/**
 * constants from excel file
 */

// eslint-disable-next-line no-unused-vars
import { IsaMillSize, JamesonCellSize } from './calculations';

export const AreaCalc = {
    2: {
        10: 0.0573628459238782,
        15: 0.0741608032525495,
        21: 0.0806995311070588,
        32: 0.0991404905625369,
        44: 0.1221139624092510,
        70: 0.1499088005129330,
        105: 0.1712307092518570,
        150: 0.1844243199989450
    },
    2.3: {
        10: 0.0573628459238782,
        15: 0.0741608032525495,
        21: 0.0806995311070588,
        32: 0.0991404905625369,
        44: 0.1221139624092510,
        70: 0.1499088005129330,
        105: 0.1712307092518570,
        150: 0.1844243199989450
    },
    2.5: {
        10: 0.0573628459238782,
        15: 0.0741608032525495,
        21: 0.0806995311070588,
        32: 0.0991404905625369,
        44: 0.1221139624092510,
        70: 0.1499088005129330,
        105: 0.1712307092518570,
        150: 0.1844243199989450
    },
    2.7: {
        10: 0.0573628459238782,
        15: 0.0741608032525495,
        21: 0.0806995311070588,
        32: 0.0991404905625369,
        44: 0.1221139624092510,
        70: 0.1499088005129330,
        105: 0.1712307092518570,
        150: 0.1844243199989450
    },
    3: {
        10: 0.0573628459238782,
        15: 0.0741608032525495,
        21: 0.0806995311070588,
        32: 0.0991404905625369,
        44: 0.1221139624092510,
        70: 0.1499088005129330,
        105: 0.1712307092518570,
        150: 0.1844243199989450
    },
    3.3: {
        10: 0.0573628459238782,
        15: 0.0741608032525495,
        21: 0.0806995311070588,
        32: 0.0991404905625369,
        44: 0.1221139624092510,
        70: 0.1499088005129330,
        105: 0.1712307092518570,
        150: 0.1844243199989450
    },
    3.5: {
        10: 0.0573628459238782,
        15: 0.0741608032525495,
        21: 0.0806995311070588,
        32: 0.0991404905625369,
        44: 0.1221139624092510,
        70: 0.1499088005129330,
        105: 0.1712307092518570,
        150: 0.1844243199989450
    },
    3.7: {
        10: 0.0573628459238782,
        15: 0.0741608032525495,
        21: 0.0806995311070588,
        32: 0.0991404905625369,
        44: 0.1221139624092510,
        70: 0.1499088005129330,
        105: 0.1712307092518570,
        150: 0.1844243199989450
    },
    4: {
        10: 0.0573628459238782,
        15: 0.0741608032525495,
        21: 0.0806995311070588,
        32: 0.0991404905625369,
        44: 0.1221139624092510,
        70: 0.1499088005129330,
        105: 0.1712307092518570,
        150: 0.1844243199989450
    },
    4.3: {
        10: 0.0573628459238782,
        15: 0.0741608032525495,
        21: 0.0806995311070588,
        32: 0.0991404905625369,
        44: 0.1221139624092510,
        70: 0.1499088005129330,
        105: 0.1712307092518570,
        150: 0.1844243199989450
    }
};
/* eslint-disable @typescript-eslint/camelcase */
const CleanerCellSizes: JamesonCellSize[] = [
    {
        downcomers: 1,
        base_metals: 'Z1200/1',
        mineral_type: 0,
        volumetric_throughput: 85,
        feed_flowrate_at_50: 42.5,
        surface_area: 2.1,
        lip_length: 3.8,
        internal_launders: 0,
        lip_length_launders: 3.8
    },
    {
        downcomers: 1,
        base_metals: 'Z1200/1',
        mineral_type: 1,
        volumetric_throughput: 85,
        feed_flowrate_at_50: 64,
        surface_area: 2.1,
        lip_length: 3.8,
        internal_launders: 0,
        lip_length_launders: 3.8
    },
    {
        downcomers: 2,
        base_metals: 'E1714/2',
        mineral_type: 0,
        volumetric_throughput: 170,
        feed_flowrate_at_50: 85,
        surface_area: 2.3,
        lip_length: 3.4,
        internal_launders: 4.8,
        lip_length_launders: 8.2
    },
    {
        downcomers: 2,
        base_metals: 'E1714/2',
        mineral_type: 1,
        volumetric_throughput: 170,
        feed_flowrate_at_50: 128,
        surface_area: 2.3,
        lip_length: 3.4,
        internal_launders: 4.8,
        lip_length_launders: 8.2
    },
    {
        downcomers: 3,
        base_metals: 'E2514/3',
        mineral_type: 0,
        volumetric_throughput: 255,
        feed_flowrate_at_50: 128,
        surface_area: 3.3,
        lip_length: 5,
        internal_launders: 7.2,
        lip_length_launders: 12.2
    },
    {
        downcomers: 3,
        base_metals: 'E2514/3',
        mineral_type: 1,
        volumetric_throughput: 255,
        feed_flowrate_at_50: 191,
        surface_area: 3.3,
        lip_length: 5,
        internal_launders: 7.2,
        lip_length_launders: 12.2
    },
    {
        downcomers: 4,
        base_metals: 'E1732/4',
        mineral_type: 0,
        volumetric_throughput: 340,
        feed_flowrate_at_50: 170,
        surface_area: 3.8,
        lip_length: 6.8,
        internal_launders: 10.4,
        lip_length_launders: 17.2
    },
    {
        downcomers: 4,
        base_metals: 'E1732/4',
        mineral_type: 1,
        volumetric_throughput: 340,
        feed_flowrate_at_50: 255,
        surface_area: 3.8,
        lip_length: 6.8,
        internal_launders: 10.4,
        lip_length_launders: 17.2
    },
    {
        downcomers: 6,
        base_metals: 'E2532/6',
        mineral_type: 0,
        volumetric_throughput: 510,
        feed_flowrate_at_50: 255,
        surface_area: 6.4,
        lip_length: 10,
        internal_launders: 15.6,
        lip_length_launders: 25.6
    },
    {
        downcomers: 6,
        base_metals: 'E2532/6',
        mineral_type: 1,
        volumetric_throughput: 510,
        feed_flowrate_at_50: 383,
        surface_area: 6.4,
        lip_length: 10,
        internal_launders: 15.6,
        lip_length_launders: 25.6
    },
    {
        downcomers: 8,
        base_metals: 'E3432/8',
        mineral_type: 0,
        volumetric_throughput: 680,
        feed_flowrate_at_50: 340,
        surface_area: 9.1,
        lip_length: 13.6,
        internal_launders: 20.8,
        lip_length_launders: 34.4
    },
    {
        downcomers: 8,
        base_metals: 'E3432/8',
        mineral_type: 1,
        volumetric_throughput: 680,
        feed_flowrate_at_50: 510,
        surface_area: 9.1,
        lip_length: 13.6,
        internal_launders: 20.8,
        lip_length_launders: 34.4
    },
    {
        downcomers: 10,
        base_metals: 'E4232/10',
        mineral_type: 0,
        volumetric_throughput: 850,
        feed_flowrate_at_50: 425,
        surface_area: 12.8,
        lip_length: 16.8,
        internal_launders: 28,
        lip_length_launders: 44.8
    },
    {
        downcomers: 10,
        base_metals: 'E4232/10',
        mineral_type: 1,
        volumetric_throughput: 850,
        feed_flowrate_at_50: 638,
        surface_area: 12.8,
        lip_length: 16.8,
        internal_launders: 28,
        lip_length_launders: 44.8
    },
    {
        downcomers: 12,
        base_metals: 'B4500/12',
        mineral_type: 0,
        volumetric_throughput: 1020,
        feed_flowrate_at_50: 510,
        surface_area: 14.4,
        lip_length: 14.14,
        internal_launders: 14.98,
        lip_length_launders: 29.1
    },
    {
        downcomers: 12,
        base_metals: 'B4500/12',
        mineral_type: 1,
        volumetric_throughput: 1020,
        feed_flowrate_at_50: 765,
        surface_area: 14.4,
        lip_length: 14.14,
        internal_launders: 14.98,
        lip_length_launders: 29.1
    },
    {
        downcomers: 16,
        base_metals: 'B5000/16',
        mineral_type: 1,
        volumetric_throughput: 1360,
        feed_flowrate_at_50: 1020,
        surface_area: 16.9,
        lip_length: 20.42,
        internal_launders: 12.4,
        lip_length_launders: 32.8
    },
    {
        downcomers: 18,
        base_metals: 'B5400/18',
        mineral_type: 0,
        volumetric_throughput: 1530,
        feed_flowrate_at_50: 765,
        surface_area: 19.2,
        lip_length: 22.62,
        internal_launders: 19.2,
        lip_length_launders: 41.8
    },
    {
        downcomers: 20,
        base_metals: 'B6000/20',
        mineral_type: 1,
        volumetric_throughput: 1700,
        feed_flowrate_at_50: 1275,
        surface_area: 22.5,
        lip_length: 26.39,
        internal_launders: 19.2,
        lip_length_launders: 45.6
    },
    {
        downcomers: 24,
        base_metals: 'B6500/24',
        mineral_type: 0,
        volumetric_throughput: 2040,
        feed_flowrate_at_50: 1020,
        surface_area: 22.2,
        lip_length: 29.53,
        internal_launders: 25.6,
        lip_length_launders: 55.1
    },
    {
        downcomers: 24,
        base_metals: 'B6500/24',
        mineral_type: 1,
        volumetric_throughput: 2040,
        feed_flowrate_at_50: 1530,
        surface_area: 22.2,
        lip_length: 29.53,
        internal_launders: 25.6,
        lip_length_launders: 55.1
    },
    {
        downcomers: 8,
        base_metals: 'B7500/8',
        mineral_type: 0,
        volumetric_throughput: 3200,
        feed_flowrate_at_50: 1600,
        surface_area: 18.9,
        lip_length: 32.04,
        internal_launders: 22.4,
        lip_length_launders: 54.4
    },
    {
        downcomers: 8,
        base_metals: 'B7500/8',
        mineral_type: 1,
        volumetric_throughput: 3200,
        feed_flowrate_at_50: 2400,
        surface_area: 18.9,
        lip_length: 32.04,
        internal_launders: 22.4,
        lip_length_launders: 54.4
    },
    {
        downcomers: 12,
        base_metals: 'B8500/12',
        mineral_type: 0,
        volumetric_throughput: 4800,
        feed_flowrate_at_50: 2400,
        surface_area: 36.7,
        lip_length: 34.72,
        internal_launders: 53.06,
        lip_length_launders: 87.8
    },
    {
        downcomers: 12,
        base_metals: 'B8500/12',
        mineral_type: 1,
        volumetric_throughput: 4800,
        feed_flowrate_at_50: 3600,
        surface_area: 36.7,
        lip_length: 34.72,
        internal_launders: 53.06,
        lip_length_launders: 87.8
    },
    {
        downcomers: 16,
        base_metals: 'B10500/16',
        mineral_type: 0,
        volumetric_throughput: 6400,
        feed_flowrate_at_50: 3200,
        surface_area: 62.2,
        lip_length: 39.94,
        internal_launders: 93.9,
        lip_length_launders: 133.5
    },
    {
        downcomers: 16,
        base_metals: 'B10500/16',
        mineral_type: 1,
        volumetric_throughput: 6400,
        feed_flowrate_at_50: 4800,
        surface_area: 62.2,
        lip_length: 39.94,
        internal_launders: 93.9,
        lip_length_launders: 133.5
    },
    {
        downcomers: 0,
        base_metals: 'MULTIPLE CELLS REQUIRED Reduce TPH & Double Cells',
        mineral_type: 1,
        volumetric_throughput: null,
        feed_flowrate_at_50: 50000,
        surface_area: 500,
        lip_length: null,
        internal_launders: null,
        lip_length_launders: null
    },
    {
        downcomers: 0,
        base_metals: 'MULTIPLE CELLS REQUIRED Reduce TPH & Double Cells',
        mineral_type: 0,
        volumetric_throughput: null,
        feed_flowrate_at_50: 50000,
        surface_area: 500,
        lip_length: null,
        internal_launders: null,
        lip_length_launders: null
    }
];

const RougherCellSizes: JamesonCellSize[] = [
    {
        downcomers: 1,
        base_metals: 'Z1200/1',
        mineral_type: 0,
        volumetric_throughput: 85,
        feed_flowrate_at_50: 42.5,
        surface_area: 2.1,
        lip_length: 3.8,
        internal_launders: 0,
        lip_length_launders: 3.8
    },
    {
        downcomers: 1,
        base_metals: 'Z1200/1',
        mineral_type: 1,
        volumetric_throughput: 85,
        feed_flowrate_at_50: 64,
        surface_area: 2.1,
        lip_length: 3.8,
        internal_launders: 0,
        lip_length_launders: 3.8
    },
    {
        downcomers: 2,
        base_metals: 'E1714/2',
        mineral_type: 0,
        volumetric_throughput: 170,
        feed_flowrate_at_50: 85,
        surface_area: 2.3,
        lip_length: 3.4,
        internal_launders: 4.8,
        lip_length_launders: 8.2
    },
    {
        downcomers: 2,
        base_metals: 'E1714/2',
        mineral_type: 1,
        volumetric_throughput: 170,
        feed_flowrate_at_50: 128,
        surface_area: 2.3,
        lip_length: 3.4,
        internal_launders: 4.8,
        lip_length_launders: 8.2
    },
    {
        downcomers: 3,
        base_metals: 'E2514/3',
        mineral_type: 0,
        volumetric_throughput: 255,
        feed_flowrate_at_50: 128,
        surface_area: 3.3,
        lip_length: 5,
        internal_launders: 7.2,
        lip_length_launders: 12.2
    },
    {
        downcomers: 3,
        base_metals: 'E2514/3',
        mineral_type: 1,
        volumetric_throughput: 255,
        feed_flowrate_at_50: 191,
        surface_area: 3.3,
        lip_length: 5,
        internal_launders: 7.2,
        lip_length_launders: 12.2
    },
    {
        downcomers: 4,
        base_metals: 'E1732/4',
        mineral_type: 0,
        volumetric_throughput: 340,
        feed_flowrate_at_50: 170,
        surface_area: 3.8,
        lip_length: 6.8,
        internal_launders: 10.4,
        lip_length_launders: 17.2
    },
    {
        downcomers: 4,
        base_metals: 'E1732/4',
        mineral_type: 1,
        volumetric_throughput: 340,
        feed_flowrate_at_50: 255,
        surface_area: 3.8,
        lip_length: 6.8,
        internal_launders: 10.4,
        lip_length_launders: 17.2
    },
    {
        downcomers: 6,
        base_metals: 'E2532/6',
        mineral_type: 0,
        volumetric_throughput: 510,
        feed_flowrate_at_50: 255,
        surface_area: 6.4,
        lip_length: 10,
        internal_launders: 15.6,
        lip_length_launders: 25.6
    },
    {
        downcomers: 6,
        base_metals: 'E2532/6',
        mineral_type: 1,
        volumetric_throughput: 510,
        feed_flowrate_at_50: 383,
        surface_area: 6.4,
        lip_length: 10,
        internal_launders: 15.6,
        lip_length_launders: 25.6
    },
    {
        downcomers: 8,
        base_metals: 'E3432/8',
        mineral_type: 0,
        volumetric_throughput: 680,
        feed_flowrate_at_50: 340,
        surface_area: 9.1,
        lip_length: 13.6,
        internal_launders: 20.8,
        lip_length_launders: 34.4
    },
    {
        downcomers: 8,
        base_metals: 'E3432/8',
        mineral_type: 1,
        volumetric_throughput: 680,
        feed_flowrate_at_50: 510,
        surface_area: 9.1,
        lip_length: 13.6,
        internal_launders: 20.8,
        lip_length_launders: 34.4
    },
    {
        downcomers: 10,
        base_metals: 'E4232/10',
        mineral_type: 0,
        volumetric_throughput: 850,
        feed_flowrate_at_50: 425,
        surface_area: 12.8,
        lip_length: 16.8,
        internal_launders: 28,
        lip_length_launders: 44.8
    },
    {
        downcomers: 10,
        base_metals: 'E4232/10',
        mineral_type: 1,
        volumetric_throughput: 850,
        feed_flowrate_at_50: 638,
        surface_area: 12.8,
        lip_length: 16.8,
        internal_launders: 28,
        lip_length_launders: 44.8
    },
    {
        downcomers: 12,
        base_metals: 'B4500/12',
        mineral_type: 0,
        volumetric_throughput: 1020,
        feed_flowrate_at_50: 510,
        surface_area: 14.4,
        lip_length: 14.14,
        internal_launders: 14.98,
        lip_length_launders: 29.1
    },
    {
        downcomers: 12,
        base_metals: 'B4500/12',
        mineral_type: 1,
        volumetric_throughput: 1020,
        feed_flowrate_at_50: 765,
        surface_area: 14.4,
        lip_length: 14.14,
        internal_launders: 14.98,
        lip_length_launders: 29.1
    },
    {
        downcomers: 16,
        base_metals: 'B5000/16',
        mineral_type: 1,
        volumetric_throughput: 1360,
        feed_flowrate_at_50: 1020,
        surface_area: 16.9,
        lip_length: 20.42,
        internal_launders: 12.4,
        lip_length_launders: 32.8
    },
    {
        downcomers: 18,
        base_metals: 'B5400/18',
        mineral_type: 0,
        volumetric_throughput: 1530,
        feed_flowrate_at_50: 765,
        surface_area: 19.2,
        lip_length: 22.62,
        internal_launders: 19.2,
        lip_length_launders: 41.8
    },
    {
        downcomers: 20,
        base_metals: 'B6000/20',
        mineral_type: 1,
        volumetric_throughput: 1700,
        feed_flowrate_at_50: 1275,
        surface_area: 22.5,
        lip_length: 26.39,
        internal_launders: 19.2,
        lip_length_launders: 45.6
    },
    {
        downcomers: 24,
        base_metals: 'B6500/24',
        mineral_type: 0,
        volumetric_throughput: 2040,
        feed_flowrate_at_50: 1020,
        surface_area: 22.2,
        lip_length: 29.53,
        internal_launders: 25.6,
        lip_length_launders: 55.1
    },
    {
        downcomers: 24,
        base_metals: 'B6500/24',
        mineral_type: 1,
        volumetric_throughput: 2040,
        feed_flowrate_at_50: 1530,
        surface_area: 22.2,
        lip_length: 29.53,
        internal_launders: 25.6,
        lip_length_launders: 55.1
    },
    {
        downcomers: 8,
        base_metals: 'B7500/8',
        mineral_type: 0,
        volumetric_throughput: 3200,
        feed_flowrate_at_50: 1600,
        surface_area: 18.9,
        lip_length: 32.04,
        internal_launders: 22.4,
        lip_length_launders: 54.4
    },
    {
        downcomers: 8,
        base_metals: 'B7500/8',
        mineral_type: 1,
        volumetric_throughput: 3200,
        feed_flowrate_at_50: 2400,
        surface_area: 18.9,
        lip_length: 32.04,
        internal_launders: 22.4,
        lip_length_launders: 54.4
    },
    {
        downcomers: 12,
        base_metals: 'B8500/12',
        mineral_type: 0,
        volumetric_throughput: 4800,
        feed_flowrate_at_50: 2400,
        surface_area: 36.7,
        lip_length: 34.72,
        internal_launders: 53.06,
        lip_length_launders: 87.8
    },
    {
        downcomers: 12,
        base_metals: 'B8500/12',
        mineral_type: 1,
        volumetric_throughput: 4800,
        feed_flowrate_at_50: 3600,
        surface_area: 36.7,
        lip_length: 34.72,
        internal_launders: 53.06,
        lip_length_launders: 87.8
    },
    {
        downcomers: 16,
        base_metals: 'B10500/16',
        mineral_type: 0,
        volumetric_throughput: 6400,
        feed_flowrate_at_50: 3200,
        surface_area: 62.2,
        lip_length: 39.94,
        internal_launders: 93.9,
        lip_length_launders: 133.5
    },
    {
        downcomers: 16,
        base_metals: 'B10500/16',
        mineral_type: 1,
        volumetric_throughput: 6400,
        feed_flowrate_at_50: 4800,
        surface_area: 62.2,
        lip_length: 39.94,
        internal_launders: 93.9,
        lip_length_launders: 133.5
    },
    {
        downcomers: 0,
        base_metals: 'MULTIPLE CELLS REQUIRED Reduce TPH & Double Cells',
        mineral_type: 1,
        volumetric_throughput: null,
        feed_flowrate_at_50: 50000,
        surface_area: 500,
        lip_length: null,
        internal_launders: null,
        lip_length_launders: null
    },
    {
        downcomers: 0,
        base_metals: 'MULTIPLE CELLS REQUIRED Reduce TPH & Double Cells',
        mineral_type: 0,
        volumetric_throughput: null,
        feed_flowrate_at_50: 50000,
        surface_area: 500,
        lip_length: null,
        internal_launders: null,
        lip_length_launders: null
    }
];

const IsaSlopeTable = {
    'copper-molybdenum': {
        120: -1.601545798,
        100: -1.601545798,
        80: -1.601545798,
        60: -1.601545798,
        40: -1.601545798
    },
    'copper-lead-zinc': {
        120: -1.560444918,
        100: -1.560444918,
        80: -2.343354006,
        60: -2.343354006,
        40: -2.410428083
    },
    iron: {
        120: -1.377955953,
        100: -1.377955953,
        80: -1.941367053,
        60: -1.941367053,
        40: -1.941367053
    },
    lead: {
        120: -1.656913524,
        100: -1.656913524,
        80: -1.814405149,
        60: -1.814405149,
        40: -1.814405149
    },
    zinc: {
        120: -1.871010875,
        100: -1.871010875,
        80: -2.149329999,
        60: -2.149329999,
        40: -2.149329999
    },
    molybdenum: {
        120: -2.603022637,
        100: -2.603022637,
        80: -2.603022637,
        60: -2.603022637,
        40: -2.603022637
    },
    nickel: {
        120: -2.663081184,
        100: -2.663081184,
        80: -2.663081184,
        60: -1.601545798,
        40: -1.601545798
    },
    tin: {
        120: -1.619254953,
        100: -1.619254953,
        80: -1.619254953,
        60: -1.619254953,
        40: -1.619254953
    },
    'pgm-polymetallics': {
        120: -1.308465573,
        100: -1.308465573,
        80: -1.723949193,
        60: -1.723949193,
        40: -1.723949193
    },
    copper: {
        120: -1.700583641,
        100: -1.725732578,
        80: -1.873821168,
        60: -1.846271377,
        40: -1.881610572
    },
    gold: {
        120: -1.413799216,
        100: -1.580088815,
        80: -1.580088815,
        60: -1.566457402,
        40: -1.566457402
    }
};

const IsaInterceptTable = {
    'copper-molybdenum': {
        120: 1876.485165,
        100: 1876.485165,
        80: 1876.485165,
        60: 1876.485165,
        40: 1876.485165
    },
    'copper-lead-zinc': {
        120: 3292.203776,
        100: 3292.203776,
        80: 53563.32969,
        60: 53563.32969,
        40: 48883.87055
    },
    iron: {
        120: 2934.3715,
        100: 2934.3715,
        80: 11902.30992,
        60: 11902.30992,
        40: 11902.30992
    },
    lead: {
        120: 2610.443268,
        100: 2610.443268,
        80: 3948.435288,
        60: 3948.435288,
        40: 3948.435288
    },
    zinc: {
        120: 6342.255955,
        100: 6342.255955,
        80: 7833.799007,
        60: 7833.799007,
        40: 7833.799007
    },
    molybdenum: {
        120: 92549.50725,
        100: 92549.50725,
        80: 92549.50725,
        60: 92549.50725,
        40: 92549.50725
    },
    nickel: {
        120: 201684.2547,
        100: 201684.2547,
        80: 201684.2547,
        60: 1876.485165,
        40: 1876.485165
    },
    tin: {
        120: 4478.099485,
        100: 4478.099485,
        80: 4478.099485,
        60: 4478.099485,
        40: 4478.099485
    },
    'pgm-polymetallics': {
        120: 3635.31804,
        100: 3635.31804,
        80: 5528.862814,
        60: 5528.862814,
        40: 5528.862814
    },
    copper: {
        120: 4716.818914,
        100: 4667.316654,
        80: 6190.616369,
        60: 4637.596496,
        40: 3953.244461
    },
    gold: {
        120: 2111.552632,
        100: 2961.910859,
        80: 2961.910859,
        60: 2149.791669,
        40: 2149.791669
    }
};

export const IsaMills: IsaMillSize[] = [
    {
        min: 20,
        max: 60,
        mill_size: 'M100 (75kW)'
    },
    {
        min: 60,
        max: 175,
        mill_size: 'M500 (200kW)'
    },
    {
        min: 175,
        max: 320,
        mill_size: 'M1,000 (355kW)'
    },
    {
        min: 320,
        max: 450,
        mill_size: 'M1,000 (500kW)'
    },
    {
        min: 450,
        max: 700,
        mill_size: 'M3,000 (800kW)'
    },
    {
        min: 700,
        max: 1000,
        mill_size: 'M5,000 (1.12MW)'
    },
    {
        min: 1000,
        max: 1300,
        mill_size: 'M5,000 (1.5MW)'
    },
    {
        min: 1300,
        max: 2200,
        mill_size: 'M7,500 (2.2MW)'
    },
    {
        min: 2200,
        max: 2800,
        mill_size: 'M10,000 (3MW)'
    },
    {
        min: 2800,
        max: 3600,
        mill_size: 'M15,000 (3.8MW)'
    },
    {
        min: 3600,
        max: 5200,
        mill_size: 'M20,000 (5.5 MW)'
    },
    {
        min: 5200,
        max: 6000,
        mill_size: 'M30,000 (6.0 MW)'
    },
    {
        min: 6000,
        max: 7600,
        mill_size: 'M50,000 (8.0 MW)'
    },
    {
        min: 7600,
        max: 10400,
        mill_size: '2 x M20,000 (5.5 MW Each)'
    },
    {
        min: 10400,
        max: 15600,
        mill_size: '3 x M20,000 (5.5 MW Each)'
    },
    {
        min: 15600,
        max: 20800,
        mill_size: '4 x M20,000 (5.5 MW Each)'
    }
];

export function getCell(mineralType, surfaceArea, feedSlurry, cleaner = false): JamesonCellSize {
    let cells = cleaner ? CleanerCellSizes : RougherCellSizes;
    cells = cells.filter(cell => cell.mineral_type === mineralType && cell.surface_area >= surfaceArea && cell.feed_flowrate_at_50 >= feedSlurry);
    // strip hidden attributes
    return cells.length > 0 ? { downcomers: cells[0].downcomers, base_metals: cells[0].base_metals } : null;
}

export function getIsaMill(f80, p80, powerRequirement): string {
    if (powerRequirement < IsaMills[0].min) {
        return 'Lab Mill Sizing Applicable';
    } if (p80 < 10) {
        return 'Desired P80 < 10 microns';
    } if (f80 > 250) {
        return 'Desired F80 > 250 microns';
    } if (f80 / p80 > 8) {
        return 'Reduction ratio > 8';
    } if (powerRequirement > IsaMills[IsaMills.length - 1].max) {
        return `${Math.round(powerRequirement / 5200)} x M20,000 (5.5MW Each)`;
    }
    return IsaMills[IsaMills.findIndex(x => x.min > powerRequirement) - 1].mill_size;
}

export function getPrediction(commodity, f80, p80, f80Cond): number {
    const slope = IsaSlopeTable[commodity][f80Cond];
    const intercept = IsaInterceptTable[commodity][f80Cond];
    return Math.abs(intercept * Math.pow(p80, slope) - (intercept * Math.pow(f80, slope) - intercept * Math.pow(f80Cond, slope)));
}
