






















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios from 'axios';
import hasOwn from 'object.hasown';
import Chart, { ChartData, ChartOptions } from 'chart.js/auto';

export interface TimeSeriesResponse {
    date: string;
    values: TimeSeriesValue[];
}

export interface TimeSeriesValue {
    name: string;
    value: number;
}

export interface MntIsaLabels {
    datePicker: string;
    legend: string;
    xAxis: string;
    yAxis: string;
    captionPrefix: string;
    captionSuffix: string;
}

export interface Station {
    name: string;
    label: string;
}

@Component
export default class MonitoringGraphMntIsa extends Vue {
    @Prop({ required: true }) labels: MntIsaLabels;
    @Prop({ required: true }) stations: Station[];
    @Prop({ default: 10 }) minValue: number;
    @Prop({ default: false, type: Boolean }) inContentGroup: boolean;

    @Ref() canvas: HTMLCanvasElement;

    apiUrl = Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Enums.API.SO2;

    currentDate = null;
    data: number[] = [];
    dateModel = null;
    chart: Chart = null;

    drawn = false;
    opened = false;

    created(): void {
        const current = new Date();
        // should set minutes to a multiple of 5
        current.setMinutes(Math.floor(current.getMinutes() / 5) * 5);
        current.setHours(current.getHours() - 1);
        this.currentDate = current;
        this.dateModel = this.getFormattedDate(current);
        this.getTimeSeriesData();
    }

    get date(): string {
        if (this.dateModel) {
            return this.dateModel.split(' ')[0];
        }
        return '';
    }

    get time(): string {
        if (this.dateModel) {
            // format to am/pm
            const time = this.dateModel.split(' ')[1];
            const splitTime = time.split(':');

            const tmpHours = Number(splitTime[0]);
            const minutes = splitTime[1];

            const suffix = tmpHours >= 12 ? 'PM' : 'AM';
            let hours = tmpHours % 12;
            if (hours === 0) hours = 12;
            return `${this.dd(hours)}:${minutes} ${suffix}`;
        }
        return '';
    }

    get config(): Record<string, unknown> {
        return {
            altInput: true,
            altFormat: 'd/m/Y, G:i K',
            dateFormat: 'd/m/Y H:i',
            disableMobile: true,
            defaultDate: this.currentDate,
            enableTime: true,
            maxDate: this.currentDate
        };
    }

    get chartData(): ChartData {
        return {
            labels: this.stations.map(station => station.label),
            datasets: [
                {
                    data: this.data,
                    backgroundColor: '#00928E',
                    barThickness: 24
                }
            ]
        };
    }

    get chartOptions(): ChartOptions {
        return {
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false,
                    external: this.externalTooltipHandler
                }
            },
            maintainAspectRatio: false,
            scales: {
                x: {
                    ticks: {
                        color: '#5A5A5A',
                        font: {
                            size: 14
                        },
                        autoSkip: false,
                        maxRotation: 32,
                        minRotation: 32
                    },
                    grid: {
                        borderColor: '#F5F5F5',
                        color: '#F5F5F5'
                    }
                },
                y: {
                    beginAtZero: true,
                    suggestedMax: this.minValue,
                    min: 0,
                    ticks: {
                        color: '#000000',
                        font: {
                            size: 14
                        }
                    },
                    grid: {
                        borderColor: '#F5F5F5',
                        color: '#F5F5F5'
                    }
                }
            }
        };
    }

    handleDateChange(): void {
        setTimeout(() => {
            this.getTimeSeriesData();
            this.opened = false;
        }, 100);
    }

    drawChart(): void {
        if (!this.canvas || this.drawn) return;

        const ctx = this.canvas.getContext('2d');
        this.chart = new Chart(ctx, {
            type: 'bar',
            data: this.chartData,
            options: this.chartOptions
        });
        this.drawn = true;
    }

    updateChart(): void {
        this.chart.data.datasets.forEach(dataset => {
            dataset.data = this.data;
        });
        this.chart.update();
    }

    dd(num: number): string {
        return (num < 10 ? `0${num}` : `${num}`);
    }

    getFormattedDate(date: Date): string {
        // convert numbers to double digits
        return `${this.dd(date.getDate())}/${this.dd(date.getMonth() + 1)}/${this.dd(date.getFullYear())} ${this.dd(date.getHours())}:${this.dd(date.getMinutes())}`;
    }

    getTimeSeriesData(): void {
        const url = `${this.apiUrl}/timeseries/mntisa/?date_time=${this.dateModel}`;
        axios.get(url)
            .then(res => {
                const allData = res.data.values.reduce((accumulator, item) => {
                    accumulator[item.name] = item.value;
                    return accumulator;
                }, {});
                const values: number[] = [];
                this.stations.forEach(station => {
                    if (hasOwn(allData, station.name)) {
                        values.push(allData[station.name]);
                    } else {
                        values.push(0);
                    }
                });
                this.data = values;
                if (!this.drawn) {
                    this.drawChart();
                } else {
                    this.updateChart();
                }
            })
            .catch(err => {
                console.log(err);
                if (!this.drawn) {
                    this.data = this.stations.map(_ => 0);
                    this.drawChart();
                }
            });
    }

    getTooltip(chart): HTMLDivElement {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.classList.add('bg-primary-50', 'py-1', 'px-3', 'rounded-xl', 'pointer-events-none', 'absolute',
                'transform', '-translate-x-3', '-translate-y-10', 'transition-all', 'duration-100', 'text-para-xs', 'flex');
            tooltipEl.style.opacity = 1;
            chart.canvas.parentNode.appendChild(tooltipEl);
        } else {
            tooltipEl.innerHTML = '';
        }

        return tooltipEl;
    }

    externalTooltipHandler(context): void {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = this.getTooltip(chart);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
        }

        // Set Text
        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map(b => b.lines);

            titleLines.forEach(title => {
                const label = document.createElement('span');
                label.classList.add('font-semibold', 'mr-2');
                label.innerText = title;
                tooltipEl.appendChild(label);
            });

            bodyLines.forEach((body) => {
                const value = document.createElement('span');
                value.innerText = body;
                tooltipEl.appendChild(value);
            });
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = '1';
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    }
}
