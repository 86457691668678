





















































































import Vue from 'vue';
import Component from 'vue-class-component';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import Pagination from '../base/Pagination.vue';
import { Prop, Watch } from 'vue-property-decorator';
import axios from 'axios';
import ResponsiveImage from '../base/ResponsiveImage.vue';
import PlayButton from '../video/PlayButton.vue';
import ArrowButton from '../base/ArrowButton.vue';
import ContextMenu from '../base/ContextMenu.vue';

export interface Filter {
    type: string;
    data: Record<string, string>[];
    default: string;
}

export interface MediaLabels {
    title: string;
    download: string;
    low: string;
    medium: string;
    high: string;
}

export interface MediaItem {
    commodity: string;
    country: string;
    dateModified: string;
    dateString: string;
    description: string;
    duration: string;
    image: string;
    imageUrls: Record<string, string>;
    intro: string;
    name: string;
    title: string;
    urlImageHigh: string;
    urlImageLow: string;
    urlImageMedium: string;
    videoId: string;
    videoLink: string;
    videoType: string;
}

@Component({
    components: {
        Pagination,
        ResponsiveImage,
        ArrowButton,
        PlayButton,
        ContextMenu
    }
})
export default class MediaLibrary extends Vue {
    @Prop({ default: 1 }) initialPages!: number;
    @Prop({ default: 6 }) limit!: number;
    @Prop({ default: false }) showLightboxes: boolean;
    @Prop() filters: Filter[];
    @Prop() labels: MediaLabels;

    apiUrl = Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Utils.addSiteToApi(Enums.API.LOCATION_V2, Enums.API.SEARCH_MEDIA, this.$site);
    currentFilters: Record<string, string> = {};
    currentPage = 1;
    pages = this.initialPages;
    items: MediaItem[] = [];
    availableResolution = ['low', 'medium', 'high'];

    /**
     * Construct query string for filter options.
     * Default options aren't appended.
     */
    get filterUrl(): string {
        let queryUrl = '';

        this.filters.forEach(filter => {
            queryUrl += this.currentFilters[filter.type] === filter.default
                ? ''
                : `&${filter.type}=${this.decodeStr(this.currentFilters[filter.type])}`;
        });
        return queryUrl;
    }

    created(): void {
        this.filters.forEach(filter => {
            Vue.set(this.currentFilters, filter.type, filter.default);
        });
    }

    /**
     * Get the (download) url according to the type (video/image) and resolution.
     *
     * @param item          The item to look up the url
     * @param resolution    The selected resolution
     */
    getUrl(item: MediaItem, resolution: string) {
        if (item.videoLink) {
            return item.videoLink;
        }
        return item[`urlImage${this.capitalize(resolution)}`];
    }

    /**
     * Capitalizes the first letter of the given string.
     *
     * @param string    The string to capitalize
     */
    capitalize(string: string) {
        if (!string) return string;
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    /**
     * Request media data from the backend.
     *
     * @param reload    If reload is true, the pagination will be reset.
     */
    getData(reload: boolean): void {
        const url = `${this.apiUrl}offset=${(this.currentPage - 1) * this.limit}&limit=${this.limit}${this.filterUrl}&reload=${reload}&locale=${Utils.getLocalStorage(Enums.STORAGE_KEY.LANGUAGE)}&rendition=square,landscape,landscape`;

        axios.get(url)
            .then(res => res.data)
            .then(data => {
                this.items = data.content;
                this.pages = data.pages === -1 ? this.pages : data.pages;
            });
    }

    /**
     * Simple space encoding.
     *
     * @param str The string to encode.
     */
    decodeStr(str: string): string {
        return str.replace(' ', '%20');
    }

    @Watch('currentPage')
    watchCurrentPage(): void {
        this.getData(false);
    }

    @Watch('currentFilters', { deep: true })
    watchCurrentFilters(): void {
        this.currentPage = 1;
        this.getData(true);
    }
}
