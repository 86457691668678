





































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ScrollDown extends Vue {
    @Prop({ required: true, default: '' }) toAnchor: string;
}
