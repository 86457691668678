var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"map relative w-full h-80 md:h-120 xl:h-180 z-20 xl:z-auto",class:{'xl:w-full': !_vm.fitMap, 'xl:w-3/4' : _vm.fitMap}},[_c('gmap-map',{ref:"mapRef",staticClass:"w-full h-full",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"options":_vm.mapOptions},on:{"idle":function($event){_vm.renderMarkers = true}}},[(_vm.renderMarkers)?_c('gmap-cluster',{attrs:{"styles":_vm.clusterStyles,"zoom-on-click":true}},_vm._l((_vm.markers),function(marker,index){return _c('gmap-custom-marker',{key:index,attrs:{"marker":marker.position,"clickable":true,"offsetY":-40,"label":{
                             color: marker.label ? 'white' : 'transparent',
                             fontFamily: 'Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif',
                             fontSize: '12px',
                             fontWeight: '100',
                             text: ' ',
                         }},nativeOn:{"click":function($event){!_vm.isOfficeMap ? _vm.openLocation(marker) : _vm.setOffice(marker)}}},[_c('div',{staticClass:"relative bg-white px-4 py-2 flex items-center justify-center border rounded-full text-para-xs",style:(_vm.getMarkerStyle(marker))},[_vm._v(" "+_vm._s(marker.label || marker.area || "")+" "),_c('div',{staticClass:"absolute border-1 w-0.5 top-full h-10",style:({
                        backgroundColor: !_vm.isOfficeMap ? marker.category.color : '#00928E',
                        borderColor: !_vm.isOfficeMap ? marker.category.color : '#00928E',
                        left: 'calc(50% - 2px)'
                    })})])])}),1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }