
















































import GmapCluster from 'gmap-vue/dist/components/cluster';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import styles from './mapStyles';
import FEATURES from './mapFeatures';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import Icon from '../base/Icon.vue';
import breakpoints from '../../plugins/breakpoints';
import SocioEconomicMapUtils, { Location, Operation, SubOperation, SubRegion } from './SocioEconomicMapUtils';
import Utils from '../../utils/Utils';
import SocioEconomicMapStaticFeature from './SocioEconomicMapStaticFeature.vue';
import SocioEconomicMapZoomButtons from './SocioEconomicMapZoomButtons.vue';

enum GridSizes {
    LARGE = 60,
    NORMAL = 60,
    NONE = 0,
}

enum ClusterSizes {
    LARGE = 1,
    NORMAL = 2
}

@Component({
    components: {
        GmapCluster,
        GmapCustomMarker,
        SocioEconomicMapStaticFeature,
        Icon,
        SocioEconomicMapZoomButtons
    }
})
export default class SocioEconomicMapEmbed extends Vue {
    /***********************
     * Vue Props
     ***********************/

    @Prop() markers: Array<any>;
    @Prop() singleMarker: any;
    @Prop() regionOption: any;
    @Prop({ default: false }) isOfficeMap: boolean;
    @Prop({ default: false }) isWorldMap: boolean;
    @Prop({ default: false }) hideCategories: boolean;
    @Prop({ default: () => ({}) }) selectedMarker: any;
    @Prop({ required: true }) country: string;
    @Prop({ default: true }) bounded: boolean;
    @Prop({ required: true }) currentRegion: SubRegion;
    @Prop({ required: true }) currentOperation: SubOperation;
    @Prop({ required: true }) operations: Array<Operation>;
    @Prop({ required: true }) allOperations: Array<Operation>;

    @Watch('markers')
    watchMarkers() {
        this.handleLocations();
    }

    @Watch('operations')
    watchOperations() {
        this.handleLocations();
    }

    /***********************
     * Vue data
     ***********************/

    kmlName = 'australia-states.kml';

    defaultZoom = 5;

    zoom = 1;

    minZoom = 2;
    maxZoom = 12;

    specialZoomLevels = {
        mobile: 2,
        tablet: 1.4,
        desktop: 2
    }

    responsiveGridThresholds = {
        mobile: 4,
        tablet: 4,
        desktop: 5
    }

    boundsPadding = {
        top: 30,
        right: 1,
        left: 1,
        bottom: 1
    }

    infoContent = '';

    currentMidx: any = null;

    center: any = { lat: 0, lng: 0 };

    bounds: any = {};

    mapInitialized = false;

    isZoomed = false;

    /***********************
     * Vue Lifecycle
     ***********************/

    created() {
        if (this.regionOption && !this.isWorldMap) {
            const { lat, lng } = this.regionOption;
            this.center.lat = lat;
            this.center.lng = lng;
        }
    }

    mounted() {
        this.handleLocations();
    }

    /***********************
     * Vue Methods
     ***********************/

    handleLocations(): void {
        /* eslint-disable */
        // @ts-ignore
        this.$gmapApiPromiseLazy().then(() => {
            if (this.operations.length) {
                /* eslint-disable */
                //@ts-ignore
                const bounds = new google.maps.LatLngBounds();
                const map = this.$refs.mapRef;
                let markers = []
                this.operations.forEach(operation => {
                    markers = [...markers, ...this.getLocationsForOperation(operation)];
                })
                markers.forEach(marker => bounds.extend(marker.position));
                //@ts-ignore
                if (markers && markers.length > 0) {
                    if (this.mapInitialized && (this.currentRegion || this.currentOperation)) {
                        //@ts-ignore
                        map.fitBounds(bounds);
                        if (this.hasValidZoomLevelOverride()) {
                            (this.$refs.mapRef as any).$mapPromise.then((mapInstance) => {
                                mapInstance.setZoom(this.zoomLevelOverride);
                                this.onZoom();
                            });
                        } else {
                            // 1 coordinate unit is moved to north if not zoomed in too close
                            SocioEconomicMapUtils.fitBoundsPaddingCenter(map, bounds, this.boundsPadding, { y: 1 });
                        }
                    } else {
                        // set zoom for maps no yet manipulated
                        (this.$refs.mapRef as any).$mapPromise.then((mapInstance) => {
                            mapInstance.setZoom(this.defaultZoom);
                            this.setCenter(map);
                            this.mapInitialized = true;
                        });
                    }
                } else {
                    this.mapInitialized = true;
                }
            }
        });
    }

    setCenter(map: any) {
        if (this.regionOption) {
            const { lat, lng } = this.regionOption;
            map.panTo({ lat, lng });
        }
    }

    hasValidZoomLevelOverride() {
        return this.zoomLevelOverride >= this.minZoom && this.zoomLevelOverride <= this.maxZoom;
    }

    onClusterClick(cluster): void {
        const markers = cluster.getMarkers();
        /* eslint-disable */
        //@ts-ignore
        const bounds = new google.maps.LatLngBounds();
        const map = this.$refs.mapRef;
        markers
            .map(marker => {
               return {
                   lat: marker.getPosition().lat(),
                   lng: marker.getPosition().lng()
               }
            })
            .forEach(position => bounds.extend(position));
        // 1 coordinate unit is moved to north if not zoomed in too close
        SocioEconomicMapUtils.fitBoundsPaddingCenter(map, bounds, this.boundsPadding, { y: 1 });
        /* eslint-enable */
    }

    onMapIdle(): void {
        if (this.mapInitialized) {
            this.setZoom();
        }
    }

    getLocationsForOperation(operation: Operation): Array<Location> {
        const result: Array<Location> = new Array<Location>();
        const usedIds: Array<string> = new Array<string>();
        operation.items.forEach(item => {
            item.locations.forEach(location => {
                if (!usedIds.includes(location.uuid) && (!this.currentRegion || location.regions.includes(this.currentRegion.uuid))) {
                    result.push(location);
                    usedIds.push(location.uuid);
                }
            });
        });
        return result;
    }

    onMarkerClicked(marker: Location, operation: Operation): void {
        this.$emit('markerClicked', marker, this.getFirstOperationForLocation(marker));
    }

    // retrieves the first operation found for a location (depending on the order in magnolia)
    getFirstOperationForLocation(marker: Location): Operation|SubOperation {
        let result: Operation|SubOperation;
        let counter = 0;
        while (!result) {
            result = SocioEconomicMapUtils.findOperation(marker.operations[counter], this.allOperations);
            counter++;
        }
        return result;
    }

    // retrieves the suboperation for a location (dependend on the state of Vue)
    getSubOperationForLocation(marker: Location, operation: Operation): SubOperation {
        return operation.items.find(item => item.locations.some(location => location.uuid === marker.uuid));
    }

    getMarkerStyle(marker, operation: Operation): any {
        return {
            borderColor: Utils.cssColor(operation.color) || '#00928E',
            backgroundColor: this.selectedMarker && this.selectedMarker.uuid === marker.uuid ? Utils.cssColor(operation.color) || '#00928E' : 'white',
            color: this.selectedMarker && this.selectedMarker.uuid === marker.uuid ? 'white' : 'black'
        };
    }

    getRegionClusterStyles(): any {
        return [{
            textColor: 'black',
            height: 38,
            fontFamily: 'Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: '12px',
            fontWeight: '800',
            url: `data:image/svg+xml;base64,${this.makeClusterShape()}`,
            width: 32
        }];
    }

    getOperationClusterStyles(operation: Operation): any {
        return [{
            textColor: 'black',
            height: 38,
            fontFamily: 'Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: '12px',
            fontWeight: '800',
            url: `data:image/svg+xml;base64,${this.makeClusterShape(operation)}`,
            width: 32
        }];
    }

    getColor(color: string): string {
        return Utils.cssColor(color);
    }

    openFiltering() {
        this.$emit('openFiltering');
    }

    get countryConfig() {
        return FEATURES[this.country];
    }

    get showStaticFeatures() {
        return this.zoom < 9;
    }

    get mapOptions() {
        return {
            styles,
            minZoom: 1.5,
            maxZoom: this.maxZoom,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            disableDefaultUI: true
            // restriction: {
            //     latLngBounds: this.bounded ? this.countryConfig.limits : {},
            //     strictBounds: false
            // }
        };
    }

    get zoomLevelOverride(): number {
        let override: number = null;
        if (this.currentOperation && this.currentOperation.zoomLevelOverride) {
            override = parseInt(this.currentOperation.zoomLevelOverride);
        }
        if (this.currentRegion && this.currentRegion.zoomLevelOverride) {
            override = parseInt(this.currentRegion.zoomLevelOverride);
        }
        return override;
    }

    makeClusterShape(operation: Operation = null): string {
        return Buffer.from('<svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 40" fill="none">\\n\' +\n' +
            '            \'    <circle cx="16" cy="16" r="15" fill="white" stroke="' + (operation ? `${this.formatOperationColor(operation.color)}` : '#00928E') + '" stroke-width="2"/>\\n\' +\n' +
            '            \'    <line x1="16" y1="32" x2="16" y2="39" stroke="' + (operation ? `${this.formatOperationColor(operation.color)}` : '#00928E') + '" stroke-width="2" stroke-linecap="round"/>\\n\' +\n' +
            '            \'</svg>').toString('base64');
    }

    zoomIn(): void {
        (this.$refs.mapRef as any).$mapPromise.then((map) => {
            map.setZoom(map.getZoom() + 1);
            this.onZoom();
        });
    }

    zoomOut(): void {
        (this.$refs.mapRef as any).$mapPromise.then((map) => {
            map.setZoom(map.getZoom() - 1);
            this.onZoom();
        });
    }

    formatOperationColor(color: string): string {
        if (!color) return color;
        return `${color[0] === '#' ? '' : '#'}${color}`;
    }

    onZoom(): void {
        this.setZoom();
    }

    setZoom(): void {
        (this.$refs.mapRef as any).$mapPromise.then((map) => {
            this.zoom = map.getZoom();
        });
    }

    get formattedLocations(): any {
        let result = [];
        const allOperations = this.operations.map(operation => {
            return { ...operation };
        });
        allOperations.forEach(operation => {
            const locations = this.getLocationsForOperation(operation) as any;
            locations.forEach(location => {
                location.operationColor = operation.color || '';
            });
            result = result.concat(locations);
        });
        return result;
    }

    get gridSize(): number {
        if (this.zoom === this.maxZoom) return GridSizes.NONE;
        return this.zoom > this.responsiveGridThreshold || (this.currentRegion || this.currentOperation || this.isZoomed) ? GridSizes.NORMAL : GridSizes.LARGE;
    }

    get clusterSize(): number {
        return this.zoom > this.responsiveGridThreshold || (this.currentRegion || this.currentOperation || this.isZoomed) ? ClusterSizes.NORMAL : ClusterSizes.LARGE;
    }

    get responsiveGridThreshold(): number {
        return this.responsiveGridThresholds[this.breakpoint];
    }

    get breakpoint(): string {
        if (breakpoints.desktop) {
            return 'desktop';
        }
        if (breakpoints.tablet) {
            return 'tablet';
        } else {
            return 'mobile';
        }
    }
}
