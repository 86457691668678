import Vue from 'vue';
import Vuex from 'vuex';
import NavigationStore from './NavigationStore';

Vue.use(Vuex);

interface StoreType {
    navigation: NavigationStore;
}

export default new Vuex.Store<StoreType>({});
