
















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class SliderButton extends Vue {
    @Prop({ default: 'right' }) direction: string;

    get rotationClass(): string {
        switch (this.direction) {
            case 'up':
                return 'rotate-180';
            case 'right':
                return '-rotate-90';
            case 'left':
                return 'rotate-90';
            case 'down':
            default:
                return '';
        }
    }
}
