








































import Vue from 'vue';
import { Component, InjectReactive, Prop } from 'vue-property-decorator';
import SimpleImage from '../../base/SimpleImage.vue';

export interface ResultLabels {
    barType: string;
    bladeType: string;
    edgestripType: string;
}

@Component({
    components: {
        SimpleImage
    }
})
export default class IsaKiddResult extends Vue {
    @Prop() title: string;
    @Prop() text: string;
    @Prop() footNote: string;
    @Prop() labels: ResultLabels;
    @Prop() images: Record<string, string>;
    @Prop() defaultImage: string;

    @InjectReactive()
    resultData: Record<string, any>;

    currentImage = '';

    mounted(): void {
        this.currentImage = this.image;
    }

    get image(): string {
        return this.images[this.resultData.resultImage] || this.defaultImage;
    }

    get imageZoom(): string {
        return this.images[this.resultData.resultImage + 'Zoom'] || this.defaultImage;
    }
}
