






import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class NavigationItem extends Vue {
    @Prop() currentUri: string;
    @Prop() targetUri: string;

    opened = false;

    created(): void {
        this.opened = this.isActiveOrTrail;
        if (this.opened) {
            this.openParent();
        }
    }

    get isActiveOrTrail(): boolean {
        return !!this.targetUri && !!this.currentUri && this.currentUri.startsWith(this.targetUri);
    }

    toggle(): void {
        this.opened = !this.opened;
    }

    openParent(): void {
        // simple as is: if the nav item is opened, propagate it to parent navigation item (if applicable)
        // necessary since the isTrailOrActive won't work with sub sites
        if (typeof (this.$parent.$parent as any).opened === 'boolean' && !(this.$parent.$parent as any).opened) {
            (this.$parent.$parent as any).opened = true;
        }
    }

    @Watch('opened')
    watchOpened(newVal: boolean): void {
        if (newVal) {
            this.openParent();
        }
    }
}
