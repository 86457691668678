









import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import Dropdown from '../base/Dropdown.vue';
import DropdownItem from '../base/DropdownItem.vue';
import breakpoints from '../../plugins/breakpoints';

@Component({
    components: {
        Dropdown,
        DropdownItem
    }
})
export default class EventsYear extends Vue {
    @Prop({ default: () => [] }) options: string[];
    @Prop({ default: '' }) label: string;
    @Prop() value: string;

    model = '';

    mounted(): void {
        this.model = this.value;
    }

    get isMobile(): boolean {
        return breakpoints.mobile;
    }

    @Emit('input')
    select(value: string): string {
        return value;
    }

    @Watch('value')
    watchValue(): void {
        this.model = this.value;
    }

    @Watch('model')
    watchModel(newValue: string): void {
        if (newValue !== this.value) {
            this.select(newValue);
        }
    }
}
