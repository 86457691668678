





















import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop } from 'vue-property-decorator';

@Component({})
export default class MultiStepFormHidden extends Vue {
    @Prop() name: string;
    @Prop() title: string;
    @Prop() value: string;
    @Prop({ default: false }) editMode: boolean;

    @Inject()
    model: Record<string, unknown>;

    created(): void {
        if (!this.model[this.name] && this.value) {
            Vue.set(this.model, this.name, this.value);
        }
    }
}
