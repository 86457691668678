










import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class MainNavigationSubMenu extends Vue {
    @Prop({ required: true }) isOpened: boolean;
}
