














import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import Icon from '../base/Icon.vue';

@Component({
    components: {
        Icon
    }
})
export default class SocioEconomicMapDetailDescription extends Vue {
    /***********************
     * Vue Props
     ***********************/
    @Prop({ default: null }) description: string;
    @Prop({ default: '' }) showMoreLabel: string;
    @Prop({ default: '' }) showLessLabel: string;

    @Watch('description')
    watchDescription() {
        // recalculate clamping whenever content changes
        this.checkClamping();
    }

    /***********************
     * Vue Data
     ***********************/
    descriptionOpen = false;
    isTextClamped = false;

    /***********************
     * Vue Lifecycle
     ***********************/
    mounted() {
        this.handleClamping();
        this.checkClamping();
    }

    /***********************
     * Vue Methods
     ***********************/
    handleClamping(): void {
        this.checkClamping();
        window.addEventListener('resize', () => this.checkClamping());
    }

    checkClamping(): void {
        this.$nextTick(() => {
            const element: Element = this.$refs.text as Element;
            this.isTextClamped = element ? element.scrollHeight > element.clientHeight : false;
        });
    }
}
