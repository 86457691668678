




























import Vue from 'vue';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import Icon from '../base/Icon.vue';

@Component({
    components: {
        Icon,
        CollapseTransition
    }
})
export default class CheckboxAccordion extends Vue {
    @Prop() id: string;
    @Prop() title: string;
    @Prop() value: boolean;

    @Prop({ default: true }) showCheckbox: boolean;
    @Prop({ required: true }) check!: boolean;

    checkModel = this.check;

    @Emit('input')
    toggle(): boolean {
        return !this.value;
    }

    @Watch('check')
    watchCheck(): void {
        this.checkModel = this.check;
    }

    @Watch('checkModel')
    watchCheckModel(): void {
        this.$emit('change', this.checkModel);
    }
}
