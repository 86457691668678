
























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { InteractiveModal } from './InteractiveImage.vue';
import Icon from '../base/Icon.vue';

@Component({
    components: {
        Icon
    }
})
export default class InteractiveImageModal extends Vue {
    @Prop() modal: InteractiveModal;

    close() {
        this.$emit('close');
    }
}
