








import Vue from 'vue';
import { Component, Prop, Ref } from 'vue-property-decorator';
import brightcovePlayerLoader from '@brightcove/player-loader';
import PlayButton from './PlayButton.vue';

@Component({
    components: {
        PlayButton
    }
})
export default class BrightCoveVideo extends Vue {
    @Prop({ default: '' }) id: string;
    @Prop({ default: '' }) placeholder: string;
    @Prop({ default: '' }) videoId: string;
    @Prop({ default: '' }) account: string;
    @Prop({ default: 'default' }) dataPlayer: string;
    @Prop({ default: 'landscape' }) rendition: string;
    @Prop({ default: false }) fullScreen: boolean;
    @Prop({ default: 1 }) numOfComponents: number;
    @Prop({ default: false }) narrow: boolean;
    @Prop({ default: false }) isSlider: boolean;
    @Prop({ default: false }) noPreload: boolean;

    @Ref() video;

    videoObject = null;
    firstPlay = false;

    get padding(): string {
        return this.rendition === 'landscape' ? 'pt-16/9' : 'pt-3/4';
    }

    get tabletPlayButtonSize(): number {
        // preserve comment: md:h-24 md:w-24 md:h-16 md:w-16 md:h-10 md:w-10
        if (this.fullScreen) {
            return 24;
        } else if (this.isSlider || (this.numOfComponents % 2 === 0)) {
            return 16;
        } else if (this.numOfComponents % 3 === 0) {
            return 10;
        }
        return 20;
    }

    get desktopPlayButtonSize(): number {
        // preserve comment: xl:h-20 xl:w-20 xl:h-16 xl:w-16 xl:h-10 xl:w-10
        if (this.fullScreen) {
            return 24;
        } else if (this.narrow) {
            if (this.isSlider || (this.numOfComponents % 2 === 0)) {
                return 16;
            } else if (this.numOfComponents % 3 === 0) {
                return 10;
            } else {
                return 20;
            }
        } else if (this.isSlider || (this.numOfComponents % 2 === 0)) {
            return 20;
        } else if (this.numOfComponents % 3 === 0) {
            return 16;
        }
        return 24;
    }

    mounted(): void {
        const options = {} as Record<string, string>;
        if (this.noPreload) {
            options.preload = 'none';
        }
        brightcovePlayerLoader({
            refNode: this.video,
            refNodeInsert: 'append',
            accountId: this.account,
            playerId: this.dataPlayer,
            embedId: 'default',
            videoId: this.videoId,
            poster: this.placeholder,
            options: options
        })
            .then(success => {
                this.videoObject = success.ref;
                this.videoObject.addClass('w-full');
                this.videoObject.addClass('h-full');
                this.videoObject.bigPlayButton.hide();
                this.videoObject.on('firstplay', () => {
                    // if play is not triggered by the play button
                    this.firstPlay = true;
                });
                // somehow dirty, this is used to control the visibility of the slider controls if applicable
                this.videoObject.on('pause', () => {
                    if (typeof (this.$parent as any).isPlaying !== 'undefined') {
                        (this.$parent as any).isPlaying = false;
                    }
                });
                this.videoObject.on('play', () => {
                    if (typeof (this.$parent as any).isPlaying !== 'undefined') {
                        (this.$parent as any).isPlaying = true;
                    }
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    play(): void {
        if (this.videoObject) {
            this.videoObject.play();
            this.firstPlay = true;
        }
    }
}
