




















































import Vue from 'vue';
import Utils from '../../utils/Utils';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import GcButton from '../base/GcButton.vue';
import CheckboxAccordion from './CheckboxAccordion.vue';
import Icon from '../base/Icon.vue';

export interface CookieLabels {
    settings: string;
    reject: string;
    accept: string;
    rejectAll: string;
    confirm: string;
}

export interface CookieOption {
    type: string;
    label: string;
    text: string;
    checkbox: boolean;
}

export interface CookieLink {
    url: string;
    label: string;
    ariaLabel: string;
}

@Component({
    components: {
        Icon,
        GcButton,
        CheckboxAccordion
    }
})
export default class CookiePolicy extends Vue {
    @Prop({ default: false }) type: string;
    @Prop() title: string;
    @Prop() text: string;
    @Prop() advText: string;
    @Prop() labels: CookieLabels;
    @Prop() link: CookieLink;
    @Prop({ default: () => [] }) options: CookieOption[];

    key = 'glencore-policy';
    consentKey = 'glencore-policy-consent';

    show = true;
    open = false;

    model: Record<string, boolean> = {};
    grantModel: Record<string, boolean> = {};

    get consent(): Record<string, string> {
        /* eslint-disable */
        return {
            security_storage: 'granted',
            analytics_storage: this.grantModel.analytics ? 'granted' : 'denied',
            functional_storage: this.grantModel.functional ? 'granted' : 'denied',
            personalization_storage: this.grantModel.functional ? 'granted' : 'denied',
            ad_storage: this.grantModel.targeting ? 'granted' : 'denied'
        }
        /* eslint-enable */
    }

    get advanced(): boolean {
        return this.type === 'advanced';
    }

    get simple(): boolean {
        return this.type === 'simple';
    }

    get subtle(): boolean {
        return this.type === 'subtle';
    }

    created(): void {
        if (this.advanced) {
            // init data layer if not already present
            window.dataLayer = window.dataLayer || [];

            // always init consent with default values
            this.options.forEach(option => {
                if (option.checkbox) {
                    Vue.set(this.grantModel, option.type, false);
                }
            });
            this.initConsent();

            // and if there's a consent state saved, update it after initialization
            const savedConsent = Utils.getLocalStorage(this.consentKey);
            if (savedConsent) {
                // set model from storage
                this.grantModel = JSON.parse(savedConsent);
                this.updateConsent();
                this.show = false;
            }
        } else {
            // check local storage
            const savedPolicyState = Utils.getLocalStorage(this.key) || '';
            if (savedPolicyState === 'accepted') {
                this.show = false;
            }
        }
    }

    mounted(): void {
        document.addEventListener('click', this.onOutsideClick);
    }

    onOutsideClick(evt: Event) {
        // for now, do nothing in advanced mode or when invisible
        if (this.advanced || !this.show) return;
        let targetElement = evt.target as HTMLElement;
        do {
            if (targetElement === this.$el) {
                return;
            }
            targetElement = targetElement.parentElement;
        } while (targetElement);
        this.close();
    }

    close(): void {
        if (this.advanced) {
            Utils.setLocalStorage(this.consentKey, JSON.stringify(this.grantModel));

            // send event to global event bus, so other components can respond to it
            this.$eventHub.$emit('cookie-consent-updated');
        }
        Utils.setLocalStorage(this.key, 'accepted');
        this.show = false;
    }

    acceptAll(): void {
        Object.keys(this.grantModel).forEach(key => {
            Vue.set(this.grantModel, key, true);
        });
        this.updateConsent();
    }

    rejectAll(): void {
        Object.keys(this.grantModel).forEach(key => {
            Vue.set(this.grantModel, key, false);
        });
        this.updateConsent();
    }

    initConsent(): void {
        if (this.$gtm && this.$gtm.enabled()) {
            this.gtag('consent', 'default', this.consent);
        }
    }

    updateConsent(): void {
        if (this.$gtm && this.$gtm.enabled()) {
            this.gtag('consent', 'update', this.consent);
            window.dataLayer.push({
                event: 'cookie_consent_update'
            });
        }
        this.close();
    }

    gtag(...args): void {
        // somehow it won't work when using the args parameter instead of arguments, so please don't change
        /* eslint-disable-next-line prefer-rest-params */
        window.dataLayer.push(arguments);
    }
}
