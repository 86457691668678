




































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Step } from './MultiStepForm.vue';

@Component
export default class MultiStepFormHeader extends Vue {
    @Prop() steps: Step[];
    @Prop() currentStep: number;

    protected getStyles(step: number): Record<string, unknown> {
        return {
            'flex-grow': this.getFlexGrow(step)
        };
    }

    protected getFlexGrow(step: number): number {
        const intermediates = this.getIntermediateStepsBefore(step) + this.getIntermediateStepsAfter(step);
        return 1 / (intermediates + 1);
    }

    protected getIntermediateStepsBefore(step: number): number {
        if (this.steps[step].type === 'normal' || step === 0) {
            return 0;
        }
        let result = 1;
        for (let i = step - 1; i >= 0; i--) {
            if (this.steps[i].type === 'intermediate') {
                result++;
            } else {
                break;
            }
        }
        return result;
    }

    protected getIntermediateStepsAfter(step: number): number {
        if (step === (this.steps.length - 1)) {
            return 0;
        }
        let result = 0;
        for (let i = step + 1; i <= this.steps.length - 1; i++) {
            if (this.steps[i].type === 'intermediate') {
                result++;
            } else {
                break;
            }
        }
        return result;
    }
}
