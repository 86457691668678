










































import Utils from '../../utils/Utils';
import MapEmbed from './MapEmbed.vue';
import MapCategories from './MapCategories.vue';
import MapDetail from './MapDetail.vue';
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

export interface Position {
    lat: number;
    lng: number;
}

export interface Office {
    position: Position;
    title: string;
    area: string;
    fullAddress: string;
    phoneNumber: string;
    mobileNumber: string;
    faxNumber: string;
    email: string;
    images: Record<string, string>;
    defaultOffice: boolean;
}

@Component({
    components: {
        MapEmbed,
        MapCategories,
        MapDetail
    }
})
export default class WorldMap extends Vue {
    /***********************
     * Vue Props
     ***********************/

    @Prop({ default: false }) hideCategories: boolean;
    @Prop() points: Array<any>;
    @Prop() categories: any;
    @Prop({ default: () => [] }) region: Array<any>;
    @Prop({ default: '' }) labelVisitWebsite: string;
    @Prop({ default: '' }) labelShowAll: string;
    @Prop({ default: false }) isOfficeMap: boolean;
    @Prop({ default: '' }) dataSource: string;
    @Prop({ default: '' }) labelSelectOffice: string;
    @Prop({ default: '' }) labelPhone: string;
    @Prop({ default: '' }) labelMobile: string;
    @Prop({ default: '' }) labelFax: string;
    @Prop({ default: '' }) labelSearch: string;
    @Prop({ default: '' }) labelBack: string;

    /***********************
     * Vue data
     ***********************/

    mapData: Array<any> = [];

    markers: Array<any> = [];

    regionOption: any = {
        position: {
            lat: null,
            lng: null
        },
        zoom: null
    };

    isWorldMap = true;

    selectedMarker: any = null;

    /***********************
     * Vue lifecycle
     ***********************/

    created() {
        this.markers = this.filteredPoints;

        if (this.region.length) {
            /* eslint-disable */
            // @ts-ignore
            const { position: { lat, lng } = {}, zoom = 0 } = this.region[0];
            this.regionOption.zoom = zoom;
            this.regionOption.position.lat = lat;
            this.regionOption.position.lng = lng;
            this.mapData = this.filteredPoints;
            this.isWorldMap = false;
            /* eslint-enable */
        }

        this.mapData = this.filteredPoints;
    }

    /***********************
     * Vue computed
     ***********************/

    get filteredPoints(): any[] {
        return this.points.filter(maker => Utils.checkLatLng(maker.position));
    }

    get showOfficeSection(): boolean {
        return this.dataSource === 'offices' && this.isOfficeMap;
    }

    get showCategories(): boolean {
        return !this.hideCategories && !this.showOfficeSection;
    }

    get searchEntries(): Record<string, string> {
        return Object.assign.apply(null, this.points.map(p => { return { [p.uuid]: p.label }; }));
    }

    /***********************
     * Vue Methods
     ***********************/

    openLocationByUUID(uuid: string): void {
        const marker: any = this.points.find(p => p.uuid === uuid);
        if (marker) {
            this.mapData = [marker];
        }
    }

    openLocation(marker): void {
        Utils.toggleScrollLock('body', 'mobile-only');
        this.selectedMarker = marker;
    }

    closeLocation(): void {
        Utils.toggleScrollLock('body', 'mobile-only');
        this.selectedMarker = null;
    }

    filter(selectedCategories): void {
        const markers = this.points.map(point => {
            return { ...point };
        });
        if (selectedCategories.length > 0) {
            this.mapData =
                markers
                    .filter(marker => {
                        let result = false;
                        selectedCategories.forEach(selectedCategory => {
                            if (selectedCategory.uuid === marker.category.uuid) {
                                result = true;
                            }
                            marker.category.subCategories.forEach(markerSubCategory => {
                                if (markerSubCategory.uuid === selectedCategory.uuid) {
                                    result = true;
                                }
                            });
                        });
                        return result && Utils.checkLatLng(marker.position);
                    });
        } else {
            this.mapData = this.filteredPoints;
        }
    }

    setOffice(office: Office): void {
        this.mapData = this.points.filter(point => (point as Office) === office);
        this.selectedMarker = office;
    }
}
