



























import Utils from '../../utils/Utils';
import { PropType } from 'vue';
import { CookieLabels } from './CookiePolicy.vue';

const consentKey = 'glencore-policy-consent';
const policyKey = 'glencore-policy';

export default {
    data() {
        return {
            grantModel: {},
            hasConsent: false
        };
    },
    props: {
        /*
         * Simple, basic, advanced
         */
        cookieConsentType: { type: String, required: true },
        /*
         * Name of consent that is required (functional, analytics, targeting)
         */
        consentName: { type: String, required: true },
        /*
         * Button labels
         */
        labels: { type: Object as PropType<CookieLabels>, required: true },
        /*
         * Consent dialog title
         */
        title: { type: String, required: true },
        /*
         * Link to cookie policy page
         */
        cookiePolicyPageLink: { type: String, required: true },
        /*
         * Optional preview image
         */
        previewImageLink: { type: String, default: '' },
        /*
         * Optional rendition, only to be used when wrapping a video
         */
        rendition: { type: String, default: '' }
    },
    computed: {
        advanced(): boolean {
            return this.cookieConsentType === 'advanced';
        },
        consent(): Record<string, string> {
            /* eslint-disable */
            return {
                security_storage: 'granted',
                analytics_storage: this.grantModel.analytics ? 'granted' : 'denied',
                functional_storage: this.grantModel.functional ? 'granted' : 'denied',
                personalization_storage: this.grantModel.functional ? 'granted' : 'denied',
                ad_storage: this.grantModel.targeting ? 'granted' : 'denied'
            }
            /* eslint-enable */
        },
        dynamicClasses(): string {
            if (this.rendition) {
                return 'h-0 ' + (this.rendition === 'landscape' ? 'pt-16/9' : 'pt-3/4');
            } else {
                return 'h-80 md:h-60';
            }
        },
        previewImageLinkStyle(): object {
            if (this.previewImageLink) {
                return {
                    backgroundImage: `url(${this.previewImageLink})`
                };
            } else {
                return null;
            }
        }
    },
    methods: {
        determineConsent(): void {
            this.hasConsent = this.advanced ? this.grantModel[this.consentName] === true : true;
        },
        /*
         * User accepted the consent, so let's update the local storage.
         */
        updateConsent(): void {
            if (this.advanced) {
                this.grantModel[this.consentName] = true;
                Utils.setLocalStorage(consentKey, JSON.stringify(this.grantModel));
            } else {
                Utils.setLocalStorage(policyKey, 'accepted');
            }

            // send event to global event bus, so other wrapper components can respond to it
            this.$eventHub.$emit('cookie-consent-updated');

            if (this.$gtm && this.$gtm.enabled()) {
                this.gtag('consent', 'update', this.consent);
                window.dataLayer.push({
                    event: 'cookie_consent_update'
                });
            }

            this.determineConsent();
        },
        /*
         * Consent was updated, either in this component or another one.
         * Now revisit the saved consent in the local storage.
         */
        consentUpdated(): void {
            const savedConsent = Utils.getLocalStorage(consentKey);
            if (savedConsent) {
                // set model from storage
                this.grantModel = JSON.parse(savedConsent);
            }
            this.determineConsent();
        },
        gtag(...args): void {
            // somehow it won't work when using the args parameter instead of arguments, so please don't change
            /* eslint-disable-next-line prefer-rest-params */
            window.dataLayer.push(arguments);
        }
    },
    watch: {
        grantModel() {
            this.determineConsent();
        }
    },
    created() {
        if (this.advanced) {
            // init data layer if not already present
            window.dataLayer = window.dataLayer || [];

            const savedConsent = Utils.getLocalStorage(consentKey);
            if (savedConsent) {
                // set model from storage
                this.grantModel = JSON.parse(savedConsent);
            }

            // listener, which responds to cookie consent updates
            this.$eventHub.$on('cookie-consent-updated', this.consentUpdated);
        } else {
            // check local storage
            const savedPolicyState = Utils.getLocalStorage(policyKey) || '';
            if (savedPolicyState === 'accepted') {
                this.hasConsent = true;
            }
        }
    },
    beforeDestroy() {
        if (this.advanced) {
            this.$eventHub.$off('cookie-consent-updated');
        }
    }
};
