









import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Icon from './Icon.vue';

@Component({
    components: {
        Icon
    }
})
export default class BackToTop extends Vue {
    @Prop({ default: 2 }) threshold: number;

    contentHeight = 0;
    viewportHeight = 0;
    scrollingToTop = false;

    /**
     * the higher the negative delta (= the lower the number) the faster the user needs to scroll to the top
     * to trigger the back button (if height threshold is reached)
     */
    MAX_NEGATIVE_DELTA = -5;

    mounted(): void {
        this.contentHeight = document.body.clientHeight;
        this.viewportHeight = window.innerHeight;

        if (this.thresholdReached) {
            this.addScrollDetection();
        }
    }

    get visible(): boolean {
        return this.thresholdReached && this.scrollingToTop;
    }

    get thresholdReached(): boolean {
        return this.contentHeight > (this.threshold * this.viewportHeight);
    }

    scrollToTop(): void {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }

    addScrollDetection() {
        const checkScrollSpeed = ((_delay) => {
            let lastPos, newPos, timer, delta;
            const delay = _delay || 50;

            function clear() {
                lastPos = null;
                delta = 0;
            }

            clear();

            return () => {
                newPos = window.scrollY;

                if (lastPos != null) {
                    delta = newPos - lastPos;
                }

                lastPos = newPos;
                clearTimeout(timer);
                timer = setTimeout(clear, delay);
                return delta;
            };
        })();

        window.onscroll = () => {
            const delta = checkScrollSpeed();
            // don't show back button when scrolling down or when too close to the top
            // after that, only show the button if the delta is reached and don't reset it as long as it's true and
            // the first precondition isn't met
            if (delta > 0 || window.scrollY < (window.innerHeight / 2)) {
                this.scrollingToTop = false;
            } else if (!this.scrollingToTop) {
                this.scrollingToTop = delta <= this.MAX_NEGATIVE_DELTA;
            }
        };
    }
}
