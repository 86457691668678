















































import { Component, Prop, Ref } from 'vue-property-decorator';
import Vue from 'vue';
import NavigationStore from '../../store/NavigationStore';
import { getModule } from 'vuex-module-decorators';
import Icon from '../base/Icon.vue';
import Cookies from 'js-cookie';

@Component({
    components: {
        Icon
    }
})
export default class MetaNavigation extends Vue {
    @Prop({ default: false }) transparent!: boolean;
    @Prop({ default: false }) editMode: boolean;
    @Prop({ default: true }) showLocaleHints: boolean;
    @Prop({ default: false }) showLanguageBanner: boolean;
    @Prop({ default: () => [] }) availableLocales: Array<string>;
    @Prop({ default: true }) languageSwitcherShown: boolean;
    @Prop() hint: string;
    @Prop() noContentTexts: any;

    @Ref() languageHint: HTMLDivElement;

    navStore: NavigationStore = getModule(NavigationStore);
    scrollPos = -1;

    bannerModel = false;
    noContentModel = false;
    prevSelectedLocale: string;

    get navigationOpened(): boolean {
        return this.navStore.navigationOpened;
    }

    get globalNavOpened(): boolean {
        return this.navStore.globalNavOpened;
    }

    get searchOpened(): boolean {
        return this.navStore.searchOpened;
    }

    get overlayOpened(): boolean {
        return this.globalNavOpened || this.searchOpened;
    }

    get showBackground(): boolean {
        return this.scrollPos > 0 || !this.transparent || this.globalNavOpened || this.searchOpened;
    }

    get showBlackLogo(): boolean {
        return this.navigationOpened || this.showBackground;
    }

    // hack to set body classes via getter
    get status(): number {
        return this.navStore.bodyClasses;
    }

    get noContentText(): string {
        const texts: Map<string, string> = new Map(Object.entries(this.noContentTexts));
        return this.prevSelectedLocale && texts.has(this.prevSelectedLocale) ? texts.get(this.prevSelectedLocale) : null;
    }

    mounted(): void {
        this.prevSelectedLocale = Cookies.get('prev-selected-locale');
        if (!this.editMode && this.transparent) {
            document.addEventListener('scroll', this.onScroll);
        }
        // set navStore variable for top margin correction
        this.navStore.setAdaptiveLanguageSwitcherOpened(this.showLanguageBanner);
        // extended hints for locales, e.g. when sub site has more locales than core site or current site doesn't have a set locale
        if (this.showLocaleHints) {
            // show hint for additional languages once
            if (this.showLanguageBanner) {
                if (!Cookies.get('shown-language-hint')) {
                    this.bannerModel = true;
                    Cookies.set('shown-language-hint', 'true');
                }
            } else {
                Cookies.remove('shown-language-hint');
            }
            // show no content overlay
            if (this.showNoContentForLocale()) {
                if (!Cookies.get('shown-no-content-hint') && this.isSameDomain()) {
                    this.noContentModel = true;
                    Cookies.set('shown-no-content-hint', 'true');
                }
            } else {
                Cookies.remove('shown-no-content-hint');
            }
            // this will close the language banner if clicked anywhere outside of it
            document.addEventListener('click', this.onOutsideLanguageHintClick);
            // set current locale as cookie
            Cookies.set('prev-selected-locale', this.$lang);
        } else {
            // cleanup after setting switch
            if (Cookies.get('shown-language-hint')) {
                Cookies.remove('shown-language-hint');
            }
            if (Cookies.get('shown-no-content-hint')) {
                Cookies.remove('shown-no-content-hint');
            }
        }
    }

    beforeDestroy(): void {
        document.removeEventListener('scroll', this.onScroll);
    }

    toggleNavigation(): void {
        this.navStore.setNavigationOpened(!this.navigationOpened);
        // shouldn't be possible to open multiple navigations/overlays at the same time
        if (this.navigationOpened) {
            this.navStore.setGlobalNavOpened(false);
            this.navStore.setSearchOpened(false);
        }
    }

    toggleGlobalNavigation(): void {
        this.navStore.setGlobalNavOpened(!this.globalNavOpened);
        // shouldn't be possible to open multiple navigations/overlays at the same time
        if (this.globalNavOpened) {
            this.navStore.setNavigationOpened(false);
            this.navStore.setSearchOpened(false);
        }
    }

    toggleSearch(): void {
        this.navStore.setSearchOpened(!this.searchOpened);
        // shouldn't be possible to open multiple navigations/overlays at the same time
        if (this.searchOpened) {
            this.navStore.setNavigationOpened(false);
            this.navStore.setGlobalNavOpened(false);
        }
    }

    closeOverlays(): void {
        this.navStore.setGlobalNavOpened(false);
        this.navStore.setSearchOpened(false);
    }

    onScroll(e: Event): void {
        this.scrollPos = (e.target as Document).scrollingElement.scrollTop;
    }

    onOutsideLanguageHintClick(evt: Event) {
        let targetElement = evt.target as HTMLElement;
        do {
            if (targetElement === this.languageHint) {
                return;
            }
            targetElement = targetElement.parentElement;
        } while (targetElement);
        this.bannerModel = false;
    }

    private isSameDomain() {
        if (!document.referrer) {
            return false;
        }
        const url = new URL(document.referrer);
        return url.origin === window.origin;
    }

    private showNoContentForLocale() {
        if (this.prevSelectedLocale) {
            return !this.availableLocales.includes(this.prevSelectedLocale);
        } else {
            return false;
        }
    }
}
