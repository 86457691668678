export default {
    australia: {
        limits: {
            north: -1.181289,
            west: 92.4084234,
            south: -49.5121946,
            east: 174.6885864
        },
        features: [
            {
                name: 'Darwin',
                position: {
                    lat: -12.462827,
                    lng: 130.841782
                },
                dotColor: '#000000',
                fontColor: '#000000',
                fontClasses: 'text-para-xxs md:text-para-xs'
            },
            {
                name: 'Adelaide',
                position: {
                    lat: -34.921230,
                    lng: 138.599503
                },
                dotColor: '#000000',
                fontColor: '#000000',
                fontClasses: 'text-para-xxs md:text-para-xs'
            },
            {
                name: 'Perth',
                position: {
                    lat: -31.953512,
                    lng: 115.857048
                },
                dotColor: '#000000',
                fontColor: '#000000',
                fontClasses: 'text-para-xxs md:text-para-xs'
            },
            {
                name: 'Melbourne',
                position: {
                    lat: -37.840935,
                    lng: 144.946457
                },
                dotColor: '#000000',
                fontColor: '#000000',
                fontClasses: 'text-para-xxs md:text-para-xs'
            },
            {
                name: 'Sydney',
                position: {
                    lat: -33.865143,
                    lng: 151.209900
                },
                dotColor: '#000000',
                fontColor: '#000000',
                fontClasses: 'text-para-xxs md:text-para-xs'
            },
            {
                name: 'Brisbane',
                position: {
                    lat: -27.470125,
                    lng: 153.021072
                },
                dotColor: '#000000',
                fontColor: '#000000',
                fontClasses: 'text-para-xxs md:text-para-xs'
            },
            {
                name: 'Kalgoorlie',
                position: {
                    lat: -30.748890,
                    lng: 121.465836
                },
                dotColor: '#656565',
                fontColor: '#818181',
                fontClasses: 'text-para-xxs md:text-para-xs'
            },
            {
                name: 'Borroloola',
                position: {
                    lat: -16.0703997184,
                    lng: 136.307098772
                },
                dotColor: '#656565',
                fontColor: '#818181',
                fontClasses: 'text-para-xxs md:text-para-xs'
            },
            {
                name: 'Cairns',
                position: {
                    lat: -16.925491,
                    lng: 145.754120
                },
                dotColor: '#656565',
                fontColor: '#818181',
                fontClasses: 'text-para-xxs md:text-para-xs'
            },
            {
                name: 'Townsville',
                position: {
                    lat: -19.258965,
                    lng: 146.816956
                },
                dotColor: '#656565',
                fontColor: '#818181',
                fontClasses: 'text-para-xxs md:text-para-xs'
            },
            {
                name: 'Mackay',
                position: {
                    lat: -21.144337,
                    lng: 149.186813
                },
                dotColor: '#656565',
                fontColor: '#818181',
                fontClasses: 'text-para-xxs md:text-para-xs'
            },
            {
                name: 'Gladstone',
                position: {
                    lat: -23.843138,
                    lng: 151.268356
                },
                dotColor: '#656565',
                fontColor: '#818181',
                fontClasses: 'text-para-xxs md:text-para-xs'
            },
            {
                name: 'Newcastle',
                position: {
                    lat: -32.916668,
                    lng: 151.750000
                },
                dotColor: '#656565',
                fontColor: '#818181',
                fontClasses: 'text-para-xxs md:text-para-xs'
            }
        ]
    }
};
