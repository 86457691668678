






























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import VueRecaptcha from 'vue-recaptcha';
import GcButton from '../base/GcButton.vue';

@Component({
    components: {
        VueRecaptcha,
        GcButton
    }
})
export default class ReCaptchaSubmit extends Vue {
    @Prop({ required: true }) label: string;
    @Prop({ default: '---no-recaptcha---' }) siteKey: string;
    @Prop({ default: false }) disabled: boolean;

    verified = this.isDev;

    checked = false;

    get isDev() {
        return this.siteKey === '---no-recaptcha---';
    }

    get hasPrivacy() {
        if (!this.$slots.privacytext) this.checked = true;
        return this.$slots.privacytext && this.$slots.privacytext.length > 0;
    }

    get isTwoItems() {
        return !this.isDev || this.hasPrivacy;
    }
}
