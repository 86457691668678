




















import Vue from 'vue';
import { Component, Prop, Provide, Ref, Watch } from 'vue-property-decorator';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';

@Component
export default class Tabs extends Vue {
    @Provide('registerTab') childRegistration = this.registerItem;
    @Ref('slideContainer') slideContainer: HTMLElement;
    @Ref('buttons') buttons: HTMLElement[];
    @Prop({ default: false }) editMode: boolean;
    @Prop({ default: 'tab' }) uuid: string;
    @Prop({ default: 0 }) value: number;

    tabs = [];
    draggable = null;
    activeTab = 0;

    created() {
        gsap.registerPlugin(Draggable);
    }

    registerItem(tab) {
        this.tabs.push(tab);
        if (this.$children.filter(this.filterTabs).length === this.tabs.length) {
            if (this.tabs.length) {
                this.tabs[0].active = true;
            }
            if (this.editMode) {
                if (window.sessionStorage.getItem(this.uuid)) {
                    // select last selected tab
                    this.activateTab(parseInt(window.sessionStorage.getItem(this.uuid)));
                }
            } else {
                setTimeout(() => {
                    this.initDraggable();
                }, 300);
            }
        }
    }

    // check children to only count slide components
    filterTabs(child: Vue) {
        return child.$vnode && child.$vnode.componentOptions && child.$vnode.componentOptions.tag === 'tab';
    }

    activateTab(index) {
        this.activeTab = index;
        this.tabs.forEach((tab, i) => {
            tab.active = index === i;
        });
        const button = this.buttons[index];
        const rect = button.getBoundingClientRect();

        // clicked button is not completely visible -> move draggable
        let newPos;
        if (rect.x < 0) {
            newPos = this.draggable.x + Math.abs(rect.x);
        } else if (rect.x > this.slideContainer.clientWidth - button.clientWidth) {
            newPos = this.draggable.x - (button.clientWidth + rect.x - this.slideContainer.clientWidth);
        }
        if (newPos !== undefined) {
            gsap.to(this.slideContainer, 0.4, { x: newPos });
        }
    }

    initDraggable() {
        if (this.draggable) {
            this.draggable.kill();
            this.draggable = null;
        }
        if (!this.tabs.length || !this.buttons) {
            return;
        }
        const button = this.buttons[0];
        if (!button) {
            return;
        }
        const containerWidth = this.buttons.reduce((res, btn) => res + btn.clientWidth, 0);
        // no draggable needed if tabs fit on one page
        if (containerWidth <= this.slideContainer.clientWidth) {
            return;
        }
        this.draggable = new Draggable(this.slideContainer, {
            type: 'x',
            edgeResistance: 0.85,
            minimumMovement: 6,
            bounds: {
                top: 0,
                left: -containerWidth + this.slideContainer.clientWidth,
                width: containerWidth,
                height: this.slideContainer.clientHeight
            }
        });
    }

    updateSlideTransform() {
        if (!this.draggable) {
            return;
        }
        let buttonWidth = 0;
        if (this.tabs.length) {
            const button = this.buttons[0];
            if (!button) {
                return;
            }
            buttonWidth = button.clientWidth;
        }
        gsap.to(this.slideContainer, 0.4, { x: `-${Math.min(this.activeTab * buttonWidth, Math.abs(this.draggable.vars.bounds.minX))}px` });
    }

    // tab changed
    @Watch('activeTab')
    onTabChanged() {
        // save selected tab to session storage so it can be restored after reload
        // (for edit mode only)
        if (this.editMode) {
            window.sessionStorage.setItem(this.uuid, `${this.activeTab}`);
        }
    }
}
