











































































import { Component, Prop, Ref } from 'vue-property-decorator';
import Vue from 'vue';
import Icon from '../base/Icon.vue';
import Utils from '../../utils/Utils';
import SocioEconomicMapLegend from './SocioEconomicMapLegend.vue';
import SocioEconomicMapFiltering from './SocioEconomicMapFiltering.vue';
import SocioEconomicMapEmbed from './SocioEconomicMapEmbed.vue';
import SocioEconomicMapDetail from './SocioEconomicMapDetail.vue';
import SocioEconomicMapScrollEffect from './SocioEconomicMapScrollEffect';
import SocioEconomicMapUtils, { Operation, Region, SubOperation, SubRegion } from './SocioEconomicMapUtils';

@Component({
    components: {
        SocioEconomicMapLegend,
        SocioEconomicMapFiltering,
        SocioEconomicMapEmbed,
        SocioEconomicMapDetail,
        Icon
    }
})
export default class SocioEconomicMap extends Vue {
    /***********************
     * Vue Props
     ***********************/
    @Prop({ required: true }) operations: Array<Operation>;
    @Prop({ required: true }) regions: Array<Region>;
    @Prop({ default: () => ([]) }) sectionData: any;
    @Prop() labelTranslation: any;
    @Prop() sectionDisclaimer: string;

    // the container the map is placed in
    @Ref('map') map: HTMLElement;
    @Ref('detail') detail: SocioEconomicMapDetail;
    @Ref('detailContainer') detailContainer: HTMLElement;

    /***********************
     * Vue data
     ***********************/
    public locations: Array<Location> = null;
    private currentRegion: SubRegion = null;
    private currentOperation: SubOperation = null;

    private selectedMarker: Location = null;

    private filteringOpen = false;

    private scrollEffectLevel = '';

    private specialMapClasses = {};

    private showMap = true;

    private regionOption = {
        lat: -25.958045,
        lng: 134.123247
    }

    /***********************
     * Vue lifecycle
     ***********************/

    mounted() {
        this.setupScrollEffect();
    }

    /***********************
     * Vue Methods
     ***********************/

    protected setupScrollEffect() {
        this.$nextTick(() => {
            const effect = new SocioEconomicMapScrollEffect(
                this.$refs.map as HTMLElement,
                (this.$refs.detail as Vue).$el as HTMLElement,
                () => {
                    this.showMap = true;
                    this.specialMapClasses = {
                        position: 'relative'
                    };
                    (this.$refs.detail as SocioEconomicMapDetail).toggleOverlayOffset(0);
                    (this.$refs.detail as SocioEconomicMapDetail).setChevronDown(false);
                },
                () => {
                    this.showMap = true;
                    this.scrollEffectLevel = 'detailContainer';
                    this.specialMapClasses = {
                        position: 'fixed',
                        top: '56px',
                        left: 0
                    };
                    (this.$refs.detail as SocioEconomicMapDetail).toggleOverlayOffset((this.$refs.map as HTMLElement).clientHeight - 50);
                    (this.$refs.detail as SocioEconomicMapDetail).setChevronDown(false);
                },
                () => {
                    this.showMap = false;
                    (this.$refs.detail as SocioEconomicMapDetail).setChevronDown(true);
                },
                () => {
                    this.showMap = false;
                    (this.$refs.detail as SocioEconomicMapDetail).setChevronDown(false);
                }
            );
            effect.handleScrollEffect();
        });
    }

    protected translate(key): string {
        const translation = this.labelTranslation.find(x => x.key === key);
        if (translation) {
            return translation.value;
        }
        return '';
    }

    protected openFiltering(): void {
        Utils.lockBodyScroll('md');
        this.filteringOpen = true;
    }

    private handleMarkerClicked(marker: Location, operation: SubOperation) {
        if (this.currentOperation && this.currentOperation.uuid === operation.uuid) {
            // case: a selected operation's poi is clicked: deselect.
            this.unsetOperation();
        } else {
            // case: a poi of an unselected operation is clicked: select.
            this.setOperation(operation);
            this.selectedMarker = marker;
        }
    }

    private setRegion(filter: SubRegion): void {
        this.unsetOperation();
        this.currentRegion = filter;
    }

    private unsetRegion(): void {
        this.currentRegion = null;
    }

    private setOperation(operation: SubOperation): void {
        this.unsetRegion();
        this.currentOperation = operation;
    }

    private unsetOperation(): void {
        this.currentOperation = null;
        this.selectedMarker = null;
    }

    private unsetQuery(): void {
        this.unsetRegion();
        this.unsetOperation();
    }

    /***********************
     * Vue Computed
     ***********************/

    get filteredOperations(): Array<Operation> {
        // Case: Region is set.
        if (this.currentRegion !== null) {
            return SocioEconomicMapUtils.getOperationsFromRegion(this.currentRegion, this.operations);
        }
        // Case: Operation is set.
        if (this.currentOperation !== null) {
            return [{ ...SocioEconomicMapUtils.getParentOperation(this.currentOperation, this.operations), ...{ items: [this.currentOperation] } }];
        }
        // Case: Neither a Region nor an Operation is set.
        return this.operations;
    }

    get allSubOperations(): Array<SubOperation> {
        const result: Array<SubOperation> = [];
        this.operations.forEach(operation => {
            operation.items.forEach(item => {
                result.push(item);
            });
        });
        return result;
    }
}
