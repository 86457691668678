
























































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DataPoint, MonitoringLabels } from './MonitoringGraph.vue';
import breakpoints from '../../plugins/breakpoints';

@Component
export default class GraphView extends Vue {
    @Prop({ required: true }) labels: MonitoringLabels;
    @Prop({ required: true }) data: DataPoint[];
    @Prop({ default: 1000 }) maxValue: number;

    get _fiveMinTime(): string {
        if (this.data.length > 0) {
            return this.formatDate(this.data[0].value5Time);
        }
        return '-';
    }

    get _hourlyTime(): string {
        if (this.data.length > 0) {
            return this.formatDate(this.data[0].value60Time);
        }
        return '-';
    }

    get colspan(): number {
        return breakpoints.mdAndUp ? 10 : 5;
    }

    get interval(): number {
        return Math.floor(this.maxValue / 10);
    }

    formatDate(dateStr: string): string {
        return dateStr.replace(/.*\s(\d{1,2}:\d{2}).*/, (a, b) => b);
    }

    calcStyles(value: number): Record<string, unknown> {
        const percentage = Math.min((Math.max(value, 0) / this.maxValue) * 100, 100);
        return {
            width: `${percentage}%`
        };
    }
}
