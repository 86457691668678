import Vue from 'vue';

export default class TabActivator {
    constructor(vue: Vue) {
        // select all links which contain a #
        const _links = document.querySelectorAll('a[href*="#"]');
        if (_links.length > 0) {
            const links = Array.prototype.slice.call(_links);
            links.forEach(elem => elem.addEventListener('click', ev => {
                this.activate(ev, vue);
            }));
        }
    }

    activate(event, vue: Vue) {
        if (event.currentTarget != null && event.currentTarget.hash != null) {
            const id = event.currentTarget.hash.substring(1);
            vue.$store.commit('setActivatedTab', id);
        }
    }
}
