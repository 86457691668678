























































import Vue from 'vue';
import { Component, Prop, Provide, ProvideReactive, Ref } from 'vue-property-decorator';
import GcButton from '../base/GcButton.vue';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios from 'axios';
import UserComment from './UserComment.vue';

export interface Comment {
    id?: number;
    componentId?: string;
    title?: string;
    text: string;
    fullName: string;
    email: string;
    date?: number;
    comments?: Comment[];
    role?: 'moderator' | 'staff';
}

export interface CommentLabels {
    placeholder: string;
    submit: string;
    comments: string;
    reply: string;
    readMore: string;
    readLess: string;
    showPrevious: string;
    moderator: string;
    staff: string;
}

@Component({
    components: {
        UserComment,
        GcButton
    }
})
export default class PublicUserComments extends Vue {
    @Prop({ required: true }) componentId: string;
    @Prop({ required: true }) labels: CommentLabels;
    @Prop({ required: true }) fullName: string;
    @Prop({ required: true }) email: string;
    @Prop({ default: false, type: Boolean }) isAdmin: boolean;
    @Prop({ default: false, type: Boolean }) disabled: boolean;

    @Ref() comment: InstanceType<typeof UserComment>[];

    apiBaseUrl = Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Enums.API.COMMENTS;
    comments: Comment[] = [];

    @ProvideReactive() loading = false;

    textModel = '';
    showComment: Record<string, boolean> = {};
    @Provide() replyModel: Record<string | number, string> = {};
    @Provide() replyMode: Record<string | number, boolean> = {};
    @Provide() editMode: Record<string | number, boolean> = {};

    async mounted(): Promise<void> {
        await this.getComments();
        window.addEventListener('resize', this.createReadMore);
    }

    get commentsUrl(): string {
        return this.apiBaseUrl + '/all/' + this.componentId;
    }

    get postNewCommentUrl(): string {
        return this.apiBaseUrl;
    }

    get updateCommentUrl(): string {
        return this.apiBaseUrl + '/update';
    }

    get deleteCommentUrl(): string {
        return this.apiBaseUrl + '/delete';
    }

    @Provide()
    autosize(ev: Event): void {
        setTimeout(function() {
            const el: HTMLTextAreaElement = ev.target as HTMLTextAreaElement;
            el.style.cssText = 'height:auto; padding:12px 16px';
            // for box-sizing other than "content-box" use:
            // el.style.cssText = '-moz-box-sizing:content-box';
            el.style.cssText = 'height:' + (el.scrollHeight + 2) + 'px';
        }, 0);
    }

    submitComment(): void {
        if (this.textModel) {
            this.postNewComment();
        }
    }

    @Provide()
    toggleReply(comment: Comment, key: string | number): void {
        // this will toggle the visibility of the text box under the comment
        Vue.set(this.replyMode, key, !this.replyMode[key]);
        // this will prefill/empty the text box with the commentator's name we're replying to
        if (this.replyMode[key]) {
            Vue.set(this.replyModel, key, '@' + comment.fullName + ' ');
        } else {
            Vue.set(this.replyModel, key, '');
        }
    }

    @Provide()
    toggleEditMode(key: string | number): void {
        Vue.set(this.editMode, key, !this.editMode[key]);
        this.$nextTick(() => this.createReadMore());
    }

    showPrevious(id: number): void {
        Vue.set(this.showComment, id, true);
    }

    @Provide()
    formatText(text: string): string {
        return text
            // wrap links
            .replace(/(http(s)?:\/\/\S+)/g, '<a href="$1" class="link" target="_blank">$1</a>')
            // replace line breaks
            .replace(/(\r\n|\r|\n)/g, '<br>');
    }

    @Provide()
    createReadMore(): void {
        if (!this.comment) return;
        // clientHeight: current rendered height
        // scrollHeight: real element height (including overflow)
        // so if clientHeight < scrollHeight -> there's a read more button needed
        this.comment.forEach(cEl => {
            const c = cEl.text;
            if (c) {
                if (c.clientHeight < c.scrollHeight && !c.nextElementSibling) {
                    const wrapper = document.createElement('div');
                    const button = document.createElement('a');
                    wrapper.classList.add('prose', 'mb-6', '-mt-4');
                    wrapper.appendChild(button);
                    button.classList.add('link', 'cursor-pointer');
                    button.innerText = this.labels.readMore;
                    button.addEventListener('click', () => {
                        this.toggleReadMore(c, button);
                    });
                    c.parentElement.appendChild(wrapper);
                } else if (c.clientHeight === c.scrollHeight && c.nextElementSibling) {
                    c.style.maxHeight = '';
                    c.style.webkitLineClamp = '';
                    c.nextElementSibling.remove();
                }
            }
        });
    }

    toggleReadMore(el: HTMLParagraphElement, button: HTMLElement): void {
        const rem = el.scrollHeight / 16;
        if (el.style.maxHeight) {
            el.style.maxHeight = '';
            button.innerText = this.labels.readMore;
            // wait for the transition to finish before reapplying the default clamping
            setTimeout(() => {
                el.style.webkitLineClamp = '';
            }, 300);
        } else {
            el.style.maxHeight = `${rem}rem`;
            el.style.webkitLineClamp = '99';
            button.innerText = this.labels.readLess;
        }
    }

    reset(): void {
        // this will reset all edit forms and texts
        this.textModel = '';
        Object.keys(this.replyMode).forEach(key => Vue.delete(this.replyMode, key));
        Object.keys(this.editMode).forEach(key => Vue.delete(this.editMode, key));
        Object.keys(this.replyModel).forEach(key => Vue.delete(this.replyModel, key));
    }

    @Provide()
    async getComments(): Promise<void> {
        this.loading = true;
        const res = await axios.get(this.commentsUrl);
        if (res.status === 200) {
            this.reset();
            this.comments = res.data;
            await this.$nextTick();
            this.createReadMore();
        }
        this.loading = false;
    }

    async postNewComment(): Promise<void> {
        const comment: Comment = {
            componentId: this.componentId,
            text: this.textModel,
            fullName: this.fullName,
            email: this.email
        };
        this.loading = true;
        const res = await axios.post(this.postNewCommentUrl, comment);
        if (res.status === 200) {
            this.textModel = '';
            await this.getComments();
        }
        this.loading = false;
    }

    @Provide()
    async replyToComment(id: number, key: string | number): Promise<void> {
        const url = this.postNewCommentUrl + '/' + id;
        const comment: Comment = {
            text: this.replyModel[key],
            fullName: this.fullName,
            email: this.email
        };
        this.loading = true;
        const res = await axios.post(url, comment);
        if (res.status === 200) {
            Vue.set(this.replyModel, key, '');
            Vue.set(this.replyMode, key, false);
            // this will make sure that after replying there is no "show more comments" and all child comments are shown
            Vue.set(this.showComment, id, true);
            await this.getComments();
        }
        this.loading = false;
    }

    @Provide()
    async updateComment(comment: Comment, key: string | number): Promise<void> {
        const url = this.updateCommentUrl;
        this.loading = true;
        const res = await axios.post(url, comment);
        if (res.status === 200) {
            Vue.set(this.editMode, key, false);
            await this.getComments();
        }
        this.loading = false;
    }

    @Provide()
    async deleteComment(id: number): Promise<void> {
        const url = this.deleteCommentUrl + '/' + id;
        this.loading = true;
        const res = await axios.post(url);
        if (res.status === 200) {
            await this.getComments();
        }
        this.loading = false;
    }
}
