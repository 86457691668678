







































import Icon from '../base/Icon.vue';
import ArrowButton from '../base/ArrowButton.vue';
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import SocioEconomicMapDetailSlider from '../socioEconomicMap/SocioEconomicMapDetailSlider.vue';

@Component({
    components: {
        Icon,
        ArrowButton,
        SocioEconomicMapDetailSlider
    }
})
export default class MapDetail extends Vue {
    /***********************
     * Vue Props
     ***********************/

    @Prop({ default: null }) location: any;
    @Prop({ default: false }) hideCategories: boolean;
    @Prop({ default: '' }) labelVisitWebsite: string;

    /***********************
     * Vue Methods
     ***********************/

    closeLocation() {
        this.$emit('close-location');
    }
}
