









import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import TabHeader from '../base/TabHeader.vue';

@Component({
    components: {
        TabHeader
    }
})
export default class GlobalNavigation extends Vue {
    @Prop() tabs: Record<string, string>;
    @Prop({ default: '' }) region: string;

    currentTab = 0;

    get tabTitles(): string[] {
        return Object.values(this.tabs);
    }

    created(): void {
        if (this.region && this.tabs[this.region]) {
            this.currentTab = Object.keys(this.tabs).indexOf(this.region);
        }
    }
}
