
































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import Utils from '../../../utils/Utils';

@Component
export default class Facebook extends Vue {
    @Prop() data: Record<string, string>;

    @Inject() isVertical: boolean;
    @Inject() showChannel: boolean;

    redraw = debounce(this.$redrawVueMasonry, 300);

    show = false;

    mounted() {
        Utils.observeIntersection(this.$refs.observable, () => { this.show = true; });
    }

    get classes(): Record<string, boolean> {
        return {
            'text-black-900': !this.hasImage,
            'text-white': this.hasImage
        };
    }

    get differenceClasses(): Record<string, boolean> {
        return {
            'text-black-500 group-hover:text-primary-500': !this.hasImage,
            'text-white group-hover:text-primary-500': this.hasImage
        };
    }

    get hasImage(): boolean {
        return this.data?.mediaType === 'photo' || this.data?.mediaType === 'video' || this.data?.mediaType === 'album';
    }

    imageLoaded(): void {
        this.redraw();
    }
}
