












import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import OverlayLink from '../portalNavigator/OverlayLink.vue';

@Component({
    components: {
        OverlayLink
    }
})
export default class SearchLink extends Vue {
    @Prop() url: string;
    @Prop() overlay: boolean;
}
