


































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Icon from '../base/Icon.vue';
import breakpoints from '../../plugins/breakpoints';

@Component({
    components: {
        Icon
    }
})
export default class QuickLinks extends Vue {
    @Prop({ default: 0 }) closeDelay!: number;
    @Prop({ default: ' ' }) title: string;
    @Prop({ default: 'quicklinks' }) ariaLabel: string;

    open: boolean = this.closeDelay > 0;

    mounted() {
        if (this.closeDelay > 0) {
            setTimeout(() => {
                this.open = false;
            }, this.closeDelay);
        }
    }

    get computedHeight() {
        return `calc(100vh - ${this.isMobile ? this.offset * 2 : this.offset}px`;
    }

    click(event) {
        // close overlay if any link was clicked
        if (event.target.closest('a')) {
            this.toggle();
        }
    }

    toggle() {
        this.open = !this.open;
    }

    get isMobile(): boolean {
        return breakpoints.mobile;
    }

    get offset(): number {
        return this.isMobile ? 56 : 118;
    }
}
