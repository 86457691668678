



















































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios, { AxiosResponse } from 'axios';
import SearchableGroupDropdown from '../base/SearchableGroupDropdown.vue';
import { Prop, Watch } from 'vue-property-decorator';
import GcButton from '../base/GcButton.vue';
import Icon from '../base/Icon.vue';
import LoadingSpinner from '../base/LoadingSpinner.vue';
import breakpoints from '../../plugins/breakpoints';
import debounce from 'lodash/debounce';

export interface Career {
    id: number;
    jobId: string;
    feed: string;
    title: string;
    language: string;
    locationTitle: string;
    department: string;
    city: string;
    region: string;
    country: string;
    channel: string;
    highlights: string[];
    description: string;
    url: string;
    applicationLink: string;
    startDate: number;
    endDate: number;
    lastUpdated: number;
    version: number;
}

interface CareerLabels {
    placeholder: string;
    filter: string;
    results: string;
    sortBy: string;
    new: string;
    closingDate: string;
    loadMore: string;
    ascending: string;
    descending: string;
    never: string;
    error: string;
}

@Component({
    components: {
        LoadingSpinner,
        SearchableGroupDropdown,
        GcButton,
        Icon
    }
})
export default class CareerListV2 extends Vue {
    @Prop({ default: 10 }) limit: number;
    @Prop({ default: {} }) filters: Record<string, string>;
    @Prop({ default: {} }) preFilters: Record<string, string>;
    @Prop({ default: {} }) hardcodedFilters: Record<string, string>;
    @Prop({ default: {} }) columns: Record<string, string>;
    @Prop({ default: () => [] }) countries: string[];
    @Prop({ default: {} }) labels: CareerLabels;
    @Prop({ default: false, type: Boolean }) inContentGroup!: boolean;
    @Prop({ default: 10, type: Number }) farFutureThreshold!: number;
    @Prop({ default: '' }) uuid: string;
    @Prop({ default: '' }) localeOverride!: string;

    // threshold in days which will decide if a posting is considered new
    newThreshold = 14;

    careers: Career[] = [];

    filterData: Record<string, string[]> = {};
    initialFilterData: Record<string, string[]> = {};

    initialized = false;
    currentPage = 0;
    totalResults = 0;
    loading = false;
    error = false;

    keyword = '';
    lastKeyword = '';
    sorting = '';

    searchCriteria: Record<string, string> = {};

    apiBaseUrl = Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) +
        Enums.API.LOCATION_V2 + Enums.API.CAREERS +
        'locale=' + (this.localeOverride || this.$lang);

    debouncedKeywordSearch: Function = debounce(this.keywordSearch, 500);

    mounted(): void {
        // sort by first column ascending by default
        if (Object.keys(this.columns).length > 0) {
            this.sorting = Object.keys(this.columns)[0] + '-asc';
        }

        // set default values to filters
        Object.keys(this.filters).forEach(f => Vue.set(this.searchCriteria, f, ''));
        if (sessionStorage.getItem(this.filterStorageKey)) {
            // if applicable: override filters with state retrieved from session storage
            const criteria: Record<string, string> = JSON.parse(sessionStorage.getItem(this.filterStorageKey));
            Object.keys(criteria).forEach(c => Vue.set(this.searchCriteria, c, criteria[c]));
        }
        // set prefilters and hardcoded filters as last
        Object.keys(this.preFilters).forEach(f => Vue.set(this.searchCriteria, f, this.preFilters[f]));

        // wait for watchers to initially trigger before continuing
        this.$nextTick(() => {
            this.initialized = true;
            this.loadCareers();
        });
    }

    get filterStorageKey(): string {
        return 'careerFilters_' + this.uuid;
    }

    get filterKeys(): string[] {
        return Object.keys(this.filters);
    }

    get filterOptions(): Record<string, Record<string, string[]>> {
        const options: Record<string, Record<string, string[]>> = {};
        Object.keys(this.filterData).forEach(fd => {
            const option: Record<string, string[]> = {};
            option[this.filters[fd]] = this.filterData[fd];
            options[fd] = option;
        });
        return options;
    }

    get apiUrl(): string {
        return this.apiBaseUrl +
            this.countries.map(c => '&country=' + c).join('') +
            '&sortBy=' + this.sorting +
            '&offset=' + (this.currentPage * this.limit) +
            '&limit=' + this.limit +
            '&searchCriteria=' + encodeURI(JSON.stringify(this.extendedSearchCriteria)) +
            '&keyword=' + encodeURI(this.lastKeyword.trim());
    }

    get hasMore(): boolean {
        return this.currentPage > 0 && (this.totalResults > (this.currentPage * this.limit));
    }

    get isMobile(): boolean {
        return breakpoints.mobile;
    }

    get extendedSearchCriteria(): Record<string, string[]> {
        const extendedSearchCriteria = {};
        Object.keys(this.searchCriteria).forEach(k => {
            extendedSearchCriteria[k] = [this.searchCriteria[k]];
        });
        Object.keys(this.hardcodedFilters).forEach(k => {
            const l = extendedSearchCriteria[k] || [];
            l.push(this.hardcodedFilters[k]);
            extendedSearchCriteria[k] = l;
        });
        return extendedSearchCriteria;
    }

    isFarFuture(date: number): boolean {
        const current = new Date();
        current.setFullYear(current.getFullYear() + this.farFutureThreshold);
        return date > current.getTime();
    }

    getCombinedLocation(career: Career): string {
        // INFO: the 'zwsp' value is used in the location mapper for unknown locations
        const locationParts = [];

        if (career.city && career.city !== '​') {
            locationParts.push(career.city);
        }
        if (career.region && career.region !== '​') {
            locationParts.push(career.region);
        }
        if (career.country && career.country !== '​') {
            locationParts.push(career.country);
        }

        return locationParts.join(', ');
    }

    isNew(postingDate: number): boolean {
        const date = new Date();
        date.setDate(date.getDate() - this.newThreshold);
        return postingDate > date.getTime();
    }

    getLink(career: Career): string {
        return window.location.pathname + '/' + encodeURI(career.jobId) + (this.localeOverride ? '?locale=' + this.localeOverride : '');
    }

    loadCareers(): void {
        this.loading = true;
        this.error = false;
        axios.get(this.apiUrl).then((res) => {
            if (res.status === 200) {
                if (this.currentPage === 0) {
                    this.careers = res.data.data;
                    this.totalResults = res.data.totalResults;
                    this.applyFilters(res);
                } else {
                    this.careers = this.careers.concat(res.data.data);
                }
                this.currentPage++;
            }
        }).catch(() => {
            this.error = true;
        }).finally(() => {
            this.loading = false;
        });
    }

    applyFilters(res: AxiosResponse<any>) {
        // normal case: apply filters as delivered by response
        const tmpFilterData: Record<string, string[]> = res.data.filters;
        Object.keys(tmpFilterData).forEach(filterKey => {
            Vue.set(this.filterData, filterKey, tmpFilterData[filterKey]);
        });
        const setFilters = Object.keys(this.searchCriteria).filter(key => !!this.searchCriteria[key]).length;
        if (setFilters === 0 || Object.keys(this.initialFilterData).length === 0) {
            // zero filters or no initial state present: set initial state
            this.initialFilterData = JSON.parse(JSON.stringify(this.filterData));
        } else if (setFilters === 1) {
            // only one filter set: reset the data for this key to initial state
            const key = Object.keys(this.searchCriteria).find(key => !!this.searchCriteria[key]);
            Vue.set(this.filterData, key, this.initialFilterData[key]);
        }
    }

    reset(): void {
        this.keyword = '';
        if (this.lastKeyword) {
            this.lastKeyword = '';
            this.search();
        }
    }

    keywordSearch(): void {
        if (this.keyword.trim().length >= 3) {
            this.lastKeyword = this.keyword.trim();
            this.search();
        } else if (this.lastKeyword) {
            this.lastKeyword = '';
            this.search();
        }
    }

    search(): void {
        if (!this.initialized) return;
        this.currentPage = 0;
        this.loadCareers();
        sessionStorage.setItem(this.filterStorageKey, JSON.stringify(this.searchCriteria));
    }

    @Watch('searchCriteria', { deep: true })
    watchFilters(): void {
        this.search();
    }

    @Watch('sorting')
    watchSorting(): void {
        this.search();
    }

    @Watch('keyword')
    watchKeyword(): void {
        this.debouncedKeywordSearch();
    }
}
