














import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop } from 'vue-property-decorator';

@Component
export default class Rss extends Vue {
    @Prop() data: Record<string, string>;

    @Inject() isVertical: boolean;
}
