


























































































































import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import gsap from 'gsap';
import { easeOut, Timeline, update } from './animations';
import VideoModal from '../video/VideoModal.vue';
import Icon from '../base/Icon.vue';
import PlayButton from '../video/PlayButton.vue';
import breakpoints from '../../plugins/breakpoints';
import ResponsiveImage from '../base/ResponsiveImage.vue';

declare const window: any;

@Component({
    components: {
        ResponsiveImage,
        VideoModal,
        PlayButton,
        Icon
    }
})
/* eslint-disable @typescript-eslint/camelcase */
export default class StagePromoTeaser extends Vue {
    @Prop() readonly language!: string;
    @Prop() readonly backgroundImageUrls: Record<string, string>;
    @Prop() readonly seeLabel: string;
    @Prop() readonly title: string;
    @Prop() readonly locales: Record<string, string>;
    @Prop() readonly pdfLink: string;
    @Prop() readonly downloadPdfLabel: string;
    @Prop() readonly anchorLink: string;
    @Prop() readonly quote: string;
    @Prop() readonly ceoName: string;
    @Prop() readonly ceoTitle: string;
    @Prop({ default: false, type: Boolean }) readonly hideSignature: boolean;
    @Prop() readonly watchLabel: string;
    @Prop() readonly video: string;
    @Prop() readonly accountId: string;
    @Prop() readonly playerId: string;
    @Prop() readonly customSignature: string;

    @Ref() readonly wrapper: HTMLDivElement;
    @Ref() readonly downloadPdfBtn!: HTMLAnchorElement;
    @Ref() readonly h1!: HTMLHeadingElement;
    @Ref() readonly langSelect!: HTMLDivElement;
    @Ref() readonly pdfLabel!: HTMLSpanElement;
    @Ref() readonly playBtn!: HTMLDivElement;
    @Ref() readonly playVideoLabel!: HTMLButtonElement;
    @Ref() readonly scrollIndicator!: SVGElement;

    @Ref() readonly quoteSection!: HTMLDivElement;
    @Ref() readonly ceoQuoteRef!: HTMLParagraphElement;
    @Ref() readonly ceoNameRef!: HTMLParagraphElement;
    @Ref() readonly ceoTitleRef!: HTMLParagraphElement;
    @Ref() readonly ceoSignatureRef!: HTMLDivElement;

    currentLang = this.language;
    currentVideo = null;

    mounted(): void {
        this.animateTitleSection();
        setTimeout(() => this.animateQuoteSection(), 100);
    }

    get showQuoteSection(): boolean {
        return !!this.quote || !this.hideSignature || !!this.ceoName || !!this.ceoTitle || !!this.video;
    }

    animateTitleSection(): void {
        const tl = gsap.timeline({ paused: false });
        tl.fromTo(this.h1, { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 1.0, ease: easeOut }, 0.8);
        tl.fromTo(this.langSelect, { opacity: 0, y: -20 }, { opacity: 1, y: 0, duration: 1.0, ease: easeOut }, 1.1);

        tl.fromTo(this.downloadPdfBtn, { opacity: 0, y: 15 }, { opacity: 1, y: 0, duration: 0.3, ease: easeOut }, 1.6);

        tl.fromTo(this.pdfLabel, { opacity: 0, y: 15 }, {
            opacity: 1,
            y: 0,
            duration: 0.6,
            ease: easeOut
        }, 1.4);

        tl.fromTo(this.scrollIndicator, { opacity: 0 }, { opacity: 1, duration: 0.6 }, 1.9);
        this.animateScrollDown(this.scrollIndicator);
    }

    animateScrollDown(element: SVGElement): void {
        const tl = gsap.timeline({ paused: false, repeat: -1 });

        const circles: NodeListOf<SVGCircleElement> = element.querySelectorAll('circle');
        const arrow = element.querySelector('path');
        let timingOffset = 0;

        Array.from(circles).forEach(circle => {
            tl.to(circle, { opacity: 0, duration: 0.2 }, timingOffset);
            tl.to(circle, { opacity: 1, duration: 0.2 }, timingOffset + 0.2);
            timingOffset += 0.2;
        });

        tl.to(arrow, { y: 3, duration: 0.5 }, 0.0);
        tl.to(arrow, { y: 0, duration: 0.5 }, 0.5);
    }

    animateQuoteSection(): void {
        const tl = Timeline(true);

        if (!window.animations) {
            window.animations = {};
        }
        window.animations.last_t = window.performance.now();
        window.animations.scroll = window.scrollY;
        window.animations.screen_w = window.innerWidth;
        window.animations.screen_h = window.innerHeight;
        window.animations.$signature = this.ceoSignatureRef.querySelector('svg');

        tl.fromTo(this.playBtn, { opacity: 0, y: 15 }, { opacity: 1, y: 0, duration: 0.3, ease: easeOut }, 1.2);
        tl.fromTo(this.playVideoLabel, { opacity: 0, y: 15 }, { opacity: 1, y: 0, duration: 0.6, ease: easeOut }, 1);

        tl.fromTo(this.ceoQuoteRef, { opacity: 0, y: 15 }, { opacity: 1, y: 0, duration: 0.5, ease: easeOut }, 0.0);
        tl.fromTo(this.ceoNameRef, { opacity: 0, y: 15 }, { opacity: 1, y: 0, duration: 0.5, ease: easeOut }, 0.3);
        tl.fromTo(this.ceoTitleRef, { opacity: 0, y: 15 }, { opacity: 1, y: 0, duration: 0.5, ease: easeOut }, 0.6);

        const paths = Array.from(this.ceoSignatureRef.querySelectorAll('path'));
        let t = 1.0;
        const path_lengths = new Array(paths.length);
        let max_length = 0;

        for (let i = 0; i < paths.length; ++i) {
            const l = paths[i].getTotalLength();
            path_lengths[i] = l;
            if (l > max_length) max_length = l;
        }

        for (let i = 0; i < paths.length; ++i) {
            const l = path_lengths[i];
            const draw_time = (l / max_length) * 1.25;

            tl.fromTo(paths[i],
                { strokeDasharray: l, strokeDashoffset: l },
                { strokeDashoffset: 0, duration: draw_time },
                t);
            t += draw_time;
        }

        window.animations.signature = tl;

        window.requestAnimationFrame(update);
    }

    scrollDown(): void {
        if (this.showQuoteSection) {
            const offset = breakpoints.mdAndUp ? 80 : 56;
            window.scrollBy({
                top: this.quoteSection.getBoundingClientRect().top - offset,
                behavior: 'smooth'
            });
        } else {
            window.scroll({
                top: this.wrapper.clientHeight,
                behavior: 'smooth'
            });
        }
    }

    openVideoModal(): void {
        this.currentVideo = this.video;
    }

    @Watch('currentLang')
    onLangChange(): void {
        const path = window.location.pathname;
        const localeSegment = `/${this.currentLang}/`;
        window.location.href = path.replace(/\/([a-z]{2,3})\//, localeSegment);
    }
}
