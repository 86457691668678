




















































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import ResponsiveImage from '../base/ResponsiveImage.vue';
import Icon from '../base/Icon.vue';

@Component({
    components: {
        ResponsiveImage,
        Icon
    }
})
export default class FlipCard extends Vue {
    @Prop({ default: 'portrait' }) rendition: 'portrait' | 'landscape';
    @Prop({ required: true }) imageUrls: Record<string, string>;
    @Prop() title: string;
    @Prop() description: string;
    @Prop({ default: true }) showGradient: boolean;

    flipped = false;
}
