





















































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, InjectReactive, Prop } from 'vue-property-decorator';
import { GolfEventLabels, Participant } from './GolfEventTypes';
import { ValidationObserver } from 'vee-validate';
import ArrowButton from '../base/ArrowButton.vue';

@Component({
    components: {
        ValidationObserver,
        ArrowButton
    }
})
export default class GolfEventParticipationStep extends Vue {
    @Prop({ required: true }) labels: GolfEventLabels;

    @InjectReactive()
    participant: Participant;

    get dinnerModel(): string[] {
        const result: string[] = [];
        if (this.participant.dinnerDay1) result.push('dinner1');
        if (this.participant.dinnerDay2) result.push('dinner2');
        return result;
    }

    set dinnerModel(newVal: string[]) {
        Vue.set(this.participant, 'dinnerDay1', newVal.indexOf('dinner1') >= 0);
        this.updateProperty('dinnerDay1', newVal.indexOf('dinner1') >= 0);
        Vue.set(this.participant, 'dinnerDay2', newVal.indexOf('dinner2') >= 0);
        this.updateProperty('dinnerDay2', newVal.indexOf('dinner2') >= 0);
    }

    get golfModel(): string[] {
        const result: string[] = [];
        if (this.participant.golfTournament) result.push('golf');
        return result;
    }

    set golfModel(newVal: string[]) {
        Vue.set(this.participant, 'golfTournament', newVal.indexOf('golf') >= 0);
        this.updateProperty('golfTournament', newVal.indexOf('golf') >= 0);
    }

    get bikeModel(): string[] {
        const result: string[] = [];
        if (this.participant.bikeTour) result.push('bike');
        return result;
    }

    set bikeModel(newVal: string[]) {
        Vue.set(this.participant, 'bikeTour', newVal.indexOf('bike') >= 0);
        this.updateProperty('bikeTour', newVal.indexOf('bike') >= 0);
    }

    @Inject('updateData')
    updateData: (background: boolean) => void;

    @Inject()
    updateProperty: (name: string, value: any) => void;

    @Inject('nextStep')
    nextStep: () => void;

    @Inject('previousStep')
    previousStep: () => void;

    @Inject()
    toOverview: () => void;

    mounted(): void {
        this.$el.scrollIntoView({ behavior: 'smooth' });
    }

    onFocusChange(passed: boolean, name: string, value: any): void {
        if (passed) {
            this.updateProperty(name, value);
        }
    }

    onSubmit(validate: () => Promise<boolean>): void {
        validate().then((valid) => {
            if (valid) {
                this.nextStep();
            }
        });
    }
}
