







































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CalendarEvent, EventsLabels } from './EventsSection.vue';

@Component
export default class EventsTable extends Vue {
    @Prop({ default: () => [] }) events: CalendarEvent[];
    @Prop({ default: {} }) labels: EventsLabels;
    @Prop({ default: {} }) showAlert: boolean;
}
