











import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import Icon from '../base/Icon.vue';

@Component({
    components: { Icon }
})
export default class SocioEconomicMapZoomButtons extends Vue {
    /***********************
     * Vue Methods
     ***********************/
    private zoomIn() {
        this.$emit('zoomIn');
    }

    private zoomOut() {
        this.$emit('zoomOut');
    }
}
