


















































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { TailingsData, TailingsLabels, TailingsQuestion, TailingsRow } from './TailingsTypes';
import Pagination from '../base/Pagination.vue';
import Icon from '../base/Icon.vue';
import axios from 'axios';

@Component({
    components: {
        Icon,
        Pagination
    }
})
export default class TailingsTable extends Vue {
    @Prop({ default: {} }) labels: TailingsLabels;
    @Prop({ default: [] }) questions: TailingsQuestion[];
    @Prop({ default: 20 }) pageSize: number;

    region = '';
    data: TailingsRow[] = [];
    currentPage = 1;
    detailModel = {};
    searchTerm = '';
    ascending = true;
    sortProperty = '';
    filterKeys = ['name', 'ownership', 'status', 'dateinitial', 'currmaxheight'];

    get paged(): TailingsRow[] {
        if (this.sorted.length <= this.pageSize) {
            return this.sorted;
        }
        return this.sorted.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
    }

    get sorted(): TailingsRow[] {
        const sorted = this.filtered.sort((j1, j2) => {
            if (j1[this.sortProperty] && j2[this.sortProperty]) {
                if (!isNaN(j1[this.sortProperty]) && !isNaN(j2[this.sortProperty])) {
                    return Number(j1[this.sortProperty] - Number(j2[this.sortProperty]));
                }
                return j1[this.sortProperty].localeCompare(j2[this.sortProperty]);
            }
            return 0;
        });
        if (!this.ascending) {
            sorted.reverse();
        }
        return sorted;
    }

    get filtered(): TailingsRow[] {
        if (this.searchTerm) {
            return this.data.filter(d => {
                let result = false;
                this.filterKeys.forEach(k => {
                    if (d[k] && d[k].toLowerCase().includes(this.searchTerm.toLowerCase())) {
                        result = true;
                    }
                });
                return result;
            });
        }
        return this.data;
    }

    get pages(): number {
        return Math.ceil(this.filtered.length / this.pageSize);
    }

    mounted(): void {
        axios.get('/.resources/gc/webresources/js/data.json')
            .then(res => {
                const data: TailingsData = res.data;
                this.region = this.getQueryVariable('r');
                this.data = data.data.filter(row => !this.region || row.region === this.region);
            })
            .catch(err => {
                console.log('Couldn\'t fetch table data');
                console.log(err);
            });
    }

    getRotation(property: string): Record<string, boolean> {
        return {
            'rotate-90': this.sortProperty === property && !this.ascending,
            '-rotate-90': (this.sortProperty === property && this.ascending) || this.sortProperty !== property
        };
    }

    getTextColor(property: string): Record<string, boolean> {
        return {
            'text-primary-500': this.sortProperty === property,
            'text-black-400': this.sortProperty !== property
        };
    }

    /**
     * Retrieves the specified query parameter's value from the url string.
     *
     * @param variable The desired parameter name
     */
    getQueryVariable(variable: string): string {
        const params = new URLSearchParams(window.location.search);
        return params.has(variable) ? params.get(variable) : '';
    }

    toggle(i: number): void {
        Vue.set(this.detailModel, i, !this.detailModel[i]);
    }

    sortBy(property: string) {
        if (property === this.sortProperty) {
            this.ascending = !this.ascending;
        } else {
            this.sortProperty = property;
            this.ascending = true;
        }
    }

    @Watch('searchTerm')
    watchSearchTerm(): void {
        this.detailModel = [];
        this.currentPage = 1;
    }

    @Watch('currentPage')
    watchCurrentPage(): void {
        this.detailModel = [];
    }
}
