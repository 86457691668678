





































import Dropdown from '../base/Dropdown.vue';
import { Component, Prop } from 'vue-property-decorator';
import { Office } from './WorldMap.vue';
import Vue from 'vue';
import SocioEconomicMapDetailSlider from '../socioEconomicMap/SocioEconomicMapDetailSlider.vue';

@Component({
    components: {
        Dropdown,
        SocioEconomicMapDetailSlider
    }
})
export default class MapOffice extends Vue {
    /***********************
     * Vue props
     ***********************/

    @Prop({ default: () => [] }) offices: Array<Office>;
    @Prop({ default: null }) selectedOffice: Office;
    @Prop({ default: '' }) labelSelectOffice: string;
    @Prop({ default: '' }) labelPhone: string;
    @Prop({ default: '' }) labelMobile: string;
    @Prop({ default: '' }) labelFax: string;

    created(): void {
        this.setOffice(this.getDefaultOffice());
    }

    /***********************
     * Vue data
     ***********************/

    openState = false;

    /***********************
     * Vue methods
     ***********************/

    getDefaultOffice(): Office {
        // will find the first office with defaultOffice = true
        // if no office matches that condition, return the first office
        const maybeOffice = this.offices.find(o => o.defaultOffice);
        return maybeOffice || this.offices[0];
    }

    setOffice(office: Office): void {
        this.$emit('office-select', office);
    }
}
