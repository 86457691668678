





















import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive, Prop } from 'vue-property-decorator';
import { GolfEventLabels, Participant } from './GolfEventTypes';
import ToggleButtons from '../base/ToggleButtons.vue';
import GolfEventPersonalDataStep from './GolfEventPersonalDataStep.vue';
import GolfEventParticipationStep from './GolfEventParticipationStep.vue';
import GolfEventTravelDataStep from './GolfEventTravelDataStep.vue';
import GolfEventSummary from './GolfEventSummary.vue';

@Component({
    components: {
        GolfEventSummary,
        GolfEventTravelDataStep,
        GolfEventParticipationStep,
        GolfEventPersonalDataStep,
        ToggleButtons
    }
})
export default class GolfEventRegistrationDialog extends Vue {
    @Prop({ required: true }) labels: GolfEventLabels;

    yesNoModel: string[] = [];

    @InjectReactive()
    currentStep: number;

    @InjectReactive()
    participant: Participant;

    onParticipate(): void {
        this.$emit('participate');
    }

    onCancel(): void {
        this.$emit('cancel');
    }
}
