import gsap from 'gsap';

const scrollTo = e => {
    try {
        const href = e.currentTarget.getAttribute('href');
        const target = document.querySelector(href);
        e.preventDefault();

        if (target) {
            window.scrollTo({
                top: target.offsetTop,
                behavior: 'smooth'
            });
        }
    } catch (e) {
    }
};

export default {
    bind(element) {
        const tl = gsap.timeline({ paused: false, repeat: -1 });

        const circles: HTMLCollection = element.querySelectorAll('circle');
        const arrow = element.querySelector('path');
        let timingOffset = 0;

        Array.from(circles).forEach(circle => {
            tl.to(circle, { opacity: 0, duration: 0.2 }, timingOffset);
            tl.to(circle, { opacity: 1, duration: 0.2 }, timingOffset + 0.2);
            timingOffset += 0.2;
        });

        tl.to(arrow, { y: 3, duration: 0.5 }, 0.0);
        tl.to(arrow, { y: 0, duration: 0.5 }, 0.5);

        element.addEventListener('click', scrollTo);

        return () => {
            element.removeEventListener('click', scrollTo);
        };
    }
};
