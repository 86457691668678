
























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Icon from '../base/Icon.vue';

export interface Color {
    pms?: string;
    cmyk?: string;
    rgb?: string;
    ral?: string;
    hex: string;
}

@Component({
    components: {
        Icon
    }
})
export default class BrandColor extends Vue {
    @Prop({ required: true }) title: string;
    @Prop({ required: true }) color: Color;
    @Prop({ required: true }) labels: Color;
    @Prop({ required: true }) copyLabel: string;
    @Prop({ required: true }) copiedLabel: string;

    copied = '';

    get style(): Record<string, string> {
        return {
            backgroundColor: this.color.hex
        };
    }

    showValue(key: string, value: string): boolean {
        return key !== 'hex' || (value || '').toLowerCase() !== '#ffffff';
    }

    copyToClipboard(key: string, value: string): void {
        navigator.clipboard.writeText(value);
        this.copied = key;
        setTimeout(() => { this.copied = ''; }, 1500);
    }
}
