












































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DataPoint, MonitoringLabels, Scale } from './MonitoringGraph.vue';

@Component
export default class TableView extends Vue {
    @Prop({ required: true }) labels: MonitoringLabels;
    @Prop({ required: true }) scale: Scale[];
    @Prop({ required: true }) data: DataPoint[];
    @Prop({ default: false }) hideTrafficLight: boolean;

    get _fiveMinTime(): string {
        if (this.data.length > 0) {
            return this.formatDate(this.data[0].value5Time);
        }
        return '-';
    }

    get _hourlyTime(): string {
        if (this.data.length > 0) {
            return this.formatDate(this.data[0].value60Time);
        }
        return '-';
    }

    formatDate(dateStr: string): string {
        return dateStr.replace(/.*\s(\d{1,2}:\d{2}).*/, (a, b) => b);
    }

    /**
     * Determine color according to value.
     * For tailwind preservation: bg-graph-blue bg-graph-green bg-graph-yellow bg-graph-orange bg-graph-red
     * @param value A numeric value
     */
    getColor(value: number): string {
        return 'bg-graph-' + this.scale.find(s => value <= s.max || !s.max).color;
    }
}
