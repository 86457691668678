





















import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import GcSwiper from '../base/GcSwiper.vue';
import GcSwiperSlide from '../base/GcSwiperSlide.vue';

@Component({
    components: {
        GcSwiper,
        GcSwiperSlide
    }
})
export default class SocioEconomicMapDetailSlider extends Vue {
    /***********************
     * Vue Props
     ***********************/
    @Prop({ default: () => ([]) }) slides: any;
    @Prop({ default: 'w-2/3' }) slideClasses: string;
    @Prop() containerClasses: string;
    // tailwind comment to prevent purge: after:border-white bg-white
    @Prop({ default: 'white' }) pagerColor: string;

    /** Hack: We want rerender when new slides arrive **/
    @Watch('slides')
    watchSlides() {
        this.render = false;
        this.$nextTick(() => {
            this.render = true;
        });
    }

    render = false;

    mounted() {
        this.render = true;
    }
}
