































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Icon from '../base/Icon.vue';

export interface BreadcrumbItem {
    url: string;
    label: string;
}

@Component({
    components: {
        Icon
    }
})
export default class Breadcrumbs extends Vue {
    @Prop({ required: true }) items: BreadcrumbItem[];
    @Prop({ required: true }) currentPageLabel: string;
    @Prop({ default: 75 }) charThreshold: number;
    @Prop({ default: 3 }) itemThreshold: number;

    opened = false;

    isItemVisible(index: number): boolean {
        return !this.showDots || index === 0 || index === (this.items.length - 1);
    }

    get showDots(): boolean {
        return !this.opened && (this.length > this.charThreshold || this.items.length > this.itemThreshold);
    }

    get length(): number {
        return this.items.reduce((prev, current) => prev + Math.min(current.label.length, 25), 0) + this.currentPageLabel.length;
    }
}
