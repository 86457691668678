import breakpoints from '../../plugins/breakpoints';

export default class SocioEconomicMapScrollEffect {
    private readonly map: HTMLElement;
    private readonly detail: HTMLElement;

    private limitTop: number;
    private limitBottom: number;

    private readonly onBefore: Function;
    private readonly onEnter: Function;
    private readonly onAfter: Function;
    private readonly onLeave: Function;

    // the default offset to trigger callbacks, should e.g. equal the header height
    private readonly headerOffset = 56;
    // offset to terminate the animation;
    private readonly leaveOffset = 40;

    constructor(map: HTMLElement, detail: HTMLElement, onBefore: Function, onEnter: Function, onAfter: Function, onLeave: Function) {
        this.map = map;
        this.detail = detail;
        this.onBefore = onBefore;
        this.onEnter = onEnter;
        this.onAfter = onAfter;
        this.onLeave = onLeave;
        this.limitTop = this.calcTopOffset(this.map);
        this.limitBottom = this.calcBottomOffset(this.map);
    }

    public handleScrollEffect() {
        window.addEventListener('scroll', this.initialScrollListener);
    }

    /**
     * The initial handler: listening for map hitting top of screen and then delegating scroll to detail container
     */
    private initialScrollListener = () => {
        if (!breakpoints.mobile) {
            this.onBefore();
            return;
        }
        if (window.scrollY + this.headerOffset >= this.limitTop) {
            if (window.scrollY + this.headerOffset >= this.limitBottom) {
                if (window.scrollY + this.headerOffset >= this.calcBottomOffset(this.detail) - this.leaveOffset) {
                    this.onLeave();
                    return;
                }
                this.onAfter();
                return;
            }
            this.onEnter();
            return;
        }
        this.onBefore();
    };

    private calcTopOffset(element: HTMLElement) {
        return window.pageYOffset + element.getBoundingClientRect().top;
    }

    private calcBottomOffset(element: HTMLElement) {
        return window.pageYOffset + element.getBoundingClientRect().bottom;
    }
}
