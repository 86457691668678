






































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import Icon from '../base/Icon.vue';
import SocioEconomicMapUtils, { Operation, SubOperation, SubRegion } from './SocioEconomicMapUtils';
import ArrowButton from '../base/ArrowButton.vue';
import SocioEconomicMapDetailDatasets from './SocioEconomicMapDetailDatasets.vue';
import SocioEconomicMapDetailSlider from './SocioEconomicMapDetailSlider.vue';
import SocioEconomicMapDetailDescription from './SocioEconomicMapDetailDescription.vue';

@Component({
    components: {
        Icon,
        ArrowButton,
        SocioEconomicMapDetailDatasets,
        SocioEconomicMapDetailSlider,
        SocioEconomicMapDetailDescription
    }
})
export default class SocioEconomicMapDetailContent extends Vue {
    /***********************
     * Vue Props
     ***********************/
    @Prop({ default: null }) entity: SubRegion|SubOperation;
    @Prop({ default: '' }) scope: string;
    @Prop({ default: () => ([]) }) operations: Array<Operation>

    @Prop({ default: '' }) downloadFactsheetLabel: string;
    @Prop({ default: '' }) operationsLabel: string;
    @Prop({ default: '' }) showMoreLabel: string;
    @Prop({ default: '' }) showLessLabel: string;

    /***********************
     * Vue Methods
     ***********************/

    private setOperation(operation: SubOperation) {
        this.$emit('setOperation', operation);
    }

    get displayOperations(): Array<SubOperation> {
        return this.filterOperations.filter(operation => !operation.hideInSidebar);
    }

    get filterOperations(): Array<SubOperation> {
        return this.scope === 'Region' && this.entity !== null && this.operations.length > 0
            ? SocioEconomicMapUtils.getSubOperationsFromRegion(this.entity as SubRegion, this.operations)
            : [];
    }
}
