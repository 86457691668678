











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import InteractiveImageModal from './InteractiveImageModal.vue';
import HotspotMap from '@lemonize/lz-hotspots-template/src/models/HotspotMap';

export interface InteractiveModal {
    id: string;
    text: string;
    image: string;
    link: Link;
}

export interface Link {
    url: string;
    target: string;
    label: string;
}

@Component({
    components: {
        InteractiveImageModal
    }
})
export default class InteractiveImage extends Vue {
    @Prop() image: string;
    @Prop() jsonMap: HotspotMap;
    @Prop({ default: () => [] }) modals: InteractiveModal[];

    opened = '';

    created(): void {
        // listen to custom event fired by hotspot-template
        window.addEventListener('__hsCustomEvent', (event: any) => {
            const id = event.detail.payload;
            if (id) {
                this.openModal(id);
            }
        });
    }

    protected openModal(id: string): void {
        // check if the id corresponds to one of the provided hotspots
        if (this.jsonMap.hotspots.findIndex(h => h.actions.click.payload === id) > -1) {
            this.opened = id;
        }
    }
}
