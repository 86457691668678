/**
 * dropdown values from table (calculated for shortness).
 * need to come from backend
 */
// eslint-disable-next-line no-unused-vars
import { Commodity } from './calculations';

export const feedGrades = new Array(6).fill(0).map((v, i) => 0.2 + i * 0.2)
    .concat(
        new Array(10).fill(0).map((v, i) => 1.5 + i * 0.5),
        new Array(5).fill(0).map((v, i) => 12 + i * 2),
        new Array(14).fill(0).map((v, i) => 25 + i * 5)
    )
    .map(x => Math.floor(x * 100) / 100);

export const tphValues = new Array(40).fill(0).map((v, i) => 5 + i * 5)
    .concat(
        new Array(4).fill(0).map((v, i) => 225 + i * 25),
        [350, 400, 450],
        new Array(9).fill(0).map((v, i) => 500 + i * 100),
        new Array(2).fill(0).map((v, i) => 1500 + i * 200),
        [2000, 2300, 2600, 3000, 4000, 5000, 6000]
    );
export const tssgValues = [2, 2.3, 2.5, 2.7, 3, 3.3, 3.5, 3.7, 4, 4.3];
export const p80Values = [10, 15, 21, 32, 44, 70, 105, 150];
export const circuitSolidsValues = new Array(8).fill(0).map((v, i) => 5 + i * 5);
export const cleanerCircuitSolidsValues = new Array(21).fill(0).map((v, i) => 5 + i);
export const recoveryValues = new Array(12).fill(0).map((v, i) => 30 + i * 5)
    .concat([87, 90, 93, 95, 97, 99]);
export const inputFeedGrades = feedGrades.slice(0, -11); // ends at 35
export const conGradeValues = feedGrades.slice(7); // start at 2
export const rougherScavengerConGradeValues = feedGrades.slice(3); // starts at 0.8

export const isaMillP80Values = [10, 15, 21, 32, 44, 70, 105];

export const IsaRegrindCommodities: Commodity[] = [
    { value: 'copper-molybdenum', label: 'Copper/Molybdenum' },
    { value: 'iron', label: 'Iron' },
    { value: 'lead', label: 'Lead' },
    { value: 'zinc', label: 'Zinc' },
    { value: 'molybdenum', label: 'Molybdenum' },
    { value: 'nickel', label: 'Nickel' },
    { value: 'tin', label: 'Tin' },
    { value: 'pgm-polymetallics', label: 'PGM & Polymetallics' },
    { value: 'copper', label: 'Copper' },
    { value: 'gold', label: 'Gold' }
];
