









import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class SocioEconomicMapDetailDatasets extends Vue {
    /***********************
     * Vue Props
     ***********************/
    @Prop({ required: true, default: () => ([]) }) datasets: any
}
