










import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import Overlay from '../teaser/Overlay.vue';

@Component({
    components: {
        Overlay
    }
})
export default class OverlayLink extends Vue {
    @Prop() url: string;
    @Prop() classes: string;
    @Prop() ariaLabel: string;

    targetUrl = '';

    click(event) {
        event.preventDefault();
        this.targetUrl = this.url;
    }
}
