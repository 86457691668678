const Enums = {
    API: {
        LOCATION: '/.rest/api/v1/',
        LOCATION_V2: '/.rest/api/v2/',
        BLOGS: 'blogs/?',
        NEWS: 'news/?',
        SEARCH_EVENT: 'searchEvent/?',
        SEARCH: 'search/?',
        SEARCH_SUGGESTIONS: 'search/suggest?',
        SEARCH_MEDIA: 'searchMedia/?',
        SOCIAL: 'social/?',
        SIMPLE_MAIL: 'form/simple/send-email',
        COMPLEX_MAIL: 'form/complex/send-email',
        SO2: '/.rest/api/so2',
        BLOGS2: 'blog2s?',
        DOCUMENTS: 'documents',
        CAREERS: 'careers/?',
        ARTICLES: 'articles?',
        COMMENTS: '/.rest/api/comments',
        GOLF_EVENT: '/.rest/api/golfevent'
    },
    STORAGE_KEY: {
        LANGUAGE: 'glencore-language',
        CONTEXT_PATH: 'glencore-context-path'
    },
    POSITION: {
        TOP: 'Top',
        RIGHT: 'Right',
        BOTTOM: 'Bottom',
        LEFT: 'Left'
    }
};

export default Enums;
