import axios from 'axios';
import truncate from 'lodash/truncate';
import chunk from 'lodash/chunk';

export default class Utils {
    /**
     * Determines if currently running as prototype or in Magnolia.
     *
     * @return True when running as prototype, false when running in Magnolia
     */
    static isPrototype() {
        const bodyElements = document.getElementsByTagName('body');
        let prototype = false;

        [].forEach.call(bodyElements, _bodyElement => {
            if (_bodyElement.classList.contains('prototype')) {
                prototype = true;
            }
        });

        return prototype;
    }

    static addClass(nodes, className) {
        [].forEach.call(nodes, n => {
            n.classList.add(className);
        });
    }

    static removeClass(nodes, className) {
        [].forEach.call(nodes, n => {
            n.classList.remove(className);
        });
    }

    static getArr(arr = []) {
        return ({ step, start, stop }) => {
            let result = [...arr];
            for (let i = start; i < stop; i += step) {
                result = [...result, i];
            }
            return result;
        };
    }

    static extractUrlParams(disableDecode = false) {
        const queryString = disableDecode
            ? window.location.search.substring(1).split('&')
            : decodeURIComponent(window.location.search.substring(1)).split('&');
        return queryString.reduce((parametersObject, keyValue) => {
            const [key, val] = keyValue.split('=');
            parametersObject[key] = val; //eslint-disable-line
            return parametersObject;
        }, {});
    }

    static getUrlParam(name) {
        return this.extractUrlParams()[name];
    }

    static setUrlParam(name, value) {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set(name, value);
        history.replaceState(null, null, `?${queryParams.toString()}`);
    }

    static redirect(url, search = '') {
        setTimeout(() => {
            window.location.href = url;
            window.location.search = search;
        }, 0);
    }

    static getLocalStorage(key) {
        return window.localStorage.getItem(key);
    }

    static setLocalStorage(key, value) {
        window.localStorage.setItem(key, value);
    }

    static findParentSelector(nodeChild, selectorName) {
        let nodeParent = nodeChild.parentElement;
        while (nodeParent && !(nodeParent.tagName.toLowerCase() === selectorName) && !nodeParent.classList.contains(selectorName)) {
            nodeParent = nodeParent.parentElement;
        }
        return nodeParent;
    }

    static getAPI(url) {
        return axios.get(url)
            .then(res => res.data);
    }

    static splitToChunk(arr, itemAmount) {
        return chunk(arr, itemAmount);
    }

    static shortenText(value, leng) {
        return truncate(value, { length: leng });
    }

    static postAPI(url, data) {
        return axios.post(url, data);
    }

    static addSiteToApi(location, name, site) {
        return `${location}${name}site=${site}&`;
    }

    static transformArrToObj(arr) {
        return arr.reduce((result, item) => {
            result[item.key] = item.value;
            return result;
        }, {});
    }

    static getPositionElement(el, position = 'Top') {
        let number = el[`offset${position}`];
        let els = el;
        while (els.offsetParent) {
            els = els.offsetParent;
            number += els[`offset${position}`];
        }
        return number;
    }

    static getHashActiveNavigation(hash) {
        const reg = /([a-zA-Z0-9%]*)_([a-zA-Z0-9]*)/g;
        const matchArr = reg.exec(hash);
        const obj = () => {
            const newArr = matchArr ? matchArr.splice(1) : null;
            return newArr && newArr.length >= 2
                ? { key: newArr[0], index: Number.parseInt(newArr[1]) } : null;
        };
        return obj();
    }

    static addBrightCoveJs(scriptUrl, callback) {
        let isExisting = false;
        const scripts = document.querySelectorAll('script');
        for (let idx = 0; idx < scripts.length; idx += 1) {
            if (scripts[idx].src.indexOf(scriptUrl) > -1) {
                isExisting = true;
            }
        }

        if (!isExisting) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = scriptUrl;
            document.getElementsByTagName('head')[0].appendChild(script);

            // the callback call autoplay
            script.onload = callback;
        }
    }

    static transformParamValue(param) {
        // check param whether is value All
        if (!param) return {};
        let paramObject;
        switch (param.type) {
            case 'blog':
                paramObject = {
                    author: param.author === param.default ? '' : encodeURIComponent(param.author),
                    tag: param.tag === param.default ? '' : encodeURIComponent(param.tag),
                    year: param.year === param.default ? '' : encodeURIComponent(param.year)
                };
                break;
            case 'latestBlog':
                paramObject = {
                    location: param.location === param.default ? '' : encodeURIComponent(param.location),
                    topic: param.topic === param.default ? '' : encodeURIComponent(param.topic),
                    businessArea: param.businessArea === param.default ? '' : encodeURIComponent(param.businessArea)
                };
                break;
            default:
                paramObject = null;
        }
        return paramObject;
    }

    static checkLat(lat) {
        return lat > -90 && lat < 90;
    }

    static checkLng(lng) {
        return lng > -180 && lng < 180;
    }

    static checkLatLng({ lat, lng }) {
        return Utils.checkLat(lat) && Utils.checkLng(lng);
    }

    static unionBy = (iteratee, ...arrs) => {
        let unionArr = [];
        for (const arr of arrs) {
            const mapped = arr.map(iteratee);
            const unionMapped = unionArr.map(iteratee);
            const uniques = mapped.filter(a => !unionMapped.includes(a));
            unionArr = [...unionArr, ...uniques];
        }
        return unionArr;
    }

    static observeIntersection(observable: any, callback: () => void, options: any = {}) {
        const observer: IntersectionObserver = new IntersectionObserver((entries) => {
            if (entries.some(entry => entry.intersectionRatio > 0)) callback();
        }, options);
        observer.observe(observable);
    }

    static toggleScrollLock(selector = 'body', suffix: string = null): void {
        document.querySelector(selector).classList.toggle(suffix ? `no-scroll--${suffix}` : 'no-scroll');
    }

    static addHexColor(hexA: string, hexB: string) {
        let hexStr = (parseInt(hexA, 16) + parseInt(hexB, 16)).toString(16);
        while (hexStr.length < 6) { hexStr = '0' + hexStr; } // Zero pad.
        return hexStr;
    }

    static subtractHexColor(hexA: string, hexB: string) {
        let hexStr = (parseInt(hexA, 16) - parseInt(hexB, 16)).toString(16);
        while (hexStr.length < 6) { hexStr = '0' + hexStr; } // Zero pad.
        return hexStr;
    }

    static cssColor(color: string): string {
        return color ? (color[0] === '#' ? color : '#' + color) : '';
    }

    static getClickTouchDOMEvent(event: any): any {
        if (event.type === 'touchstart' || event.type === 'touchmove' || event.type === 'touchend') {
            return event.touches[0];
        } else {
            return event;
        }
    }

    static isScrollableOrClamped(element: HTMLElement): boolean {
        return element ? element.scrollHeight > (element.clientHeight + 1) : false;
    }

    static isFullyScrolled(element: HTMLElement): boolean {
        return element.scrollTop + element.clientHeight >= element.scrollHeight;
    }

    static lockBodyScroll(breakpoint: string = null): void {
        const deactivatedBreakpointPrefix = breakpoint || '';
        (window as any)._lastBodyScrollTopPos = window.scrollY;
        document.querySelector('body').style.top = `${(window as any)._lastBodyScrollTopPos}px`;
        document.querySelector('html').classList.add('overflow-hidden', `${deactivatedBreakpointPrefix}:overflow-visible`);
        document.querySelector('body').classList.add('fixed', `${deactivatedBreakpointPrefix}:static`);
        document.querySelector('body').classList.add('h-full', `${deactivatedBreakpointPrefix}:h-auto`);
    }

    static unlockBodyScroll(breakpoint: string = null): void {
        const deactivatedBreakpointPrefix = breakpoint || '';
        document.querySelector('body').style.top = '';
        document.querySelector('html').classList.remove('overflow-hidden', `${deactivatedBreakpointPrefix}:overflow-visible`);
        document.querySelector('body').classList.remove('fixed', `${deactivatedBreakpointPrefix}:static`);
        document.querySelector('body').classList.remove('h-full', `${deactivatedBreakpointPrefix}:h-auto`);
        window.scrollTo(0, (window as any)._lastBodyScrollTopPos || 0);
    }

    static formatString(value: string, ...args: any[]): string {
        let a = value;
        for (const k in args) {
            a = a.replace(new RegExp('\\{' + k + '\\}', 'g'), args[k]);
        }
        return a;
    }

    /**
     * Appends a script element to a specified parent element with a given url and given attributes.
     * The promise returned resolves as soon as the script has been loaded.
     *
     * @param url the url of the script resource
     * @param attributes a record of attributes (key-value) to set on the script element
     * @param parent the parent to append the element to
     * @returns a promise resolving to the respective script element when it has been loaded
     */
    public static addScript(url: string, attributes: Record<string, string> = {}, parent: HTMLElement = document.body): Promise<HTMLScriptElement> {
        return new Promise((resolve, reject) => {
            try {
                const list: HTMLCollectionOf<HTMLScriptElement> = parent.getElementsByTagName('script');
                for (let i = 0; i < list.length; i++) {
                    const element: HTMLScriptElement = list[i];
                    if (url === element.src) {
                        return; // script tag with same URL already exists, prevent adding duplicates
                    }
                }

                // create and append script tag
                const script: HTMLScriptElement = document.createElement('script');
                script.setAttribute('type', 'text/javascript');
                script.setAttribute('src', url);
                Object.entries(attributes).forEach(([key, value]) => {
                    script.setAttribute(key, value);
                });
                script.onload = () => resolve(script);
                parent.appendChild(script);
            } catch (err) {
                console.error('error adding script', err);
                reject(err);
            }
        });
    }
}
