











































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import DocumentsTable from './DocumentsTable.vue';
import ContentGroup from '../contentGroup/ContentGroup.vue';
import ContentGroupItem from '../contentGroup/ContentGroupItem.vue';
import Icon from '../base/Icon.vue';
import SearchableGroupDropdown from '../base/SearchableGroupDropdown.vue';

export interface FilterLabels {
    filterBy: string;
    year: string;
    tag: string;
    reportingYear: string;
    fullSearch: string;
    search: string;
    download: string;
    selected: string;
    loadMore: string;
    defaultFilter: string;
}

export interface Filters {
    tag: boolean;
    year: boolean;
    reportingYear: boolean;
}

@Component({
    components: {
        DocumentsTable,
        ContentGroup,
        ContentGroupItem,
        Icon,
        SearchableGroupDropdown
    }
})
export default class DocumentListView extends Vue {
    /***********************
     * Vue Props
     ***********************/
    @Prop({ required: true }) data: any[];
    @Prop({ default: {} }) tagCategories: Record<string, string[]>;
    @Prop({ default: 0 }) batchSize: number;
    @Prop({ required: true }) columns: { key: string; label: string }[];
    @Prop({ required: true }) archiveText: string;
    @Prop({ required: true }) title: string;
    @Prop({ required: true }) labelArchive: string;
    @Prop({ required: true }) filterLabels: FilterLabels;
    @Prop({ required: true }) chevronIcon: string;
    @Prop({ default: 'tabs' }) grouping: string;
    @Prop({ default: false, type: Boolean }) expanded: boolean;
    @Prop({ default: true, type: Boolean }) showSingleCategories: boolean;
    @Prop({ default: true, type: Boolean }) showSearchBar: boolean;
    @Prop({ default: true, type: Boolean }) showTableHeader: boolean;
    @Prop({ default: true, type: Boolean }) showDocumentSize: boolean;
    @Prop({ default: true, type: Boolean }) showDocumentIcon: boolean;
    @Prop({ default: true, type: Boolean }) showDateAfterText: boolean;
    @Prop({ default: {} }) filters: Filters;
    @Prop({ default: false, type: Boolean }) sortingEnabled: boolean;
    @Prop({ default: false, type: Boolean }) inContentGroup: boolean;
    @Prop({ default: 6 }) maxColumns: number;
    @Prop() description: string;

    /***********************
     * Vue data
     ***********************/
    filterVisible = false;
    filterYear = '';
    filterReportingYear = '';
    filterTag = '';
    filterTags: Record<string, string> = {};
    keyword = '';

    /***********************
     * Vue methods
     ***********************/
    mounted(): void {
        // for single dropdown mode:
        // add a default option to all categories & init model
        if (this.showSingleCategories) {
            Object.keys(this.tagCategories).forEach(tc => {
                this.tagCategories[tc].unshift(this.filterLabels.defaultFilter);
                Vue.set(this.filterTags, tc, '');
            });
        }
    }

    mapData(data, years): Record<string, unknown>[] {
        return years
            .map(year => (
                {
                    year,
                    items: data.filter(item => year === new Date(item.date).getFullYear())
                }
            ));
    }

    /***********************
     * Vue computed
     ***********************/
    get groups(): Record<string, any>[] {
        if (!this.grouped) {
            return [{ year: -1, items: this.data }];
        }
        return this.mapData(this.data, this.years.slice(0, this.maxColumns));
    }

    get archive(): Record<string, any>[] {
        return this.mapData(this.data, this.years.slice(this.maxColumns));
    }

    get years(): number[] {
        return Object.keys(this.data.reduce((acc, item) => {
            acc[new Date(item.date).getFullYear()] = '';
            return acc;
        }, {})).map(x => parseInt(x)).sort((a, b) => b - a);
    }

    get reportingYears(): number[] {
        return Object.keys(this.data.reduce((acc, item) => {
            if (item.reportingYear) {
                acc[item.reportingYear] = '';
            }
            return acc;
        }, {})).map(x => parseInt(x)).sort((a, b) => b - a);
    }

    get showYearFilter(): boolean {
        return (this.showDateAfterText || this.columns.findIndex(col => col.key === 'date') >= 0) && this.filters.year;
    }

    get showTagFilter(): boolean {
        return this.filters.tag;
    }

    get showReportingYearFilter(): boolean {
        return this.filters.reportingYear;
    }

    get yearGroup(): Record<string, string[]> {
        const group = {};
        group[this.filterLabels.year] = [
            ...this.years.map(year => year + '')
        ];
        return group;
    }

    get reportingYearGroup(): Record<string, string[]> {
        const group = {};
        group[this.filterLabels.reportingYear] = [
            ...this.reportingYears.map(year => year + '')
        ];
        return group;
    }

    get tableOptions(): Record<string, any> {
        return {
            columns: this.columns,
            categories: this.tagCategories,
            showHeaders: this.showTableHeader,
            showSize: this.showDocumentSize,
            showIcon: this.showDocumentIcon,
            dateAfterText: this.showDateAfterText,
            batchSize: this.batchSize,
            lblDownload: this.filterLabels.download,
            lblSelected: this.filterLabels.selected,
            lblLoadMore: this.filterLabels.loadMore
        };
    }

    get isFiltered(): boolean {
        return Object.keys(this.filters).some(k => this.filters[k]);
    }

    get grouped(): boolean {
        return this.grouping === 'tabs' || this.grouping === 'accordions';
    }

    get filteredData(): Record<string, any>[] {
        if (!this.isFiltered) {
            return this.data;
        }
        let result = this.data;
        // filter after year of publishing
        if (this.filterYear) {
            const year = Number(this.filterYear);
            result = result.filter(item => new Date(item.date).getFullYear() === year);
        }
        // filter after reporting year (not always the same as year of publishing)
        if (this.filterReportingYear) {
            result = result.filter(item => item.reportingYear === this.filterReportingYear);
        }
        // default tag filtering, you can only select one tag in the grouped dropdown
        if (this.filterTag) {
            result = result.filter(item => (item.tags || []).indexOf(this.filterTag) > -1);
        }
        // basically same as filterTag but in single mode you can select multiple tags (one from each category)
        if (this.showSingleCategories && Object.keys(this.filterTags).length > 0) {
            result = result.filter(item =>
                // logical AND, so the item must have all filtered tags
                Object.keys(this.filterTags)
                    .filter(ft => !!this.filterTags[ft] && this.filterTags[ft] !== this.filterLabels.defaultFilter)
                    .every(ft => (item.tags || []).indexOf(this.filterTags[ft]) >= 0)
            );
        }
        // keyword filtering -> look up in document title (case-insensitive)
        if (this.keyword) {
            result = result.filter(item => (item.title || '').toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0);
        }
        return result;
    }
}
