



import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';

@Component
export default class StageTeaser extends Vue {
    @Prop() title: string;
    @Prop() subTitle: string;
    @Prop() description: string;
    // *** begin used in carousel ***
    @Prop() link: string;
    @Prop() target: string;
    @Prop() label: string;
    @Prop() ariaLabel: string;
    // *** end used in carousel ***
    @Prop() video: string
    @Prop({ required: true }) urls: Record<string, string>;
    @Prop({ default: 'sm' }) variationSm: string;
    @Prop({ default: 'md' }) variationMd: string;
    @Prop({ default: 'lg' }) variationLg: string;
    @Prop({ default: 'xl' }) variationXl: string;
    @Prop({ default: 'original' }) original: string;

    @Inject({
        default: () => null
    }) private readonly registerCarouselItem!: Function;

    get videoType() {
        return this.video ? this.video.substring(this.video.lastIndexOf('.') + 1) : 'mp4';
    }

    mounted() {
        if (this.registerCarouselItem) {
            this.registerCarouselItem(this);
        }
    }
}
