var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-12 xl:mb-20"},[_c('ul',{staticClass:"flex"},[_vm._l((_vm.steps),function(step,index){return [(step.type === 'normal')?_c('li',{key:index,staticClass:"relative flex flex-col items-center"},[_c('span',{staticClass:"w-3 h-3 md:w-4 md:h-4 rounded-full",class:{
                            'border-2 border-primary-500': _vm.currentStep === index,
                            'bg-primary-500': _vm.currentStep > index,
                            'bg-black-300': _vm.currentStep < index
                      }}),_c('span',{staticClass:"absolute top-6 hidden md:block text-para-xs whitespace-nowrap",class:{
                            'text-primary-500': _vm.currentStep >= index,
                            'text-black-300': _vm.currentStep < index,
                            'font-semibold': _vm.currentStep === index
                      }},[_vm._v(" "+_vm._s(step.title)+" ")])]):_vm._e(),(step.type === 'intermediate')?_c('li',{staticClass:"-mx-2.5"}):_vm._e(),(index < (_vm.steps.length - 1))?_c('li',{staticClass:"h-0.5 mx-2 my-1.25 md:my-1.5 rounded-sm",class:{
                    'bg-black-300': _vm.currentStep <= index,
                    'bg-primary-500': _vm.currentStep > index
                },style:(_vm.getStyles(index))}):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }