





















































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import Icon from '../base/Icon.vue';
import { Location, Operation, SubOperation, SubRegion } from './SocioEconomicMapUtils';
import ArrowButton from '../base/ArrowButton.vue';
import SocioEconomicMapDetailDatasets from './SocioEconomicMapDetailDatasets.vue';
import SocioEconomicMapDetailContent from './SocioEconomicMapDetailContent.vue';

@Component({
    components: {
        Icon,
        ArrowButton,
        SocioEconomicMapDetailDatasets,
        SocioEconomicMapDetailContent
    }
})
export default class SocioEconomicMapDetail extends Vue {
    /***********************
     * Vue Props
     ***********************/
    @Prop({ required: true, default: () => ([]) }) sectionData: any;
    @Prop({ default: '' }) sectionDisclaimer: string;
    @Prop({ default: null }) location: Location;
    @Prop({ default: null }) region: SubRegion;
    @Prop({ default: null }) operation: SubOperation;
    @Prop({ default: () => ([]) }) operations: Array<Operation>

    @Prop({ default: '' }) downloadFactsheetLabel: string;
    @Prop({ default: '' }) operationsLabel: string;
    @Prop({ default: '' }) showMoreLabel: string;
    @Prop({ default: '' }) showLessLabel: string;

    /***********************
     * Vue data
     ***********************/
    detailOpen = false;
    detailScrollable = false;
    detailOffset = '0px';
    showChevronUp = true;
    specialChevronStyles = {};

    /***********************
     * Vue Methods
     ***********************/
    private setOperation(operation: SubOperation) {
        this.$emit('setOperation', operation);
    }

    public toggleOverlayOffset(offset: number): void {
        if (offset < 0) return;
        this.detailOffset = offset.toString().concat('px');
    }

    public setChevronDown(show: boolean): void {
        this.showChevronUp = !show;
        if (show) {
            this.specialChevronStyles = {
                top: '56px',
                position: 'fixed',
                'z-index': '5000',
                margin: 0
            };
        } else {
            this.specialChevronStyles = { };
        }
    }
}
