
















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class PlayButton extends Vue {
    // preserve comment: h-10 w-10 md:h-20 md:w-20 xl:h-24 xl:w-24
    @Prop({ default: 10 }) mobileSize: number;
    @Prop({ default: 20 }) tabletSize: number;
    @Prop({ default: 24 }) desktopSize: number;
    @Prop({ default: true }) absolute: boolean;
    @Prop({ default: false }) fillDefault: boolean;
}
