import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Sortable extends Vue {
    @Prop({ required: true }) items: Record<string, any>[];
    @Prop({ default: false, type: Boolean }) sortingEnabled: boolean;

    ascending = true;
    sortProperty = '';

    get sorted(): Record<string, any>[] {
        if (this.sortingEnabled && this.sortProperty) {
            const sorted = this.items.sort((row1, row2) => {
                if (row1[this.sortProperty] && row2[this.sortProperty]) {
                    if (!isNaN(Number(row1[this.sortProperty])) && !isNaN(Number(row2[this.sortProperty]))) {
                        return Number(row1[this.sortProperty]) - Number(row2[this.sortProperty]);
                    }
                    return row1[this.sortProperty].localeCompare(row2[this.sortProperty]);
                }
                return 0;
            });
            if (!this.ascending) {
                sorted.reverse();
            }
            return sorted;
        }
        return this.items;
    }

    protected sortBy(property: string): void {
        if (!this.sortingEnabled) {
            return;
        }
        if (property === this.sortProperty) {
            this.ascending = !this.ascending;
        } else {
            this.sortProperty = property;
            this.ascending = true;
        }
    }

    protected getIconClasses(property: string): Record<string, boolean> {
        return {
            'rotate-90': this.sortProperty === property && !this.ascending,
            '-rotate-90': (this.sortProperty === property && this.ascending) || this.sortProperty !== property,
            'text-primary-500': this.sortProperty === property,
            'text-black-400': this.sortProperty !== property
        };
    }
}
