






import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { screens } from '../../plugins/breakpoints';

@Component
export default class FiguresSection extends Vue {
    @Prop({ default: true, type: Boolean }) hasTitles: boolean;

    mounted(): void {
        window.addEventListener('resize', this.resize);
        this.resize();
    }

    beforeDestroy(): void {
        window.removeEventListener('resize', this.resize);
    }

    resize(): void {
        if (this.hasTitles) {
            const titles = Array.prototype.slice.call(this.$el.querySelectorAll('h4')) as HTMLHeadingElement[];
            titles.forEach(title => {
                title.style.height = '';
            });
            if (window.innerWidth >= screens.md) {
                const height = titles.reduce((acc, curr) => Math.max(acc, curr.clientHeight), 0);
                titles.forEach(title => {
                    title.style.height = `${height}px`;
                });
            }
        }
    }
}
