







































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios from 'axios';
import Combobox from '../base/Combobox.vue';
import GcButton from '../base/GcButton.vue';
import UserProperties from './UserProperties.vue';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';

export interface User {
    id: string;
    title: string;
    email: string;
    contact: string;
    roles: string[];
    properties: Record<string, string>;
}

export interface Role {
    id: string;
    title: string;
}

export interface ApprovalLabels {
    placeholder: string;
    tabNewUsers: string;
    tabUserManagement: string;
    tabReapprovals: string;
    login: string;
    email: string;
    save: string;
    targetRoles: string;
    approve: string;
    reject: string;
    delete: string;
    deletion: string;
    approval: string;
    rejection: string;
    approveError: string;
    rejectError: string;
    updateError: string;
    searchPlaceholder: string;
    selectPlaceholder: string;
}

@Component({
    components: {
        Combobox,
        GcButton,
        UserProperties,
        CollapseTransition
    }
})
export default class UserManagement extends Vue {
    @Prop({ required: true }) users: User[];
    @Prop({ required: true }) enabledUsers: User[];
    @Prop({ required: true }) usersToBeReapproved: User[];
    @Prop({ required: true }) roles: Role[];
    @Prop({ required: true }) labels: ApprovalLabels;
    @Prop({ default: false, type: Boolean }) inContentGroup: boolean;
    @Prop({ default: false }) hideReApprovalTab: boolean;
    @Prop({ default: {} }) properties: Record<string, string>;
    @Prop({ default: {} }) preselectRoles: Record<string, string>;

    keyword = '';
    active = true;
    selectedRoles: Record<string, string[]> = {};
    processedUsers: string[] = [];
    error = '';
    activeUser1 = '';
    activeUser2 = '';

    mounted(): void {
        const roleList = this.roles.map(r => r.title);

        this.enabledUsers.forEach(u => {
            Vue.set(this.selectedRoles, u.id, u.roles.filter(r => roleList.includes(r)));
        });

        this.users.forEach(u => {
            Vue.set(this.selectedRoles, u.id, this.getPreselectedRoles(u).filter(r => roleList.includes(r)));
        });
    }

    get rolesItems(): Record<string, string> {
        const items = {};
        this.roles.forEach(r => {
            items[r.title] = r.title;
        });
        return items;
    }

    get filteredUsers(): User[] {
        return this.filterUsers(this.users);
    }

    get filteredEnabledUsers(): User[] {
        return this.filterUsers(this.enabledUsers);
    }

    get filteredUsersToBeReapproved(): User[] {
        return this.filterUsers(this.usersToBeReapproved);
    }

    filterUsers(users: User[]): User[] {
        if (!this.keyword) return users;
        return users.filter(user => {
            // check title, email and any custom properties
            return user.title.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0 ||
                user.email.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0 ||
                Object.keys(user.properties).some(key => user.properties[key].toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0);
        });
    }

    isProcessed(id: string): boolean {
        return this.processedUsers.indexOf(id) >= 0;
    }

    getApproveUrl(id: string) {
        return `${Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH)}/.rest/pur/accept/${id}`;
    }

    getRejectUrl(id: string) {
        return `${Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH)}/.rest/pur/reject/${id}`;
    }

    getReapproveUrl(id: string) {
        return `${Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH)}/.rest/pur/reapprove/${id}`;
    }

    getRejectReapproveUrl(id: string) {
        return `${Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH)}/.rest/pur/reject-reapproval/${id}`;
    }

    getDeleteUrl(id: string) {
        return `${Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH)}/.rest/pur/delete/${id}`;
    }

    getUpdateUrl(id: string) {
        return `${Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH)}/.rest/pur/update/${id}`;
    }

    getPreselectedRoles(user: User): string[] {
        if (!this.preselectRoles.prop) {
            return [];
        }
        const rolesStr = user.properties[this.preselectRoles.prop];
        if (!rolesStr) {
            return [];
        }
        return rolesStr.split('__')
            .map(r => this.preselectRoles.prefix + r);
    }

    public async approve(id: string) {
        this.active = false;
        const data: Record<string, boolean> = {};
        if (this.selectedRoles[id]) {
            this.selectedRoles[id].forEach(r => {
                data[r] = true;
            });
        }
        await axios.post(this.getApproveUrl(id), data)
            .then(() => {
                this.processedUsers.push(id);
            })
            .catch(() => {
                this.error = 'approve-' + id;
            })
            .finally(() => {
                this.active = true;
            });
    }

    public async reject(id: string, url: string) {
        this.active = false;
        await axios.post(url)
            .then(() => {
                this.processedUsers.push(id);
            })
            .catch(() => {
                this.error = 'reject-' + id;
            })
            .finally(() => {
                this.active = true;
            });
    }

    public async reapprove(id: string) {
        this.active = false;
        await axios.post(this.getReapproveUrl(id))
            .then(() => {
                this.processedUsers.push(id);
            })
            .catch(() => {
                this.error = 'approve-' + id;
            })
            .finally(() => {
                this.active = true;
            });
    }

    public async update(id: string) {
        this.active = false;
        const data: Record<string, boolean> = {};
        // first set all roles to false
        this.roles.forEach(r => {
            data[r.title] = false;
        });
        // then set all selected roles to true
        if (this.selectedRoles[id]) {
            this.selectedRoles[id].forEach(r => {
                data[r] = true;
            });
        }
        await axios.post(this.getUpdateUrl(id), data)
            .then(() => {
                // nothing to do here
            })
            .catch(() => {
                this.error = 'update-' + id;
            })
            .finally(() => {
                this.active = true;
            });
    }
}
