




























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Icon from '../base/Icon.vue';

export interface CareerListLabels {
    position: string;
    localisation: string;
    deadline: string;
}

export interface Job {
    date: string;
    description: string;
    header: string;
    introduces: string[];
    jobHighlight1: string;
    jobHighlight2: string;
    jobHighlight3: string;
    jobId: string;
    localisation: string;
    position: string;
    title: string;
    url: string;
}

@Component({
    components: {
        Icon
    }
})
export default class CareerList extends Vue {
    @Prop({ default: () => [] }) jobs: Job[];
    @Prop({ default: () => [] }) addColumns: Record<string, string>[];
    @Prop({ default: {} }) labels: CareerListLabels;
    @Prop({ default: {} }) showLocalisation: boolean;
    @Prop() detailPage: string;
    @Prop() feedId: string;

    sortProperty = 'position';
    ascending = true;

    get sortedJobs(): Job[] {
        this.jobs.sort((j1, j2) => {
            if (this.sortProperty === 'date') {
                return new Date(j1[this.sortProperty]).getTime() - new Date(j2[this.sortProperty]).getTime();
            } else if (j1[this.sortProperty]) {
                return j1[this.sortProperty].localeCompare(j2[this.sortProperty]);
            }
            return 0;
        });
        if (!this.ascending) {
            this.jobs.reverse();
        }
        return this.jobs;
    }

    sortBy(property: string) {
        if (property === this.sortProperty) {
            this.ascending = !this.ascending;
        } else {
            this.sortProperty = property;
            this.ascending = true;
        }
    }

    openCareer(jobId: string) {
        if (this.detailPage && this.feedId && jobId) {
            window.open(`${this.detailPage}?jobId=${jobId}&feedId=${this.feedId}`, '_self');
        }
    }
}
