var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"name":_vm.title,"rules":_vm.rules,"mode":"eager","disabled":_vm.validationDisabled,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"text-para-s font-semibold"},[_c('span',[_vm._v(_vm._s(_vm.title))]),(_vm.required)?_c('span',[_vm._v(" *")]):_vm._e(),(_vm.suffix)?_c('span',{staticClass:"text-black-500 font-normal"},[_vm._v(" "+_vm._s(_vm.suffix))]):_vm._e()]),_c('div',{staticClass:"relative text-black-500"},[(_vm.type !== 'number')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model[_vm.name]),expression:"model[name]"}],staticClass:"w-full text-para-s border rounded focus:outline-none py-2.5 pr-4",class:{
                    'pl-4': _vm.type !== 'email',
                    'pl-10': _vm.type === 'email',
                    'border-black-200 focus:border-primary-500': errors.length === 0,
                    'border-error': errors.length > 0
               },attrs:{"type":"text","name":_vm.name},domProps:{"value":(_vm.model[_vm.name])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, _vm.name, $event.target.value)}}}):_c('number-input',{staticClass:"w-full text-para-s border rounded focus:outline-none py-2.5 pr-4",class:{
                      'pl-4': _vm.type !== 'email',
                           'pl-10': _vm.type === 'email',
                           'border-black-200 focus:border-primary-500': errors.length === 0,
                           'border-error': errors.length > 0
                      },attrs:{"name":_vm.name,"options":_vm.options},model:{value:(_vm.model[_vm.name]),callback:function ($$v) {_vm.$set(_vm.model, _vm.name, $$v)},expression:"model[name]"}}),(_vm.type === 'email')?_c('icon',{staticClass:"absolute stroke-current w-4 h-10 md:h-11 left-4 top-px",attrs:{"name":"mail"}}):_vm._e()],1),(errors.length > 0)?_c('span',{staticClass:"text-error text-para-xs mt-1 md:mt-2"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }