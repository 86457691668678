



import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { gsap } from 'gsap';

@Component
export default class Overlay extends Vue {
    @Prop() url: string;

    @Ref() embedded: HTMLDivElement;

    el = null;
    tl = null;

    @Watch('url')
    triggerModal() {
        this.url.length > 0 ? this.open() : this.close();
    }

    fetchContent(): Promise<Element> {
        return new Promise((resolve, reject) => {
            // actually fetch the modal content
            // eslint-disable-next-line no-undef
            fetch(this.url)
                .then(res => res.text())
                .then(html => {
                    // insert the fetched html into the dom

                    const mount = {
                        props: [],
                        template: html
                    };
                    const VueMounted = Vue.extend(mount);
                    const vm = new VueMounted().$mount();
                    this.embedded.appendChild(vm.$el);

                    this.$nextTick(() => {
                        // append modal to body to be on top of everything
                        document.body.append(this.$el);
                        // get the inserted html as element
                        resolve(this.$el);
                    });
                })
                .catch(reject);
        });
    }

    async open() {
        try {
            this.el = await this.fetchContent();
            this.tl = gsap.timeline({ paused: true });
            this.tl.fromTo('.js-content-modal-bg', { height: 0 }, { height: '100%', duration: 1.0 }, 0.0);
            this.tl.fromTo('.js-content-modal-content', { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 0.4 }, 0.3);
            this.tl.fromTo('.js-content-modal-copy', { opacity: 0, y: -20 }, { opacity: 1, y: 0, duration: 0.5 }, 0.6);
            // // get targets for open/close event
            const closeBtn = this.el.querySelector('.js-close-button');
            // and add the listeners
            closeBtn.addEventListener('click', () => this.$emit('close'));
            this.el.classList.remove('opacity-0', 'invisible');
            //
            window.requestAnimationFrame(() => {
                this.el.classList.add('opacity-100', 'inset-0', 'fixed', 'bg-white', 'z-50', 'overflow-y-scroll', 'overflow-x-hidden');
                document.body.classList.add('no-scroll');
            });
            this.tl.restart();
        } catch (err) {
            console.log(err);
        }
    }

    close() {
        if (!this.el) return;

        this.el.classList.remove('opacity-100', 'inset-0', 'fixed', 'bg-white', 'z-50', 'overflow-y-scroll', 'overflow-x-hidden');

        window.requestAnimationFrame(() => {
            // Timeout matches the animation time for it to fade.
            setTimeout(() => {
                this.embedded.innerHTML = '';
                this.el = null;
            }, 300);

            document.body.classList.remove('no-scroll');
        });
    }
}
