


































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, InjectReactive, Prop } from 'vue-property-decorator';
import { GEvent, GolfEventLabels, Participant } from './GolfEventTypes';
import { ValidationObserver } from 'vee-validate';
import ArrowButton from '../base/ArrowButton.vue';

@Component({
    components: {
        ValidationObserver,
        ArrowButton
    }
})
export default class GolfEventTravelDataStepStep extends Vue {
    @Prop({ required: true }) labels: GolfEventLabels;

    @InjectReactive()
    participant: Participant;

    @InjectReactive()
    event: GEvent;

    dataModel: string[] = [];
    showRequired = false;

    get meansOfTravelModel(): string[] {
        return [this.participant.meansOfTravel];
    }

    set meansOfTravelModel(newVal: string[]) {
        if (newVal.length) {
            Vue.set(this.participant, 'meansOfTravel', newVal[0]);
            if (newVal[0] !== 'UNKNOWN') {
                this.showRequired = false;
            }
        }
        this.updateProperty('meansOfTravel', this.participant.meansOfTravel);
    }

    get arrivalDateModel(): string {
        if (!this.participant.arrivalTime) return undefined;
        const date = new Date(this.participant.arrivalTime + 2 * 60 * 60 * 1000); // add two hours to get to Swiss timezone

        const year = date.getUTCFullYear();
        const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
        const day = ('0' + date.getUTCDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }

    set arrivalDateModel(newVal: string) {
        let timeStr = '00:00';
        if (this.participant.arrivalTime) {
            const old = new Date(this.participant.arrivalTime + 2 * 60 * 60 * 1000); // add two hours to get to Swiss timezone
            const hours = ('0' + old.getUTCHours()).slice(-2);
            const minutes = ('0' + old.getUTCMinutes()).slice(-2);
            timeStr = `${hours}:${minutes}`;
        }
        const date = new Date(`${newVal}T${timeStr}:00.000+02:00`); // in Swiss timezone
        if (!isNaN(date.getTime())) {
            Vue.set(this.participant, 'arrivalTime', date.getTime());
        }
    }

    get arrivalTimeModel(): string {
        if (!this.participant.arrivalTime) return undefined;
        const date = new Date(this.participant.arrivalTime + 2 * 60 * 60 * 1000); // add two hours to get to Swiss timezone

        const hours = ('0' + date.getUTCHours()).slice(-2);
        const minutes = ('0' + date.getUTCMinutes()).slice(-2);
        return `${hours}:${minutes}`;
    }

    set arrivalTimeModel(newVal: string) {
        const old = this.participant.arrivalTime
            ? new Date(this.participant.arrivalTime + 2 * 60 * 60 * 1000) // add two hours to get to Swiss timezone
            : new Date();
        const dateStr = old.toISOString().split('T')[0];
        const date = new Date(`${dateStr}T${newVal}:00.000+02:00`); // in Swiss timezone
        if (!isNaN(date.getTime())) {
            Vue.set(this.participant, 'arrivalTime', date.getTime());
        }
    }

    get departureDateModel(): string {
        if (!this.participant.departureTime) return undefined;
        const date = new Date(this.participant.departureTime + 2 * 60 * 60 * 1000); // add two hours to get to Swiss timezone

        const year = date.getUTCFullYear();
        const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
        const day = ('0' + date.getUTCDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }

    set departureDateModel(newVal: string) {
        let timeStr = '00:00';
        if (this.participant.departureTime) {
            const old = new Date(this.participant.departureTime + 2 * 60 * 60 * 1000); // add two hours to get to Swiss timezone
            const hours = ('0' + old.getUTCHours()).slice(-2);
            const minutes = ('0' + old.getUTCMinutes()).slice(-2);
            timeStr = `${hours}:${minutes}`;
        }
        const date = new Date(`${newVal}T${timeStr}:00.000+02:00`); // in Swiss timezone
        if (!isNaN(date.getTime())) {
            Vue.set(this.participant, 'departureTime', date.getTime());
        }
    }

    get departureTimeModel(): string {
        if (!this.participant.departureTime) return undefined;
        const date = new Date(this.participant.departureTime + 2 * 60 * 60 * 1000); // add two hours to get to Swiss timezone

        const hours = ('0' + date.getUTCHours()).slice(-2);
        const minutes = ('0' + date.getUTCMinutes()).slice(-2);
        return `${hours}:${minutes}`;
    }

    set departureTimeModel(newVal: string) {
        const old = this.participant.departureTime
            ? new Date(this.participant.departureTime + 2 * 60 * 60 * 1000) // add two hours to get to Swiss timezone
            : new Date();
        const dateStr = old.toISOString().split('T')[0];
        const date = new Date(`${dateStr}T${newVal}:00.000+02:00`); // in Swiss timezone
        if (!isNaN(date.getTime())) {
            Vue.set(this.participant, 'departureTime', date.getTime());
        }
    }

    @Inject()
    updateData: (background: boolean) => void;

    @Inject()
    updateProperty: (name: string, value: any) => void;

    @Inject()
    nextStep: () => void;

    @Inject()
    previousStep: () => void;

    @Inject()
    toOverview: () => void;

    created(): void {
        // this should preset the 'now' choice once a user selected at least the means of travel
        if (this.participant.meansOfTravel) {
            this.dataModel = ['now'];
        }
    }

    mounted(): void {
        this.$el.scrollIntoView({ behavior: 'smooth' });
    }

    onFocusChange(passed: boolean, name: string, value: any): void {
        if (passed) {
            this.updateProperty(name, value);
        }
    }

    onSubmit(validate: () => Promise<boolean>): void {
        if (!(this.participant.meansOfTravel && this.participant.meansOfTravel !== 'UNKNOWN')) {
            this.showRequired = true;
            return;
        }
        this.showRequired = false;

        validate().then((valid) => {
            if (valid) {
                this.nextStep();
            }
        });
    }
}
