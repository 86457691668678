





















































































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import ContentOverlay from '../base/ContentOverlay.vue';
import Icon from '../base/Icon.vue';
import GcButton from '../base/GcButton.vue';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';

@Component({
    components: {
        CollapseTransition,
        ContentOverlay,
        GcButton,
        Icon
    }
})
export default class Questionnaire extends Vue {
    @Prop() readonly anchor: string;
    @Prop() readonly resourcePath: string;

    @Prop() readonly title: string;
    @Prop() readonly subtitle: string;

    @Prop() readonly resetTitle: string;
    @Prop() readonly resetText: string;
    @Prop() readonly resetLabel: string;

    @Prop() readonly questions: string[];
    @Prop() readonly yesLabel: string;
    @Prop() readonly noLabel: string;

    @Prop() readonly modalTitle: string;
    @Prop() readonly modalText: string;
    @Prop() readonly modalLabel: string;

    @Prop() readonly contactTitle: string;
    @Prop() readonly contactText: string;
    @Prop() readonly contactLabel: string;

    questionIndex = 0;
    modalOpen = false;
    contactOpen = false;

    get complete(): boolean {
        return this.questionIndex === this.questions.length;
    }

    openModal(): void {
        window.requestAnimationFrame(() => {
            this.modalOpen = true;
        });
    }

    nextStep(): void {
        this.questionIndex++;
    }

    reset(): void {
        this.questionIndex = 0;
    }
}
