

































import Component from 'vue-class-component';
import Vue from 'vue';
import { Emit, Prop, Ref } from 'vue-property-decorator';
import GcSwiperSlide from './GcSwiperSlide.vue';

@Component
export default class TabHeader extends Vue {
    @Prop({ default: () => [] }) tabs: string[];
    @Prop({ default: () => [] }) anchors: string[];
    @Prop({ default: false, type: Boolean }) inContentGroup: boolean;
    @Prop({ default: false, type: Boolean }) narrow: boolean;
    @Prop({ default: false, type: Boolean }) highlight: boolean;
    @Prop() value;
    @Ref() slideEl: InstanceType<typeof GcSwiperSlide>[];

    hasControls = false;

    mounted(): void {
        // some dirty hack to trigger rerendering to initially calculate the width correctly on the first tab
        const current = this.value;
        this.$emit('input', undefined);
        setTimeout(() => {
            // check if value has changed while waiting and set accordingly, otherwise reset to what it was before
            const index = (!!this.value || this.value === 0) && (this.value !== current) ? this.value : current;
            this.$emit('input', index);
        }, 100);
    }

    /**
     * Emits the input event to trigger v-model update with the given selected index.
     *
     * @param index The selected index
     */
    @Emit('input')
    select(index: number): number {
        return index;
    }

    setDeeplink(value: string): void {
        if (value) {
            if (window.history.pushState) {
                window.history.pushState(null, null, `#${value}`);
            } else {
                window.location.hash = `#${value}`;
            }
        }
    }

    getStyle(index: number): Record<string, unknown> {
        return {
            'max-width': index === this.value ? this.calcWidth(index) : `${this.getMaxWidth()}px`
        };
    }

    getMaxWidth(): number {
        return Math.max(184, 853 / this.tabs.length);
    }

    calcWidth(index: number): string {
        const max = this.getMaxWidth();
        if (this.slideEl && this.slideEl[index]) {
            const slide = this.slideEl[index];
            // calc width based on character count
            // calc with an average of 7.5px per character
            const text = (slide.$el as HTMLElement).innerText || '';
            const width = (((text.length * 7.5) / 2) + 24);
            return width > max ? `${width}px` : `${max}px`;
        }
        return `${max}px`;
    }

    /**
     * Honk method to propagate the control visibility status to the component itself.
     */
    controlsVisible(show: boolean): boolean {
        this.hasControls = show;
        return show;
    }
}
