



























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Icon from './Icon.vue';

@Component({
    components: {
        Icon
    }
})
export default class GcButton extends Vue {
    @Prop({ default: 'button' }) type: string;
    @Prop({ default: 'black' }) color: string;
    @Prop({ default: 'arrow-right' }) icon: string;
    @Prop({ default: false, type: Boolean }) secondary: boolean;
    @Prop({ default: false, type: Boolean }) disabled: boolean;
    @Prop() url: string;
    @Prop() label: string;
    @Prop() ariaLabel: string;

    get classes(): Record<string, boolean> {
        return {
            group: !this.disabled,
            'px-12 py-2.5': !this.secondary,
            'px-8 py-2': this.secondary,
            'bg-black-900 hover:bg-black-600 focus:border-primary-500 active:bg-black-500': !this.primaryColor,
            'bg-primary-500 hover:bg-primary-600 focus:border-black-900 active:bg-primary-700': this.primaryColor,
            'disabled:bg-black-100 disabled:text-black-400': this.disabled && !this.primaryColor,
            'disabled:bg-primary-300 disabled:text-primary-500': this.disabled && this.primaryColor
        };
    }

    get primaryColor(): boolean {
        return this.color === 'primary';
    }
}
