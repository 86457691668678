






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop } from 'vue-property-decorator';
import Utils from '../../../utils/Utils';

@Component
export default class Instagram extends Vue {
    @Prop() data: Record<string, string>;

    @Inject() showChannel: boolean;

    show = false;

    mounted() {
        Utils.observeIntersection(this.$refs.observable, () => { this.show = true; });
    }

    get imgUrl(): string {
        return this.data.asset || (this.data.mediaType === 'VIDEO' ? this.data.thumbnailUrl : this.data.url) || '';
    }

    get styles(): Record<string, string> {
        return {
            'background-image': this.show ? `url(${this.imgUrl})` : ''
        };
    }
}
