












import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { User } from './UserManagement.vue';

@Component
export default class UserProperties extends Vue {
    @Prop({ required: true }) user: User;
    @Prop({ required: true }) properties: Record<string, string>;
}
