











import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop, Watch } from 'vue-property-decorator';
import NavigationStore from '../../store/NavigationStore';
import { getModule } from 'vuex-module-decorators';

@Component
export default class ContentGroupItem extends Vue {
    @Prop({ default: false, type: Boolean }) showAll!: boolean;
    @Prop({ default: false, type: Boolean }) isLast: boolean;
    @Prop({ default: 'accordion' }) type!: string;
    @Prop() title: string;
    @Prop() currentIndex: number;
    @Prop() index: number;
    @Prop({ default: 1 }) itemsLength: number;
    @Prop({ default: '' }) anchor!: string;

    @Inject() isAccordion: boolean;

    navStore: NavigationStore = getModule(NavigationStore);

    open = this.active || this.showAll;

    get active() {
        return (this.currentIndex === this.index) || (this.showAll && this.currentIndex === this.itemsLength);
    }

    get visible(): boolean {
        if (this.isAccordion) {
            return this.open;
        }
        return this.active;
    }

    get activatedTab(): string {
        return this.navStore.activatedTab;
    }

    toggle(): void {
        this.open = !this.open;
    }

    checkActivatedTab(id: string): void {
        if (this.anchor === id) {
            this.open = true;
        }
    }

    @Watch('activatedTab')
    watchActivatedTab(newVal: string): void {
        if (newVal) {
            this.checkActivatedTab(newVal);
        }
    }

    @Watch('visible')
    watchVisible(newVal: boolean): void {
        if (newVal) {
            // some stuff needs an event to trigger when becoming visible to actually work
            // ok, in fact only the animated text currently needs this or the text stays invisible
            // ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠠⠀⠀⠀⠀⠠⣀⣠⣤⣤⣤⣤⣤⣄⡠⠤⠄⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
            // ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⠤⠄⠒⠒⠒⠂⠂⠀⠀⠀⠀⠀⠀⠀⠀⣠⣾⣿⣿⣿⣿⣿⣿⣿⣿⠃⠀⠀⠈⠳⠤⢀⡀⠀⠀⠀⠀⠀⠀
            // ⠀⠀⠀⠀⠀⠀⠀⠀⣠⠊⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢹⣿⣿⣿⣿⣿⣿⣿⣿⣿⡆⠀⠀⠀⠀⠀⠀⠈⠳⡄⠀⠀⠀⠀
            // ⠀⠀⠀⠀⠀⡠⠒⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣠⣾⣿⣿⣿⣿⣿⣿⣿⣿⠟⠁⠀⠀⠀⠀⠀⠀⠀⠀⠘⣄⠀⠀⠀
            // ⠀⠀⠀⠀⠀⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠘⢿⣿⣿⣿⣿⣿⣿⣿⣿⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠑⢄⠀
            // ⠀⠀⠀⢀⢖⣿⡷⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣼⣿⣿⣿⣿⣿⣿⣿⡿⠃⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⠀
            // ⠀⠀⠀⢸⡾⣿⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣴⣿⣿⣿⣿⣿⣿⣿⠟⠋⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢇⠀
            // ⠀⠀⠀⣴⣛⣿⣿⠇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢺⣿⣿⣿⣿⣿⣿⣿⢥⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡸⠀
            // ⠀⠀⣾⣾⣿⣿⣧⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⣀⣤⠽⠿⠿⠿⠿⠿⣧⣄⡈⠛⠛⠛⣛⣛⣳⣶⣶⣦⣄⡀⠀⠀⠀⠀⡎⠀⠀
            // ⠀⠘⡿⣿⣿⣿⣿⣿⣦⣄⠀⠀⣀⣠⠤⠤⣤⣖⡺⢟⣩⠥⢒⡊⠭⠭⠝⢑⣒⠲⠤⢭⢖⣪⠭⠜⠓⢒⣒⠒⠒⢒⣛⢷⣄⠀⢀⡇⠀⠀
            // ⠀⢀⣽⣿⣿⣿⣿⣿⣿⣿⣠⠞⠉⢀⣤⣿⡭⠷⡺⠚⠉⢩⣾⣩⡿⠻⣦⡀⠀⠀⠀⠁⠲⡠⠒⠁⠀⣴⣈⡿⠷⣦⠀⠈⠈⠙⠻⣄⠀⠀
            // ⠀⢸⣿⣿⣿⣿⣿⡭⠟⠉⠁⠀⠀⠘⠓⠒⠲⡉⠀⠀⠀⢸⣿⣬⣷⣶⣿⡇⠀⠀⠀⠀⠈⠀⠀⠀⢸⣿⣧⣿⣶⣿⠇⠀⠀⠀⠀⣸⠀⠀
            // ⠀⠀⠈⠓⣿⠶⠟⠁⠀⠀⠀⠀⠀⠘⠛⠂⠀⠈⠑⠠⢀⠈⢛⣿⣿⠛⠋⣀⡀⣀⠠⠐⠂⠀⢀⣀⠀⠙⠻⠿⢿⣍⡀⠤⠤⠒⢊⡍⠀⠀
            // ⠀⠀⠀⡴⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⢠⣄⣤⣾⣿⣷⣤⠀⠀⠀⠀⠀⠀⣀⡤⡎⠀⠀⠀
            // ⠀⠀⡸⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣠⡤⠤⠶⠦⢤⣤⣀⠀⠀⢠⣿⣿⣿⣤⣿⣿⣿⣿⣇⣀⣀⣤⢶⠛⠁⢀⡏⠀⠀⠀
            // ⠀⢰⠃⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠸⣅⡀⠐⠦⣤⣤⣀⣈⠉⠙⠻⣿⣿⣿⣿⣿⣿⣿⡿⠉⠀⢀⣀⣠⣤⠴⢻⠀⠀⠀⠀
            // ⠀⢸⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠙⠳⠶⢤⣄⣉⠉⠛⠛⠛⠻⠻⣿⣿⣿⠿⠿⠿⠛⠛⠋⠉⠁⣀⣴⠏⠀⠀⠀⠀
            // ⠀⡏⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠰⢦⣀⠀⠀⠀⠀⠀⠀⠈⠉⠓⠒⠦⠤⠤⠤⠤⠤⠤⠤⠤⠤⠤⠶⠒⠋⠉⡘⠀⠀⠀⠀⠀
            // ⢀⣧⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⡇⠀⠀⠀⠀⠀
            // ⢸⠀⠙⠦⣄⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡔⠑⠒⠦⢤⣄⠀⠀⠀⠀⠀⠀⠀⣀⠤⠤⠤⢤⣀⣀⣀⠴⠚⠉⠀⢸⠀⠀⠀⠀⠀
            // ⢸⠀⠀⠀⠈⠉⠛⠒⠦⠤⢤⣀⣀⣀⣀⣀⣀⣀⣰⠁⠀⠀⠀⠀⠈⠑⡤⠒⠒⢢⠖⣉⠀⠀⠀⠀⠀⠉⠁⠀⠀⠀⠀⠀⠈⠳⣄⠀⠀⠀
            // ⠸⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⠀⠀⠀⠀⠀⠀⠩⠇⠀⠀⠀⠧⠀⠀⠀⠀⠀⠀⠁⠀⠀⠀⠀⠀⠀⠀⠀⠈⢦⠀⠀
            // ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⠀⠀⠀⠀⠀⡀⠜⠒⠤⠀⠐⠒⠤⡀⠀⠀⠀⡰⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠱⡄
            setTimeout(() => window.dispatchEvent(new Event('resize')), 200);
        }
    }
}
