

























import Component from 'vue-class-component';
import CookiePolicy from './CookiePolicy.vue';
import { Watch } from 'vue-property-decorator';
import Utils from '../../utils/Utils';
import { ToggleButton } from 'vue-js-toggle-button';
import Vue from 'vue';

@Component({
    components: {
        ToggleButton
    }
})
export default class EmbeddedCookiePolicy extends CookiePolicy {
    init = false;
    created(): void {
        // load saved state if applicable
        const savedConsent = Utils.getLocalStorage(this.consentKey);
        if (savedConsent) {
            // set model from storage
            this.grantModel = JSON.parse(savedConsent);
            this.$nextTick(() => {
                this.init = true;
            });
        }
        // set all necessary options to true
        this.options.filter(o => !o.checkbox).forEach(o => Vue.set(this.grantModel, o.type, true));
    }

    get colors(): Record<string, string> {
        return {
            checked: 'rgb(var(--color-primary-500))',
            unchecked: 'rgb(var(--color-black-400))',
            disabled: 'rgb(var(--color-black-200))'
        };
    }

    @Watch('grantModel', { deep: true })
    watchGrantModel(): void {
        if (this.init) {
            this.updateConsent();
            Utils.setLocalStorage(this.consentKey, JSON.stringify(this.grantModel));
        }
    }
}
