








import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class Checkbox extends Vue {
    /***********************
     * Vue Props
     ***********************/

    @Prop({ default: () => ({ backgroundColor: '#00928E', borderColor: '#00928E' }) }) checkedStyles!: any;
    @Prop({ default: {} }) iconStyles!: any;
    @Prop({ default: false }) model!: boolean;

    /***********************
     * Vue Data
     ***********************/

    get checkedStyle() {
        return !this.model ? {} : this.checkedStyles;
    }
}
