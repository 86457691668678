














import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { Operation } from './SocioEconomicMapUtils';

@Component
export default class SocioEconomicMapLegend extends Vue {
    @Prop({ default: 'Glencore\'s Operations' }) label: string;
    @Prop({ default: () => ([]) }) operations: Operation;

    lightenFactor = '34';

    private cssColor(color: string): string {
        return color[0] === '#' ? color : '#' + color;
    }

    private lightenColor(color: string): string {
        return color + this.lightenFactor;
    }
}
