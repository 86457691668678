






import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';

@Component
export default class Tab extends Vue {
    @Inject({
        default: () => null
    }) readonly registerTab!: Function;

    @Prop({ required: true }) title!: string;

    active = false;

    mounted() {
        // register self on parent component
        this.registerTab(this);
    }
}
