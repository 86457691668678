



















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Icon from '../base/Icon.vue';

const RADIUS = 49;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

@Component({
    components: {
        Icon
    }
})
export default class WindChart extends Vue {
    @Prop({ required: true }) label: string;
    @Prop({ required: true }) speed: number;
    @Prop({ required: true }) direction: number;
    @Prop({ default: 100 }) max: number;

    get r(): number {
        return RADIUS;
    }

    get circleStyle(): Record<string, unknown> {
        return {
            strokeDasharray: CIRCUMFERENCE,
            strokeDashoffset: CIRCUMFERENCE * (1 - (Math.min(this.speed, this.max) / this.max)),
            strokeLinecap: 'round'
        };
    }
}
