



























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Icon from './Icon.vue';
import Lightbox from './Lightbox.vue';
import breakpoints from '../../plugins/breakpoints';

@Component({
    components: {
        Icon,
        Lightbox
    }
})
export default class ResponsiveImage extends Vue {
    @Prop({ required: true }) urls: Record<string, string>;
    @Prop() title: string;
    @Prop() labelledBy: string;
    @Prop() caption: string;
    @Prop() altTitle: string;
    @Prop({ default: 'w-full h-full object-cover' }) imgClass: string;
    @Prop({ default: 'sm' }) variationMobile: string;
    @Prop({ default: 'md' }) variationTablet: string;
    @Prop({ default: 'lg' }) variationDesktop: string;
    @Prop({ default: 'xl' }) variationWide: string;
    @Prop({ default: 'original' }) variationOriginal: string;
    @Prop({ default: false, type: Boolean }) showCaption: boolean;
    @Prop({ default: false, type: Boolean }) showWide: boolean;
    @Prop({ default: false, type: Boolean }) showLightbox: boolean;
    @Prop({ default: false, type: Boolean }) hideMobileLightboxIcon: boolean;

    lightboxVisible = false;

    get alt(): string {
        return this.getCleanText(this.altTitle || this.title || this.caption || this.fileName);
    }

    get _showLightbox(): boolean {
        return this.showLightbox && breakpoints.mdAndUp;
    }

    get renderWide(): boolean {
        return this.showWide && !!this.urls[this.variationWide];
    }

    get sources(): Record<string, string>[] {
        const sources = [
            {
                media: '(max-width: 600px)',
                srcset: this.urls[this.variationMobile]
            },
            {
                media: '(max-width: 1024px)',
                srcset: this.urls[this.variationTablet]
            },
            {
                media: this.renderWide ? '(max-width: 1280px)' : undefined,
                srcset: this.urls[this.variationDesktop]
            }
        ];

        if (this.renderWide) {
            sources.push({
                media: undefined,
                srcset: this.urls[this.variationWide]
            });
        }

        return sources;
    }

    get fileName(): string {
        if (this.urls && this.urls.high) {
            return this.urls.high.split('/').pop();
        }
        return '';
    }

    protected getCleanText(text: string): string {
        const el = document.createElement('div');
        el.innerHTML = text;
        return el.innerText;
    }

    protected setLightboxVisible(value: boolean): void {
        if (this._showLightbox) {
            this.lightboxVisible = value;
        }
    }
}
