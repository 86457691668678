










import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';

@Component({
    components: {
        CollapseTransition
    }
})
export default class ReadMore extends Vue {
    @Prop() showMore: string;
    @Prop() showLess: string;

    opened = false;

    get label(): string {
        return this.opened ? this.showLess : this.showMore;
    }
}
