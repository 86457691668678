var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('gmap-map',{ref:"mapRef",staticClass:"w-full h-full",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.center,"options":_vm.mapOptions},on:{"idle":_vm.onMapIdle}},[_vm._l((_vm.countryConfig.features),function(feature,index){return _c('socio-economic-map-static-feature',{attrs:{"feature":feature,"index":index,"visible":_vm.showStaticFeatures}})}),_vm._l((_vm.operations),function(operation){return _c('gmap-cluster',{attrs:{"zoom-on-click":true,"grid-size":_vm.gridSize,"styles":_vm.getRegionClusterStyles(),"minimum-cluster-size":_vm.clusterSize},on:{"click":_vm.onClusterClick}},_vm._l((_vm.getLocationsForOperation(operation)),function(marker,index){return _c('gmap-custom-marker',{key:index,attrs:{"marker":marker.position,"clickable":true,"offsetY":-40,"label":{
                                 color: 'black',
                                 fontFamily: 'Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif',
                                 fontSize: '12px',
                                 fontWeight: '100',
                                 text: ' ',
                             }},nativeOn:{"click":function($event){return _vm.onMarkerClicked(marker, operation)}}},[_c('div',{staticClass:"relative bg-white px-4 py-2 flex items-center justify-center border rounded-full text-para-xs",style:(_vm.getMarkerStyle(marker, operation))},[_vm._v(" "+_vm._s(marker.title || marker.name || "")+" "),_c('div',{staticClass:"absolute border-1 w-0.5 top-full h-10",style:({
                        backgroundColor: _vm.getColor(operation.color),
                        borderColor: _vm.getColor(operation.color),
                        left: 'calc(50% - 2px)'
                })})])])}),1)}),_c('socio-economic-map-zoom-buttons',{on:{"zoomIn":_vm.zoomIn,"zoomOut":_vm.zoomOut}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }