






import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Icon extends Vue {
    @Prop() name: string;
    @Prop({ default: '/img/sprite.svg' }) source: string;

    get sprite(): string {
        return (this.$prototype ? '' : '/.resources/gc/webresources') + this.source;
    }
}
