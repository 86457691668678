




import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { CurrencyInputOptions, useCurrencyInput } from 'vue-currency-input';

@Component({
    setup(props) {
        const { inputRef, setValue } = useCurrencyInput(props.options as CurrencyInputOptions);
        return { inputRef, setValue };
    }
})
export default class NumberInput extends Vue {
    @Prop() value: number;
    @Prop() options: CurrencyInputOptions;

    setValue: (number) => void;

    @Watch('value')
    watchValue(newValue: number) {
        this.setValue(newValue);
    }
}
