/* eslint-disable @typescript-eslint/camelcase */
import gsap from 'gsap';
import breakpoints from '../../plugins/breakpoints';

export const easyEase = 'Power4.inOut';
export const easeOut = 'Power4.out';
export const easeCirc = 'circ.out';

declare const window: any;

export const Timeline = pause => {
    const tl = gsap.timeline({ paused: pause });
    tl._has_played = false;
    return tl;
};

const playOnceOnScroll = (anim, scroll, threshold) => {
    if (scroll >= threshold && anim._has_played === false) {
        anim._has_played = true;
        anim.restart();
    }
};

const updateHeader = () => {
    const offset = breakpoints.mdAndUp ? 80 : 56;
    const threshold = window.innerHeight - offset;
    const baseScroll = window.animations.scroll;
    playOnceOnScroll(window.animations.signature, baseScroll, threshold);
};

export const update = t => {
    window.requestAnimationFrame(update);

    window.animations.last_t = t;
    window.animations.scroll = window.pageYOffset;
    window.animations.screen_w = window.innerWidth;
    window.animations.screen_h = window.innerHeight;
    window.animations.is_side_scrolling = false;

    updateHeader();
};
