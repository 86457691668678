






import Vue from 'vue';
import Component from 'vue-class-component';
import { ValidationObserver } from 'vee-validate';
import { Prop, Watch } from 'vue-property-decorator';
import Cookies from 'js-cookie';
import Utils from '../../utils/Utils';

export interface FormFieldMapping {
    FormFieldName: string;
    DataverseFieldName: string;
}

declare const d365mktformcapture: any;

@Component({
    components: {
        ValidationObserver
    }
})
export default class VueForm extends Vue {
    @Prop() uuid: string;
    @Prop({ default: false, type: Boolean }) agreed: boolean;
    @Prop({ default: false, type: Boolean }) dynamicsSend: boolean;
    @Prop() dynamicsFormId: string;
    @Prop() dynamicsApiUrl: string;
    @Prop() dynamicsLibUrl: string;
    @Prop({ default: {} }) dynamicsFieldMappings: any;

    created(): void {
        if (this.dynamicsSend && this.dynamicsLibUrl) {
            Utils.addScript(this.dynamicsLibUrl);
        }
    }

    mounted(): void {
        const params = new URLSearchParams(window.location.search);
        if (params.has('mgnlFormToken')) {
            const actionResult = document.getElementById(this.uuid);
            if (actionResult) {
                actionResult.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }
        }
    }

    submit(): void {
        const form = this.$el.querySelector('form');
        if (form) {
            // get csrf from cookie if not set
            const csrf = this.$el.querySelector('#csrf') as HTMLInputElement;
            if (csrf && !csrf.value) {
                csrf.value = Cookies.get('csrf');
            }
            if (this.dynamicsSend && !!d365mktformcapture) {
                // dynamics form capture
                form.querySelectorAll('select').forEach((select: any) => {
                    if (select.forEach) {
                        select.forEach = null;
                    }
                });
                const serializedForm = d365mktformcapture.serializeForm(form, this.dynamicsFieldMappings);

                const payload = serializedForm.SerializedForm.build();
                const captureConfig = {
                    FormId: this.dynamicsFormId,
                    FormApiUrl: this.dynamicsApiUrl
                };

                d365mktformcapture.submitForm(captureConfig, payload)
                    .catch(e => {
                        console.log(e);
                    })
                    .finally(() => {
                        // submit after promise is either accepted or rejected
                        form.submit();
                    });
            } else {
                // normal submit
                form.submit();
            }
        }
    }

    /**
     * Special method for the login component, where users might have to agree to terms & conditions first
     */
    @Watch('agreed')
    watchAgreed(): void {
        if (this.agreed) {
            this.submit();
        }
    }
}
