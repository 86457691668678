



































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

/**
 * Serves as icon component for article social sharing.
 */
@Component
export default class SharingIcon extends Vue {
    @Prop({ required: true }) network: string;
}
