


























































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios from 'axios';
import Icon from '../base/Icon.vue';
import Pagination from '../base/Pagination.vue';
import LoadingSpinner from '../base/LoadingSpinner.vue';
import DocumentsTable from '../documents/DocumentsTable.vue';
import SearchLink from './SearchLink.vue';
import breakpoints from '../../plugins/breakpoints';
import debounce from 'lodash/debounce';

export interface SearchLabels {
    placeholder: string;
    all: string;
    top: string;
    results: string;
    sortBy: string;
    topTerms: string;
    quickLinks: string;
    noResultsTitle: string;
    noResultsText: string;
    noResultsOther: string;
    moreResults: string;
    categories: Record<string, string>;
}

export interface SearchResult {
    site: SiteResults;
}

export interface SiteResults {
    page?: number;
    results: Result[];
    resultsPerWorkspace: Record<string, number>;
    total: number;
}

export interface Result {
    id: string;
    jcrPath: string;
    label: string;
    lastModified: Record<string, number>;
    link: string;
    overlay: boolean;
    score: number;
    type: string;
}

@Component({
    components: {
        LoadingSpinner,
        Icon,
        Pagination,
        SearchLink
    }
})
export default class Search extends Vue {
    @Prop() labels: SearchLabels;
    @Prop({ default: () => [] }) topTerms: string[];
    @Prop({ default: () => [] }) quickLinks: Record<string, string>[];
    @Prop({ default: 20 }) resultsPerPage: number;

    apiUrl = Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Utils.addSiteToApi(Enums.API.LOCATION_V2, Enums.API.SEARCH, this.$site);
    suggestBaseUrl = Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Utils.addSiteToApi(Enums.API.LOCATION_V2, Enums.API.SEARCH_SUGGESTIONS, this.$site);

    PAGE_SIZE = 20;
    PAGE_SIZE_MOBILE = 5;
    NUM_TOP_RESULTS = 4;

    keyword = '';
    currentSearch = '';
    currentPage = 1;
    currentCategory = 'all';
    pages = 0;
    results: Result[] = [];
    topResults: Result[] = [];
    suggestions: string[] = [];
    mobileResults: Record<string, Result[]> = {};
    numResults = 0;
    resultsPerWorkspace: Record<string, number> = {};
    loading = false;

    searchSuggestions: Function = debounce(this.getSuggestions, 500);

    get categories(): Record<string, string> {
        const result: Record<string, string> = {
            all: `${this.labels.all} (${this.numResults})`
        };
        Object.keys(this.resultsPerWorkspace)
            .sort((k1, k2) => this.resultsPerWorkspace[k2] - this.resultsPerWorkspace[k1])
            .filter(k => this.resultsPerWorkspace[k] > 0)
            .forEach(k => {
                result[k] = `${this.labels.categories[k]} (${this.resultsPerWorkspace[k]})`;
            });
        return result;
    }

    get resultsLabel(): string {
        return Utils.formatString(this.labels.results, this.numResults, this.currentSearch);
    }

    get noResultsLabel(): string {
        return Utils.formatString(this.labels.noResultsTitle, this.currentSearch);
    }

    get url(): string {
        return `${this.mobileUrl}&category=${this.currentCategory}&start=${this.start}&rows=${this.rows}`;
    }

    get mobileUrl(): string {
        return `${this.apiUrl}locale=${this.$lang}&query=${this.currentSearch}`;
    }

    get suggestUrl(): string {
        return `${this.suggestBaseUrl}locale=${this.$lang}&query=${this.keyword}`;
    }

    get start(): number {
        return this.currentCategory === 'all' && this.currentPage > 1 ? ((this.currentPage - 1) * this.PAGE_SIZE) + this.NUM_TOP_RESULTS : (this.currentPage - 1) * this.PAGE_SIZE;
    }

    get rows(): number {
        return this.currentCategory === 'all' && this.currentPage === 1 ? this.PAGE_SIZE + this.NUM_TOP_RESULTS : this.PAGE_SIZE;
    }

    get isMobile(): boolean {
        return breakpoints.mobile;
    }

    get mobileCategories(): string[] {
        return Object.keys(this.mobileResults).sort((k1, k2) => this.resultsPerWorkspace[k2] - this.resultsPerWorkspace[k1]);
    }

    hasMoreResults(workspace: string): boolean {
        return workspace === 'all'
            ? this.mobileResults[workspace].length < this.numResults
            : this.mobileResults[workspace].length < this.resultsPerWorkspace[workspace];
    }

    getType(linkType: string) {
        switch (linkType) {
            case 'document':
            case 'asset':
                return 'DOCUMENT';
            default:
            case 'basic':
            case 'page':
            case 'mail':
                return 'WEBSITE';
        }
    }

    getIcon(type: string, link: string): string {
        switch (type) {
            case 'WEBSITE':
                return 'search_page';
            case 'DOCUMENT':
                return `search_${DocumentsTable.getFileType(link)}`;
            case 'MEMBER':
                return 'search_person';
            case 'OFFICE':
                return 'search_office';
            case 'LOCATION':
                return 'search_location';
            case 'EVENT':
                return 'search_calendar';
            default:
                return 'search_file';
        }
    }

    getTarget(type: string) {
        if (type === 'DOCUMENT') {
            return '_blank';
        }
        return '_self';
    }

    searchByKeyword(suggestion: string) {
        this.keyword = suggestion;
        this.initSearch();
    }

    initSearch(): void {
        if (this.keyword.length >= 3 && this.currentSearch !== this.keyword) {
            this.currentSearch = this.keyword;
            this.loading = true;
            this.currentPage = 1;
            this.currentCategory = 'all';
            this.results = [];
            this.mobileResults = {};
            this.topResults = [];
            this.search();
            this.trackSearch(this.currentSearch);
        }
    }

    async search(): Promise<void> {
        if (this.currentSearch) {
            try {
                const res = await axios.get(this.url);
                const data: SearchResult = res.data;
                if (this.currentPage === 1) {
                    if (this.currentCategory === 'all') {
                        this.pages = Math.ceil((data.site.total - this.NUM_TOP_RESULTS) / this.resultsPerPage);
                        this.numResults = data.site.total;
                        this.resultsPerWorkspace = data.site.resultsPerWorkspace;
                        this.topResults = data.site.results.slice(0, this.NUM_TOP_RESULTS);
                        this.results = data.site.results.slice(this.NUM_TOP_RESULTS);
                        // mobile search: init all other categories with values
                        if (this.isMobile) {
                            Vue.set(this.mobileResults, 'all', data.site.results.slice(0, this.PAGE_SIZE_MOBILE));
                            Object.keys(this.resultsPerWorkspace).filter(k => this.resultsPerWorkspace[k] > 0).forEach(k => this.searchMobile(k));
                        }
                    } else {
                        this.pages = Math.ceil((data.site.total) / this.resultsPerPage);
                        this.results = data.site.results;
                    }
                } else {
                    this.results = data.site.results;
                }
            } finally {
                this.loading = false;
            }
        }
    }

    async searchMobile(workspace: string): Promise<void> {
        const start = this.mobileResults[workspace] ? this.mobileResults[workspace].length : 0;
        try {
            const res = await axios.get(`${this.mobileUrl}&category=${workspace}&start=${start}&rows=${this.PAGE_SIZE_MOBILE}`);
            const data: SearchResult = res.data;
            const results = (this.mobileResults[workspace] || []).concat(data.site.results);
            Vue.set(this.mobileResults, workspace, results);
        } catch (err) {
            Vue.set(this.mobileResults, workspace, {});
        }
    }

    async getSuggestions(): Promise<void> {
        this.loading = true;
        if (this.keyword.length < 3) return;
        const res = await axios.get(this.suggestUrl);
        if (res.status === 200 && res.data.length > 0) {
            this.suggestions = res.data;
        } else {
            this.suggestions = [];
        }
        this.loading = false;
    }

    trackSearch(searchText: string): void {
        if (this.$gtm && this.$gtm.enabled()) {
            window.dataLayer.push({
                event: 'customSearch',
                customSearchInput: searchText
            });
        }
    }

    @Watch('keyword')
    watchKeyword(): void {
        if (this.keyword.trim().length < 3) {
            this.suggestions = [];
        } else if (this.keyword !== this.currentSearch) {
            this.searchSuggestions();
        }
    }

    @Watch('currentPage')
    watchCurrentPage(): void {
        if (this.currentPage > 0) {
            this.search();
        }
    }

    @Watch('currentCategory')
    watchCurrentCategory(): void {
        this.currentPage = 1;
        this.search();
    }
}
