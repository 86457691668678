/*****************/
/* JS Components */
/*****************/

/*****************/
/* TS Components */
/*****************/
import Vue from 'vue';
import ArticleFilterGrid from './components/articles/ArticleFilterGrid.vue';
import Accordion from './components/base/Accordion.vue';
import ArrowButton from './components/base/ArrowButton.vue';
import Dropdown from './components/base/Dropdown.vue';
import DropdownItem from './components/base/DropdownItem.vue';
import Tab from './components/base/Tab.vue';
import Tabs from './components/base/Tabs.vue';
import GcSwiper from './components/base/GcSwiper.vue';
import GcSwiperSlide from './components/base/GcSwiperSlide.vue';
import CareerList from './components/career/CareerList.vue';
import ContentGroup from './components/contentGroup/ContentGroup.vue';
import ContentGroupItem from './components/contentGroup/ContentGroupItem.vue';
import CookiePolicy from './components/cookie/CookiePolicy.vue';
import EventsSection from './components/events/EventsSection.vue';
import ReCaptchaSubmit from './components/form/ReCaptchaSubmit.vue';
import VueForm from './components/form/VueForm.vue';
import VueFormInput from './components/form/VueFormInput.vue';
import VueFormSelection from './components/form/VueFormSelection.vue';
import Leadership from './components/leadership/Leadership.vue';
import MediaLibrary from './components/media/MediaLibrary.vue';
import GlobalNavigation from './components/navigation/GlobalNavigation.vue';
import MainNavigation from './components/navigation/MainNavigation.vue';
import MainNavigationSubMenu from './components/navigation/MainNavigationSubMenu.vue';
import MetaNavigation from './components/navigation/MetaNavigation.vue';
import NavigationItem from './components/navigation/NavigationItem.vue';
import Search from './components/navigation/Search.vue';
import SocialSection from './components/social/SocialSection.vue';
import StageCarousel from './components/stage/StageCarousel.vue';
import StageTeaser from './components/stage/StageTeaser.vue';
import TailingsTable from './components/tailings/TailingsTable.vue';
import BrightCoveVideo from './components/video/BrightCoveVideo.vue';
import MovingImageVideo from './components/video/MovingImageVideo.vue';
import StageVideoTeaser from './components/video/StageVideoTeaser.vue';
import DocumentListView from './components/documents/DocumentListView.vue';
import DocumentsTable from './components/documents/DocumentsTable.vue';
import QuickLinks from './components/links/QuickLinks.vue';
import BackgroundTexts from './components/backgroundTexts/BackgroundTexts.vue';
import ScrollDown from './components/base/ScrollDown.vue';
import IsaMillCalculator from './components/calculators/IsaMillCalculator.vue';
import JamesonCellCalculator from './components/calculators/JamesonCellCalculator.vue';
import MonitoringGraph from './components/monitoring/MonitoringGraph.vue';
import WorldMap from './components/map/WorldMap.vue';
import MapEmbed from './components/map/MapEmbed.vue';
import MapCategories from './components/map/MapCategories.vue';
import MapOffice from './components/map/MapOffice.vue';
import MapDetail from './components/map/MapDetail.vue';
import TableContainer from './components/tables/TableContainer.vue';
import CustomHtml from './components/html/CustomHtml.vue';
import TeaserLink from './components/teaser/TeaserLink.vue';
import TeaserHeroList from './components/teaser/TeaserHeroList.vue';
import Overlay from './components/teaser/Overlay.vue';
import ReadMore from './components/base/ReadMore.vue';
import SharingIcon from './components/base/SharingIcon.vue';
import GcButton from './components/base/GcButton.vue';
import ResponsiveImage from './components/base/ResponsiveImage.vue';
import SimpleImage from './components/base/SimpleImage.vue';
import BrandColor from './components/brand/BrandColor.vue';
import Icon from './components/base/Icon.vue';
import MonitoringGraphMntIsa from './components/monitoring/MonitoringGraphMntIsa.vue';
import SocioEconomicMapEmbed from './components/socioEconomicMap/SocioEconomicMapEmbed.vue';
import SocioEconomicMapStaticFeature from './components/socioEconomicMap/SocioEconomicMapStaticFeature.vue';
import SocioEconomicMapDetail from './components/socioEconomicMap/SocioEconomicMapDetail.vue';
import SocioEconomicMapFiltering from './components/socioEconomicMap/SocioEconomicMapFiltering.vue';
import SocioEconomicMapSelection from './components/socioEconomicMap/SocioEconomicMapSelection.vue';
import SocioEconomicMap from './components/socioEconomicMap/SocioEconomicMap.vue';
import SocialSharing from './components/social/SocialSharing.vue';
import Breadcrumbs from './components/base/Breadcrumbs.vue';
import MultiStepForm from './components/calculators/albion/MultiStepForm.vue';
import MultiStepFormRangeInput from './components/calculators/albion/MultiStepFormRangeInput.vue';
import MultiStepFormNumberInput from './components/calculators/albion/MultiStepFormNumberInput.vue';
import MultiStepFormTextInput from './components/calculators/albion/MultiStepFormTextInput.vue';
import ContentOverlay from './components/base/ContentOverlay.vue';
import { HotspotTemplate } from '@lemonize/lz-hotspots-template';
import { DocsHeader, DocsTab, DocsTabs } from '@lemonize/lz-mgnl-docs';
import InteractiveImage from './components/media/InteractiveImage.vue';
import FlipCard from './components/teaser/FlipCard.vue';
import StagePromoTeaser from './components/stage/StagePromoTeaser.vue';
import Questionnaire from './components/code/Questionnaire.vue';
import ArticleTabbedGrid from './components/articles/ArticleTabbedGrid.vue';
import SliderButton from './components/base/SliderButton.vue';
import FiguresSection from './components/figures/FiguresSection.vue';
import BackToTop from './components/base/BackToTop.vue';
import CareerListV2 from './components/career/CareerListV2.vue';
import InfoBanner from './components/base/InfoBanner.vue';
import UserManagement from './components/pur/UserManagement.vue';
import PublicUserComments from './components/pur/PublicUserComments.vue';
import StageSectionTeaser from './components/stage/StageSectionTeaser.vue';
import VueFormCheckbox from './components/form/VueFormCheckbox.vue';
import PublicUserLogin from './components/pur/PublicUserLogin.vue';
import OverlayLink from './components/portalNavigator/OverlayLink.vue';
import EmbeddedCookiePolicy from './components/cookie/EmbeddedCookiePolicy.vue';
import VueFormFile from './components/form/VueFormFile.vue';
import SearchLink from './components/navigation/SearchLink.vue';
import ConditionalAreaSelect from './components/calculators/ConditionalAreaSelect.vue';
import IsaKiddResult from './components/calculators/albion/IsaKiddResult.vue';
import MultiStepFormSelect from './components/calculators/albion/MultiStepFormSelect.vue';
import MultiStepFormHidden from './components/calculators/albion/MultiStepFormHidden.vue';
import GolfEvent from './components/golfevent/GolfEvent.vue';
import ToggleButtons from './components/base/ToggleButtons.vue';
import PlayButton from './components/video/PlayButton.vue';
import ConsentWrapper from './components/cookie/ConsentWrapper.vue';

/**************************/
/* Component registration */
/**************************/
Vue.component('article-filter-grid', ArticleFilterGrid);
Vue.component('article-tabbed-grid', ArticleTabbedGrid);
Vue.component('accordion', Accordion);
Vue.component('arrow-button', ArrowButton);
Vue.component('slider-button', SliderButton);
Vue.component('dropdown', Dropdown);
Vue.component('dropdown-item', DropdownItem);
Vue.component('tab', Tab);
Vue.component('tabs', Tabs);
Vue.component('gc-swiper', GcSwiper);
Vue.component('gc-swiper-slide', GcSwiperSlide);
Vue.component('career-list', CareerList);
Vue.component('content-group', ContentGroup);
Vue.component('content-group-item', ContentGroupItem);
Vue.component('cookie-policy', CookiePolicy);
Vue.component('embedded-cookie-policy', EmbeddedCookiePolicy);
Vue.component('events-section', EventsSection);
Vue.component('re-captcha-submit', ReCaptchaSubmit);
Vue.component('vue-form', VueForm);
Vue.component('vue-form-input', VueFormInput);
Vue.component('vue-form-selection', VueFormSelection);
Vue.component('vue-form-checkbox', VueFormCheckbox);
Vue.component('vue-form-file', VueFormFile);
Vue.component('leadership', Leadership);
Vue.component('media-library', MediaLibrary);
Vue.component('global-navigation', GlobalNavigation);
Vue.component('main-navigation', MainNavigation);
Vue.component('main-navigation-sub-menu', MainNavigationSubMenu);
Vue.component('meta-navigation', MetaNavigation);
Vue.component('navigation-item', NavigationItem);
Vue.component('search', Search);
Vue.component('social-section', SocialSection);
Vue.component('stage-carousel', StageCarousel);
Vue.component('stage-teaser', StageTeaser);
Vue.component('stage-video-teaser', StageVideoTeaser);
Vue.component('stage-promo-teaser', StagePromoTeaser);
Vue.component('stage-section-teaser', StageSectionTeaser);
Vue.component('tailings-table', TailingsTable);
Vue.component('bright-cove-video', BrightCoveVideo);
Vue.component('moving-image-video', MovingImageVideo);
Vue.component('document-list-view', DocumentListView);
Vue.component('documents-table', DocumentsTable);
Vue.component('quick-links', QuickLinks);
Vue.component('background-texts', BackgroundTexts);
Vue.component('scroll-down', ScrollDown);
Vue.component('isamill-calculator', IsaMillCalculator);
Vue.component('jameson-cell-calculator', JamesonCellCalculator);
Vue.component('monitoring-graph', MonitoringGraph);
Vue.component('monitoring-graph-mnt-isa', MonitoringGraphMntIsa);
Vue.component('custom-html', CustomHtml);
Vue.component('world-map', WorldMap);
Vue.component('map-embed', MapEmbed);
Vue.component('map-categories', MapCategories);
Vue.component('map-office', MapOffice);
Vue.component('map-detail', MapDetail);
Vue.component('teaser-link', TeaserLink);
Vue.component('teaser-hero-list', TeaserHeroList);
Vue.component('overlay', Overlay);
Vue.component('table-container', TableContainer);
Vue.component('read-more', ReadMore);
Vue.component('sharing-icon', SharingIcon);
Vue.component('gc-button', GcButton);
Vue.component('socio-economic-map', SocioEconomicMap);
Vue.component('socio-economic-map-embed', SocioEconomicMapEmbed);
Vue.component('socio-economic-map-static-feature', SocioEconomicMapStaticFeature);
Vue.component('socio-economic-map-detail', SocioEconomicMapDetail);
Vue.component('socio-economic-map-filtering', SocioEconomicMapFiltering);
Vue.component('socio-economic-map-selection', SocioEconomicMapSelection);
Vue.component('responsive-image', ResponsiveImage);
Vue.component('simple-image', SimpleImage);
Vue.component('brand-color', BrandColor);
Vue.component('icon', Icon);
Vue.component('user-management', UserManagement);
Vue.component('social-sharing', SocialSharing);
Vue.component('breadcrumbs', Breadcrumbs);
Vue.component('multi-step-form', MultiStepForm);
Vue.component('multi-step-form-range-input', MultiStepFormRangeInput);
Vue.component('multi-step-form-number-input', MultiStepFormNumberInput);
Vue.component('multi-step-form-text-input', MultiStepFormTextInput);
Vue.component('multi-step-form-select', MultiStepFormSelect);
Vue.component('multi-step-form-hidden', MultiStepFormHidden);
Vue.component('content-overlay', ContentOverlay);
Vue.component('hotspot-template', HotspotTemplate);
Vue.component('docs-header', DocsHeader);
Vue.component('docs-tabs', DocsTabs);
Vue.component('docs-tab', DocsTab);
Vue.component('interactive-image', InteractiveImage);
Vue.component('flip-card', FlipCard);
Vue.component('questionnaire', Questionnaire);
Vue.component('figures-section', FiguresSection);
Vue.component('back-to-top', BackToTop);
Vue.component('info-banner', InfoBanner);
Vue.component('career-list-v2', CareerListV2);
Vue.component('public-user-comments', PublicUserComments);
Vue.component('public-user-login', PublicUserLogin);
Vue.component('overlay-link', OverlayLink);
Vue.component('search-link', SearchLink);
Vue.component('conditional-area-select', ConditionalAreaSelect);
Vue.component('isa-kidd-result', IsaKiddResult);
Vue.component('golf-event', GolfEvent);
Vue.component('toggle-buttons', ToggleButtons);
Vue.component('play-button', PlayButton);
Vue.component('consent-wrapper', ConsentWrapper);
