



















































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, InjectReactive, Prop } from 'vue-property-decorator';
import { GEvent, GolfEventLabels, Participant } from './GolfEventTypes';
import VueFormSelection from '../form/VueFormSelection.vue';
import GcButton from '../base/GcButton.vue';
import Icon from '../base/Icon.vue';
import { ValidationObserver } from 'vee-validate';
import ArrowButton from '../base/ArrowButton.vue';

@Component({
    components: {
        ValidationObserver,
        VueFormSelection,
        ArrowButton,
        GcButton,
        Icon
    }
})
export default class GolfEventSummary extends Vue {
    @Prop({ required: true }) labels: GolfEventLabels;

    @InjectReactive()
    participant: Participant;

    @InjectReactive()
    event: GEvent;

    confirmation = false;
    policyConfirmation = false;

    swissDate = new Intl.DateTimeFormat(undefined, { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Europe/Zurich' });
    swissTime = new Intl.DateTimeFormat(undefined, { hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'Europe/Zurich' });

    get isShuttleNeeded(): boolean {
        if (typeof this.participant.shuttleNeeded === 'boolean') {
            return this.participant.shuttleNeeded;
        }
        return this.participant.shuttleNeeded === 'true';
    }

    get isAccommodationCostCoverage(): boolean {
        if (typeof this.participant.accommodationCostCoverage === 'boolean') {
            return this.participant.accommodationCostCoverage;
        }
        return this.participant.accommodationCostCoverage === 'true';
    }

    get bookings(): string {
        const bookings: string[] = [];
        if (this.participant.dinnerDay1) {
            bookings.push(this.labels.dinnerWednesday);
        }
        if (this.participant.dinnerDay2) {
            bookings.push(this.labels.dinnerThursday);
        }
        if (this.participant.golfTournament) {
            bookings.push(this.labels.golfTournament);
        }
        if (this.participant.bikeTour) {
            bookings.push(this.labels.bikeTour);
        }
        return bookings.join(', ');
    }

    get dietaryRestrictions(): string {
        const restrictions: string[] = [];
        if (this.participant.dietaryRestrictions.includes('VEGETARIAN')) {
            restrictions.push(this.labels.vegetarian);
        }
        if (this.participant.dietaryRestrictions.includes('NO_PORK')) {
            restrictions.push(this.labels.noPork);
        }
        if (this.participant.dietaryRestrictions.includes('NO_SHELLFISH')) {
            restrictions.push(this.labels.noShellfish);
        }
        if (this.participant.dietaryRestrictions.includes('NUT_ALLERGY')) {
            restrictions.push(this.labels.nutsAllergy);
        }
        if (this.participant.dietaryRestrictions.includes('GLUTEN_ALLERGY')) {
            restrictions.push(this.labels.glutenAllergy);
        }
        if (this.participant.dietaryRestrictions.includes('OTHER')) {
            restrictions.push(this.labels.other);
        }
        return restrictions.join(', ');
    }

    mounted(): void {
        this.$el.scrollIntoView({ behavior: 'smooth' });
    }

    getGender(gender: string): string {
        switch (gender) {
            case 'MALE':
                return this.labels.genderMale;
            case 'FEMALE':
                return this.labels.genderFemale;
            case 'UNKNOWN':
            default:
                return gender;
        }
    }

    getRental(rental: string): string {
        switch (rental) {
            case 'NO':
                return this.labels.noRental;
            case 'RIGHT_HANDED':
                return this.labels.rightHanded;
            case 'RIGHT_HANDED_LONG':
                return this.labels.rightHandedLong;
            case 'LEFT_HANDED':
                return this.labels.leftHanded;
            case 'LEFT_HANDED_LONG':
                return this.labels.leftHandedLong;
            default:
                return rental;
        }
    }

    getSize(size: string): string {
        switch (size) {
            case 'S':
                return this.labels.s;
            case 'M':
                return this.labels.m;
            case 'L':
                return this.labels.l;
            case 'XL':
                return this.labels.xl;
            case 'XXL':
                return this.labels.xxl;
            default:
                return size;
        }
    }

    getSkillLevel(level: string): string {
        switch (level) {
            case 'BEGINNER':
                return this.labels.beginner;
            case 'INTERMEDIATE':
                return this.labels.intermediate;
            default:
                return level;
        }
    }

    getMeansOfTravel(means: string): string {
        switch (means) {
            case 'UNKNOWN':
                return this.labels.unknown;
            case 'CAR':
                return this.labels.car;
            case 'TRAIN':
                return this.labels.train;
            case 'AIRPLANE':
                return this.labels.airplane;
            default:
                return means;
        }
    }

    getSwissDate(time: number): string {
        if (!time) return '-';
        const date = new Date(time);
        return this.swissDate.format(date);
    }

    getSwissTime(time: number): string {
        if (!time) return '-';
        const date = new Date(time);
        return this.swissTime.format(date);
    }

    valueOrFallback(value: any): any {
        if (typeof value === 'undefined' || value === '') {
            return '-';
        }
        return value;
    }

    print(): void {
        window.print();
    }

    @Inject()
    previousStep: () => void;

    @Inject()
    confirmBooking: () => void;

    @Inject()
    toOverview: () => void;
}
