

























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class LoadSpinner extends Vue {
    @Prop({ default: false, type: Boolean }) active!: boolean;
    animate = false;

    mounted() {
        if (this.active) {
            setTimeout(() => {
                this.animate = true;
            }, 500);
        }
    }

    @Watch('active')
    toggle() {
        if (this.active) {
            this.$nextTick(() => {
                this.animate = true;
            });
        } else {
            this.animate = false;
        }
    }
}
