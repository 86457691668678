















import GmapCustomMarker from 'vue2-gmap-custom-marker';
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component({
    components: {
        GmapCustomMarker
    }
})
export default class SocioEconomicMapStaticFeature extends Vue {
    @Prop() feature: any;
    @Prop() index: number;
    @Prop({ default: true }) visible: boolean;
}
