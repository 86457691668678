










import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import breakpoints from '../../plugins/breakpoints';

import ScrollDown from '../base/ScrollDown.vue';

@Component({
    components: { ScrollDown }
})
export default class BackgroundTexts extends Vue {
    @Prop({ required: true, default: '' }) imageExtraLarge!: string;
    @Prop({ required: true, default: '' }) imageLarge!: string;
    @Prop({ required: true, default: '' }) imageMedium!: string;
    @Prop({ required: false }) scrollToAnchor!: string;

    backgroundStyle: any = {
        backgroundImage: `url(${this.image})`
    }

    get image(): string {
        return breakpoints.xl ? this.imageExtraLarge : (breakpoints.lg ? this.imageLarge : this.imageMedium);
    }
}
