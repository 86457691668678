






























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Provide, Watch } from 'vue-property-decorator';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import SocialFilter from './SocialFilter.vue';
import SocialTabSection from './SocialTabSection.vue';
import GcButton from '../base/GcButton.vue';
import TabHeader from '../base/TabHeader.vue';

export interface SocialReferenceItem {
    value: string;
    label: string;
}

export interface Account {
    type: string;
    accounts: SocialReferenceItem[];
}

export interface Labels {
    labelFilter: string;
    labelLoadMore: string;
    labelSocialMedia: string;
    labelSocialAll: string;
    labelsAccountTypes: Record<string, string>;
}

@Component({
    components: {
        TabHeader,
        SocialFilter,
        SocialTabSection,
        GcButton
    }
})
export default class SocialSection extends Vue {
    @Prop({ default: 8 }) numOfItems: number;
    @Prop({ default: () => [] }) uuid: string;
    @Prop({ default: {} }) rssPost: Record<string, string>;
    @Prop({ default: 'vertical' }) layout: string;
    @Prop({ default: false }) showFilter: boolean;
    @Prop({ default: true }) showChannelNames: boolean;
    @Prop({ default: false }) highlight: boolean;
    @Prop({ default: false }) inContentGroup: boolean;
    @Prop({ default: true }) visible: boolean;
    @Prop({ default: () => [] }) accounts: Account[];
    @Prop({ default: () => [] }) posts: Record<string, string>[];
    @Prop({ default: () => [] }) labels: Labels;

    apiUrlSocial = `${Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH)}${Utils.addSiteToApi(Enums.API.LOCATION_V2, Enums.API.SOCIAL, this.$site)}`;
    tabItems = [];
    offset = 0;
    isLoadMoreActive = true;
    isFilterActive = false;
    tabActive = 0;
    accActive = '';
    data: Record<string, string>[] = [];
    dataRef: SocialReferenceItem[] = [];

    get uuidParam(): string {
        if (this.showFilter) {
            return '';
        }
        return `&uuid=${this.uuid}`;
    }

    @Provide()
    get isVertical(): boolean {
        return this.layout === 'vertical';
    }

    @Provide()
    get showChannel(): boolean {
        return this.showChannelNames;
    }

    created(): void {
        this.tabItems = [this.labels.labelSocialAll];
        this.accounts.forEach((account) => {
            if (account.accounts && account.accounts.length) {
                this.tabItems.push(this.labels.labelsAccountTypes[account.type] || account.type);
            }
        });
        if (this.showFilter) {
            this.getSocial(0, true);
        } else {
            if (this.rssPost.link) {
                this.posts.pop();
                this.posts.unshift(this.rssPost);
            }
            this.data = this.posts;
        }
    }

    getSocial(offset, reload): void {
        this.offset = offset;
        // eslint-disable-next-line max-len
        const url = `${this.apiUrlSocial}${this.getActiveTab() !== this.labels.labelSocialAll ? `type=${this.getActiveTab()}&` : ''}offset=${this.offset}&limit=${this.numOfItems}&account=${this.accActive}${this.uuidParam}`;
        Utils.getAPI(url)
            .then(res => {
                // set data according to parameters and rss status
                const tmpData: Record<string, string>[] = res.data;
                if (reload && this.rssPost.link && this.getActiveTab() === this.labels.labelSocialAll) {
                    tmpData.pop();
                    tmpData.unshift(this.rssPost);
                    this.data = tmpData;
                    // also lower offset by 1 to reflect the removed post
                    this.offset--;
                } else if (!reload) {
                    this.data = [...this.data, ...tmpData];
                } else {
                    this.data = tmpData;
                }
                this.isLoadMoreActive = res.status;
            });
    }

    getActiveTab(): string {
        return this.tabItems[this.tabActive] || '';
    }

    getSocialRef(): void {
        if (this.getActiveTab() !== this.labels.labelSocialAll) {
            const data = this.accounts
                .filter(x => x.type.toUpperCase() === this.getActiveTab().toUpperCase());
            this.dataRef = data[0].accounts;
            this.accActive = this.dataRef[0].value;
            this.isFilterActive = true;
        } else {
            this.isFilterActive = false;
        }
    }

    loadMore(): void {
        this.offset = this.offset + this.numOfItems;
        this.getSocial(this.offset, false);
    }

    @Watch('tabActive')
    watchTabActive(): void {
        if (this.tabActive !== undefined) {
            this.getSocialRef();
            this.getSocial(0, true);
        }
    }

    @Watch('accActive')
    watchAccActive(): void {
        this.getSocial(0, true);
    }
}
