









import Vue from 'vue';
import Component from 'vue-class-component';
import ContentOverlay from '../base/ContentOverlay.vue';
import { Prop } from 'vue-property-decorator';

@Component({
    components: {
        ContentOverlay
    }
})
export default class PublicUserLogin extends Vue {
    @Prop({ required: true }) buttonLabel: string;
    termsModel = false;
    agreed = false;

    openTerms(): void {
        this.termsModel = true;
    }
}
