















import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class ToggleButtons extends Vue {
    @Prop({ default: {} }) items: Record<string, string>; // key -> label
    @Prop({ default: {} }) views: Record<string, string>; // key -> view
    @Prop({ default: '' }) btnClasses: string;
    @Prop({ default: '' }) emptyValue: string;
    @Prop({ default: false, type: Boolean }) multiSelect: boolean;
    @Prop({ default: false, type: Boolean }) grow: boolean;
    @Prop({ default: false, type: Boolean }) growMobile: boolean;
    @Prop({ default: false, type: Boolean }) col: boolean;
    @Prop({ default: false, type: Boolean }) colMobile: boolean;
    @Prop() value: string[]; // selected keys

    currentSelection: string[] = [];

    mounted(): void {
        // respect the initial v-model value
        if (this.value) {
            this.currentSelection = this.value;
        }
    }

    get currentViews(): Set<string> {
        const result = new Set<string>();
        this.currentSelection.forEach(value => {
            result.add(this.views[value]);
        });
        return result;
    }

    isSelected(item: string): boolean {
        return this.currentSelection.indexOf(item) >= 0;
    }

    toggle(value: string) {
        const index = this.currentSelection.indexOf(value);
        if (index >= 0) {
            // remove from selection
            this.currentSelection.splice(index, 1);
            if (this.currentSelection.length === 0) {
                if (this.emptyValue) {
                    this.currentSelection = [this.emptyValue];
                }
            }
        } else {
            if (this.multiSelect) {
                const newSelection = [...this.currentSelection];
                // add to selection
                newSelection.push(value);
                // sort selection by position in items
                newSelection.sort((a, b) =>
                    Object.keys(this.items).indexOf(a) - Object.keys(this.items).indexOf(b)
                );
                this.currentSelection = newSelection;
            } else {
                // allow only one selection at a time
                this.currentSelection = [value];
            }
        }
    }

    @Watch('currentSelection')
    watchSelection(newVal: string[]): void {
        this.$emit('input', newVal);
        // if no multiselect, also emit the key as event
        if (!this.multiSelect && newVal.length > 0) {
            const key = newVal[0];
            this.$emit(key);
        }
    }

    @Watch('value')
    watchValue(): void {
        this.currentSelection = this.value;
    }
}
