






























import Vue from 'vue';
import GcButton from '../base/GcButton.vue';
import Component from 'vue-class-component';
import ArticleGrid from './ArticleGrid.vue';
import { Prop, Watch } from 'vue-property-decorator';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios from 'axios';

/* Holds all information needed for responsive images */
export interface Asset {
    sourceSet: string;
    source: string;
    sizes: string;
}

export interface Article {
    type: string;
    title: string;
    link: string;
    date: string;
    tags: string[];
    imageUrls: string[];
    asset: Asset;
}

export interface ArticleLabels {
    defaultFilter: string;
    yearFilter: string;
    readMore: string;
    filter: string;
}

@Component({
    components: {
        ArticleGrid,
        GcButton
    }
})

/**
 * Component to enable filtering of articles.
 * See articles section with layout `filter-grid`.
 */
export default class ArticleFilterGrid extends Vue {
    @Prop({ default: false }) showImages: boolean;
    @Prop({ default: false }) filterByYear: boolean;
    @Prop({ default: false }) filterByTag: boolean;
    @Prop({ default: false }) highlight!: boolean;
    @Prop({ default: 24 }) maxItems!: number;
    @Prop({ default: 18 }) chunkSize!: number;
    @Prop({ default: {} }) labels: ArticleLabels;
    @Prop({ default: [] }) articles!: Article[];
    @Prop({ default: {} }) categories: Record<string, Record<string, string>[]>;
    @Prop({ required: true }) uuid: string;
    @Prop({ default: () => [] }) yearOptions: string[];

    apiUrl = Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Enums.API.LOCATION_V2 + Enums.API.ARTICLES;
    years: string[] = [];

    // only use one model for all filters
    currentFilters: Record<string, string> = {};

    shownArticles: Article[] = [];
    offset = 0;
    hasMore = false;
    init = false;

    /**
     * Collect years, initialize category state object
     */
    created(): void {
        // init years and year model
        this.years = [
            this.labels.defaultFilter,
            ...this.yearOptions
        ];
        Vue.set(this.currentFilters, 'year', null);

        // add default category and init categories model
        for (const [key, value] of Object.entries(this.categories)) {
            value.unshift({
                uuid: '0',
                tag: this.labels.defaultFilter
            });
            Vue.set(this.currentFilters, key, null);
        }

        this.shownArticles = this.articles;
        this.offset = this.shownArticles.length;
        this.hasMore = this.shownArticles.length === this.maxItems;

        this.setTagFromQueryOrStorage();
    }

    getUrl(limit: number): string {
        return `${this.apiUrl}uuid=${this.uuid}&locale=${this.$lang}${this.yearFilter}${this.tagFilter}&offset=${this.offset}&limit=${limit}`;
    }

    get filterStorageKey(): string {
        return 'articleFilterGrid_' + this.uuid;
    }

    get yearFilter(): string {
        return this.currentFilters.year && this.currentFilters.year !== this.labels.defaultFilter ? `&year=${this.currentFilters.year}` : '';
    }

    get tagFilter(): string {
        const setFilters = Object.keys(this.currentFilters)
            .filter(c => c !== 'year') // filter out years since this will be treated differently
            .filter(c => this.currentFilters[c] && this.currentFilters[c] !== '0')
            .map(c => `&tag=${this.currentFilters[c]}`);
        return setFilters.join('');
    }

    setTagFromQueryOrStorage(): void {
        const params = new URLSearchParams(window.location.search);
        // tag from query has priority over session storage
        if (params.has('tag')) {
            const tag = params.get('tag');
            // actually we also need to check the categories if one of them actually contains the given tag
            Object.keys(this.categories).forEach(key => {
                if (this.categories[key].findIndex(t => t.uuid === tag) >= 0) {
                    // set the tag
                    Vue.set(this.currentFilters, key, tag);
                }
            });
        } else if (sessionStorage.getItem(this.filterStorageKey)) {
            // load from session storage and apply all non-null/default values to model
            const filters: Record<string, string> = JSON.parse(sessionStorage.getItem(this.filterStorageKey));
            Object.keys(filters)
                .filter(f => !!filters[f] && filters[f] !== '0' && filters[f] !== this.labels.defaultFilter)
                .forEach(f => Vue.set(this.currentFilters, f, filters[f]));
        }
    }

    getArticles(limit: number): void {
        axios.get(this.getUrl(limit)).then(res => {
            if (this.offset === 0) {
                this.shownArticles = res.data;
            } else {
                this.shownArticles = [
                    ...this.shownArticles,
                    ...res.data
                ];
            }
            this.offset += limit;
            // if number of delivered articles is same as limit, assume there are more articles to load
            this.hasMore = res.data.length === limit;
        });
    }

    loadMore(): void {
        this.getArticles(this.chunkSize);
    }

    @Watch('currentFilters', { deep: true })
    watchCurrentFilters(): void {
        // check if any filters are actually set
        if (Object.keys(this.currentFilters).some(c => this.currentFilters[c])) {
            this.init = true;
        }
        if (this.init) {
            this.offset = 0;
            this.getArticles(this.maxItems);
            sessionStorage.setItem(this.filterStorageKey, JSON.stringify(this.currentFilters));
        }
    }
}
