















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop, Ref, Watch } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import Facebook from './items/Facebook.vue';
import Instagram from './items/Instagram.vue';
import Youtube from './items/Youtube.vue';
import Twitter from './items/Twitter.vue';
import breakpoints from '../../plugins/breakpoints';
import Rss from './items/Rss.vue';

@Component({
    components: {
        Facebook,
        Instagram,
        Youtube,
        Twitter,
        Rss
    }
})
export default class SocialTabSection extends Vue {
    @Prop({ default: () => [] }) data: Record<string, string>[];
    @Prop({ default: false }) showLabel: boolean;
    @Prop({ default: false }) inContentGroup: boolean;
    @Prop({ default: true }) visible: boolean;
    @Prop() labelTabSection: string;
    @Prop() layout: string;

    @Inject() isVertical: boolean;

    @Ref() wrapper: HTMLDivElement;

    redraw = debounce(this.$redrawVueMasonry, 20);

    get showTiles(): boolean {
        return this.isVertical || breakpoints.mdAndUp;
    }

    get showPagination(): boolean {
        return !this.isVertical && breakpoints.mobile;
    }

    updated(): void {
        this.redraw();
    }

    @Watch('visible')
    watchVisible(isVisible: boolean): void {
        if (isVisible) {
            this.redraw();
        }
    }
}
