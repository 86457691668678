
import Vue, { CreateElement, VNode } from 'vue';
import Component from 'vue-class-component';

@Component
export default class TableContainer extends Vue {
    render(h: CreateElement): VNode {
        return h('div', [this.$slots.default ? this.$slots.default.map((child: VNode) => child.tag === 'table' ? h('div', { staticClass: 'overflow-x-auto table-section' }, [child]) : child) : '']);
    }
}
