
















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Article, ArticleLabels } from './ArticleFilterGrid.vue';
import TeaserLink from '../teaser/TeaserLink.vue';

/**
 * Render filtered articles.
 */
@Component({
    components: {
        TeaserLink
    }
})
export default class ArticleGrid extends Vue {
    @Prop({ default: () => [] }) articles: Article[];
    @Prop({ default: false }) showImages: boolean;
    @Prop({ default: false }) highlight: boolean;
    @Prop({ default: {} }) labels: ArticleLabels;

    getIcon(type: string): string {
        return type === 'overlay' ? type : 'right';
    }
}
