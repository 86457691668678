/* eslint-disable dot-notation, @typescript-eslint/camelcase */
/**
 * count-up directive
 *
 * Looks for a numerical value inside the element and
 * counts up to it as the element scrolls into view
 * Usage: <div v-count-up>13</div>
 * Looks for the first numerical value inside the element (has to be directly inside the element)
 * Does nothing if no numerical value is found
 *
 * @author Julian Frank (julian.frank@lemonize.de)
 */
import { CountUp } from 'countup.js';

function onCountUp(useGrouping: boolean, entries: IntersectionObserverEntry[]) {
    const decimalFormat = window['decimalFormat'] || '.';
    const groupingSeparator = window['groupingSeparator'] || ',';
    Array.from(entries).forEach(entry => {
        const target = entry.target as HTMLElement;
        if (entry.intersectionRatio > 0) {
            const matches = target.innerText.match(`\\d+([${decimalFormat}]\\d+)?`);
            const counted = target.dataset.counted;
            if (matches.length > 0 && !counted) {
                const regex = new RegExp(`(.*)${matches[0]}(.*)`);
                const prefix = target.innerText.replace(regex, (_, prefix) => prefix) || '';
                const suffix = target.innerText.replace(regex, (_, p, suffix) => suffix) || '';
                const decimalPlaces = matches[0].includes(decimalFormat) ? matches[0].split(decimalFormat).pop().length : 0;
                const number = parseFloat(matches[0].replace(`${decimalFormat}`, '.'));
                if (!isNaN(number)) {
                    const countUp = new CountUp(target, number, {
                        prefix: prefix,
                        suffix: suffix,
                        separator: groupingSeparator,
                        decimal: decimalFormat,
                        decimalPlaces: decimalPlaces,
                        useGrouping: useGrouping
                    });
                    if (!countUp.error) {
                        target.dataset.counted = 'true'; // only do the animation once
                        countUp.start();
                    } else {
                        console.error(countUp.error);
                    }
                }
            }
        }
    });
}

export default {
    bind: function(element: HTMLElement, binding) {
        const useGrouping = binding?.value?.useGrouping || false;
        const observer = new IntersectionObserver(entries => onCountUp(useGrouping, entries), {
            root: null,
            rootMargin: '0px 0px',
            threshold: (window.innerWidth > 800 ? 0.3 : 0.1)
        });
        observer.observe(element);
    }
};
