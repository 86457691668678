




















import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import Overlay from './Overlay.vue';
import VideoModal from '../video/VideoModal.vue';

@Component({
    components: {
        VideoModal,
        Overlay
    }
})
export default class TeaserLink extends Vue {
    @Prop() type: string;
    @Prop({ default: '_self' }) target: string;
    @Prop() ariaLabel: string;
    @Prop() title: string;
    @Prop() url: string;
    @Prop() classes: string;
    @Prop() accountId: string;
    @Prop() playerId: string;
    @Prop() videoId: string;

    targetUrl = '';

    get displayUrl(): string {
        // this should prevent from links being rendered on types where it's not needed
        if (this.type !== 'overlay' && this.type !== 'video') {
            return this.url;
        }
        return undefined;
    }

    click(event) {
        if (this.type === 'overlay') {
            event.preventDefault();
            this.targetUrl = this.url;
        } else if (this.type === 'video') {
            event.preventDefault();
            this.targetUrl = this.videoId;
        }
    }
}
