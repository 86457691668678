














































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, InjectReactive, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import Icon from '../../base/Icon.vue';
import { CurrencyInputOptions } from 'vue-currency-input';
import { formattingOptions } from './MultiStepForm.vue';
import NumberInput from '../../base/NumberInput.vue';

@Component({
    components: {
        ValidationProvider,
        Icon,
        NumberInput
    }
})
export default class MultiStepFormTextInput extends Vue {
    @Prop() name: string;
    @Prop() title: string;
    @Prop() suffix: string;
    @Prop({ default: 'text' }) type: 'text' | 'number' | 'email';
    @Prop({ default: false }) required: boolean;
    @Prop({ required: true }) stepIndex: number;
    @Prop() default: string;

    @Inject()
    model: Record<string, unknown>;

    @InjectReactive()
    currentStep: number;

    created(): void {
        if (!this.model[this.name] && this.default) {
            Vue.set(this.model, this.name, this.default);
        }
    }

    get validationDisabled(): boolean {
        return this.currentStep !== this.stepIndex;
    }

    get options(): CurrencyInputOptions {
        return formattingOptions;
    }

    get rules(): Record<string, unknown> {
        /* eslint-disable */
        return {
            required: this.required,
            is_number: this.type === 'number',
            email: this.type === 'email'
        };
        /* eslint-enable */
    }
}
