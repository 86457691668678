





















































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Provide, ProvideReactive, Ref } from 'vue-property-decorator';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios from 'axios';
import LoadingSpinner from '../base/LoadingSpinner.vue';
import { GEvent, GolfEventLabels, Participant, PropertyDto } from './GolfEventTypes';
import ContentOverlay from '../base/ContentOverlay.vue';
import GcButton from '../base/GcButton.vue';
import GolfEventRegistrationDialog from './GolfEventRegistrationDialog.vue';
import Icon from '../base/Icon.vue';
import GolfEventSummary from './GolfEventSummary.vue';

@Component({
    components: {
        GolfEventRegistrationDialog,
        GolfEventSummary,
        LoadingSpinner,
        ContentOverlay,
        GcButton,
        Icon
    }
})
export default class GolfEvent extends Vue {
    @Prop() labels: GolfEventLabels;

    @Ref() dialog: InstanceType<typeof GolfEventRegistrationDialog>;

    apiUrl = Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Enums.API.GOLF_EVENT;

    currentView = '';
    denied = false;
    error = false;
    loading = false;
    showCancelOverlay = false;

    @ProvideReactive()
    currentStep = 0;

    @ProvideReactive()
    participant: Participant = {};

    @ProvideReactive()
    event: GEvent = {};

    created(): void {
        // query initial data for event and participant
        this.queryEvent();
        this.queryData();
    }

    get registrationClosed(): boolean {
        if (!this.event.registrationByDate) {
            return false;
        }
        // current time if after closing data -> closed
        return new Date().getTime() > this.event.registrationByDate;
    }

    cancelParticipation(): void {
        // cancel participation
        Vue.set(this.participant, 'status', 'CANCELLED');
        this.showCancelOverlay = false;
        this.updateData();
    }

    confirmParticipation(): void {
        // this will take the user to the first step of the actual registration form
        Vue.set(this.participant, 'status', 'REGISTERED_OPEN');
        Vue.set(this.participant, 'registrationStep', 1);
        this.updateData();
        this.currentView = 'registration';
        this.currentStep = 1;
    }

    @Provide()
    confirmBooking(): void {
        Vue.set(this.participant, 'status', 'REGISTERED_COMPLETE');
        this.updateData();
        this.currentView = '';
    }

    @Provide()
    nextStep(): void {
        const step = this.currentStep + 1;
        // only update the user step if it is actually higher
        if (this.participant.registrationStep < step) {
            Vue.set(this.participant, 'registrationStep', step);
        }
        this.updateData();
        this.currentStep = step;
    }

    @Provide()
    previousStep(): void {
        this.currentStep -= 1;
    }

    @Provide()
    toOverview(): void {
        this.currentView = '';
    }

    goToRegistrationStep(step: number): void {
        // only go to step if user actually has already been on that step
        if (this.participant.registrationStep >= step) {
            this.currentStep = step;
            this.currentView = 'registration';
        }
    }

    queryEvent(): void {
        this.loading = true;
        axios.get(this.apiUrl)
            .then(res => {
                this.event = res.data;
            })
            .catch(() => {
                // handle error / show error view
                this.error = true;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    queryData(): void {
        this.loading = true;
        axios.get(this.apiUrl + '/participant/' + this.getTokenFromUrl())
            .then(res => {
                this.participant = res.data;
            })
            .catch(() => {
                // no user with this token or other error -> denied
                this.denied = true;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    @Provide()
    updateData(background = false): void {
        if (!background) {
            this.loading = true;
        }
        axios.post(this.apiUrl + '/participant', this.participant)
            .then(() => {
                // nothing to do here
            })
            .catch(() => {
                // handle error / show error view
                this.error = true;
            })
            .finally(() => {
                if (!background) {
                    this.loading = false;
                }
            });
    }

    @Provide()
    updateProperty(propertyName: string, value: any): void {
        if (!this.participant || !this.participant.id || !propertyName || typeof value === 'undefined') return;
        const data: PropertyDto = {
            name: propertyName,
            value: value,
            token: this.participant.id
        };
        axios.post(this.apiUrl + '/participant/property', data)
            .then(() => {
                // nothing to do here
            })
            .catch(() => {
                // handle error / show error view
                this.error = true;
            })
            .finally(() => {
                // nothing to do here
            });
    }

    getTokenFromUrl(): string {
        let token = '';
        const params = new URLSearchParams(window.location.search);
        if (params.has('token')) {
            // token (technical id) should be present in the url
            token = params.get('token');
        } else {
            // if not, it also might be the last path segment (server side forward)
            token = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
        }
        return this.isValidTokenFormat(token) ? token : '';
    }

    isValidTokenFormat(token: string): boolean {
        // standard uuid format
        return /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/.test(token);
    }

    get image(): string {
        if (this.error || this.denied || this.registrationClosed) {
            return 'error';
        }
        if (this.participant.status === 'UNKNOWN' || this.currentView === 'registration') {
            switch (this.currentStep) {
                case 0:
                    return 'registration';
                case 1:
                    return 'personal';
                case 2:
                    return 'participation';
                case 3:
                    return 'travel';
                case 4:
                    return 'summary';
            }
        }
        if (this.participant.status === 'CANCELLED') {
            return 'cancelled';
        }
        if (this.participant.status === 'REGISTERED_COMPLETE') {
            return 'summary';
        }
        return 'default';
    }
}
