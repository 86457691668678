







import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class TeaserHeroList extends Vue {
    @Prop({}) classes: string;

    uniqueRef = 0;

    /**
     * Generate unique Ref ID on component generation.
     */
    mounted(): void {
        this.uniqueRef = Math.floor(Math.random() * 100);
    }

    /**
     * On 'play' button click, or image click, simulate click on video link.
     */
    play() {
        const teaserLinkRef = this.$scopedSlots.text()[0].context.$refs['teaserLink-' + this.uniqueRef];

        if (teaserLinkRef) {
            teaserLinkRef.$el.click();
        }
    }
}
