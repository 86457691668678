




















/* eslint-disable @typescript-eslint/member-delimiter-style */
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
// eslint-disable-next-line no-unused-vars
import { CalcResult } from './calculations';

@Component({
    name: 'JamesonCellResults'
})
export default class JamesonCellResult extends Vue {
    @Prop() protected results: CalcResult[];
    @Prop() protected groupLabels: { value: string, key: string }[];
    @Prop({ default: 'Results' }) protected labelResultsSuffix: string;

    /***********************
     * Vue methods
     ***********************/
    // find translation with given key in groupLabels array
    // ignores digits in keys and appends them to the result
    protected getGroupLabel(key) {
        const label = this.groupLabels.find(x => x.key === key.replace(/\d/g, ''));
        if (label) {
            return `${key.replace(/[a-zA-Z_]+/, label.value).replace(/(\d)$/, (_, $1) => ` ${$1}`)} - `;
        }
        return '';
    }

    // rounds to 1 decimal and inserts thousand separators
    protected formatValue(value) {
        return value.toFixed(1)
        // insert , as thousands separators
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    /***********************
     * Vue computed
     ***********************/
    get groups() {
        return this.results.reduce((groups, result) => {
            const tmp = groups;
            const g = result.labelKey.split('.').shift();
            if (!Object.prototype.hasOwnProperty.call(tmp, g)) {
                tmp[g] = [];
            }
            tmp[g].push(result);
            return tmp;
        }, {});
    }
}
