













import Component from 'vue-class-component';
import Vue from 'vue';
import { SocialReferenceItem } from './SocialSection.vue';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import breakpoints from '../../plugins/breakpoints';

@Component
export default class SocialFilter extends Vue {
    @Prop({ default: () => [] }) accounts: SocialReferenceItem[];
    @Prop({ default: false }) inContentGroup: boolean;
    @Prop() labelSocialFilter: string;
    @Prop() value: string;

    model = '';

    get mobile(): boolean {
        return breakpoints.mobile;
    }

    mounted(): void {
        this.model = this.accounts[0].value;
    }

    @Emit()
    input(value: string): string {
        return value;
    }

    @Watch('model')
    watchModel(): void {
        this.input(this.model);
    }

    @Watch('accounts')
    watchAccounts(): void {
        this.model = this.accounts[0].value;
    }
}
