























































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { DutyFieldType } from './calculations';
import { ValidationProvider } from 'vee-validate';
import NumberInput from '../base/NumberInput.vue';
import { CurrencyInputOptions } from 'vue-currency-input';
import { formattingOptions } from './albion/MultiStepForm.vue';
import VueSlider from 'vue-slider-component';

@Component({
    components: {
        VueSlider,
        NumberInput,
        ValidationProvider
    }
})
export default class CalculatorInput extends Vue {
    /***********************
     * Vue properties
     ***********************/
    @Prop({ required: true }) protected name: string;
    @Prop({ required: true }) protected label: string;
    @Prop({ default: 'yes' }) protected yesLabel: string;
    // type: (number[] | { key: string, value: string }[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Prop() protected values: any[];
    @Prop() protected interval: number;
    @Prop() protected disabled: boolean;
    @Prop({ required: true }) protected type: DutyFieldType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Prop() protected value: any;
    @Prop() dependency: (x) => boolean;
    // make any necessary updates if this value changes
    @Prop() protected observable: string | number;

    /***********************
     * Vue data
     ***********************/
    protected FieldType = DutyFieldType;

    protected model: number | string | boolean = null;

    /***********************
     * Vue lifecycle hooks
     ***********************/
    mounted(): void {
        // set number input to undefined so placeholder is displayed
        // this will trigger a validation error, so automatic validation is disabled
        if (this.value) {
            this.model = this.value;
            return;
        }
        this.setModel();
    }

    setModel(): void {
        switch (this.type) {
            case DutyFieldType.CHECKBOX:
                this.model = false;
                break;
            case DutyFieldType.DROPDOWN:
            case DutyFieldType.RANGE_SLIDER:
                this.model = this.dValues[0];
                break;
            case DutyFieldType.KVP_DROPDOWN:
                this.model = this.values[0].value;
                break;
            case DutyFieldType.PERCENTAGE:
                this.model = undefined;
                break;
            default:
                break;
        }
    }

    /***********************
     * Vue computed
     ***********************/
    get modelLabel(): string {
        const selected = this.values.find(x => x.value === this.model);
        return selected ? selected.label : '';
    }

    // type: (number[] | { key: string, value: string }[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get dValues(): any[] {
        if (!this.dependency || !this.values || !this.observable) {
            return this.values;
        }
        return this.values.filter(this.dependency);
    }

    get rangeOptions(): CurrencyInputOptions {
        return formattingOptions;
    }

    get _min(): string {
        return new Intl.NumberFormat().format(Math.min(...this.dValues));
    }

    get _max(): string {
        return new Intl.NumberFormat().format(Math.max(...this.dValues));
    }

    get rules(): Record<string, unknown> {
        /* eslint-disable */
        return {
            min_value: Math.min(...this.dValues),
            max_value: Math.max(...this.dValues),
            required: true
        };
        /* eslint-enable */
    }

    /***********************
     * Vue watcher
     ***********************/
    @Watch('model')
    onModelChanged(val, old): void {
        // trigger validation bc automatic validation is disabled
        // but only if there previously was a value to avoid initial validation error
        if (this.type === DutyFieldType.PERCENTAGE && old) {
        } else if (this.type === DutyFieldType.DROPDOWN) {
            this.$emit('input', val ? parseFloat(val) : val);
        } else if (this.type === DutyFieldType.CHECKBOX) {
            this.$emit('input', val && !this.disabled);
        } else {
            this.$emit('input', val);
        }
    }

    @Watch('value')
    onValueChanged() {
        this.model = this.value;
    }

    @Watch('dValues')
    @Watch('observable')
    onValuesChanged() {
        if (!this.dValues) return;
        // if the dependency changes make sure the current value
        // still is in a valid range -> select last valid value
        if (!this.dValues.find(x => (this.type === DutyFieldType.KVP_DROPDOWN ? x.value === this.model : x === this.model)) && this.dValues.length > 0) {
            this.setModel();
        }
    }
}
