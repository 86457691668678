



































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Icon from '../base/Icon.vue';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';

export interface SocialNetwork {
    isNetwork: boolean;
    network: string;
    label: string;
    twitterUser?: string;
    url?: string;
}

@Component({
    components: {
        Icon,
        CollapseTransition
    }
})
export default class SocialSharing extends Vue {
    @Prop({ required: true }) networks: SocialNetwork[];
    @Prop({ required: true }) pageUrl: string;
    @Prop({ required: true }) pageTitle: string;
    @Prop({ default: 'share' }) label: string;
    @Prop() description: string;
    @Prop() quote: string;
    @Prop() hashtags: string;

    opened = false;
}
