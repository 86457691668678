






















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ArrowButton from './ArrowButton.vue';

@Component({
    components: {
        ArrowButton
    }
})
export default class Pagination extends Vue {
    /**
     * Current page, for allowing v-model usage.
     */
    @Prop() value: number;
    /**
     * The number of pages.
     */
    @Prop() pages: number;
    /**
     * The amount of pages shown to the left and right of the current page (if applicable).
     * Not including the first and last page.
     */
    @Prop({ default: 1 }) threshold: number;

    get showLowerDots(): boolean {
        return this.pagesShown[0] && this.pagesShown[0] > 2;
    }

    get showUpperDots(): boolean {
        return this.pagesShown.length > 0 && (this.pagesShown[this.pagesShown.length - 1]) < (this.pages - 1);
    }

    get pagesShown(): number[] {
        const result = [];
        if (this.pages <= ((2 * this.threshold) + 2)) {
            // case: not enough pages to fill the dynamic pages part
            for (let i = 2; i < this.pages; i++) {
                result.push(i);
            }
        } else if (this.value <= this.threshold + 1) {
            // case: lower edge
            for (let i = 2; i <= ((2 * this.threshold) + 2); i++) {
                result.push(i);
            }
        } else if (this.value >= (this.pages - this.threshold)) {
            // case: upper edge
            for (let i = (this.pages - ((2 * this.threshold) + 1)); i < this.pages; i++) {
                result.push(i);
            }
        } else {
            // case: default
            for (let i = (this.value - this.threshold); i <= (this.value + this.threshold); i++) {
                result.push(i);
            }
        }
        return result;
    }

    getClasses(page: number): Record<string, boolean> {
        return {
            'flex flex-row justify-center items-center w-6 h-6 md:w-8 md:h-8 rounded-full border hover:border-primary-500 cursor-pointer': true,
            'text-white border-primary-500 bg-primary-500': this.value === page,
            'border-transparent': this.value !== page
        };
    }

    next(): void {
        if (this.value < this.pages) {
            this.$emit('input', this.value + 1);
        }
    }

    prev(): void {
        if (this.value > 1) {
            this.$emit('input', this.value - 1);
        }
    }

    goTo(page: number) {
        this.$emit('input', page);
    }
}
